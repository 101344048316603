import React from 'react';
import styled from 'styled-components';
import ReactDataGrid from 'react-data-grid';
import { ArrowRightOutlined } from '@ant-design/icons';
import { themeColors } from '../../colors/themeColors';

const Panel = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${themeColors.secondary.gridBackground};
`;

const DataPanel = styled.div`
  width: 44%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const Title = styled.div`
  color: #ffffff;
  font-weight: 600;
  padding: 10px 5px;
`;

const NoDataPanel = styled.div`
  background-color: ${themeColors.secondary.darker};
  color: #eeeeee;
  font-weight: 600;
  padding: 20px;
  border-radius: 6px;
`;

const ArrowPanel = styled.div`
  color: #ffffff;
  font-size: 24px;
  padding: 0px 20px 0px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default ({ beforeData, afterData }) => {
  const getDataGridForData = (data) => {
    if (data === undefined)
      return (
        <NoDataPanel>
          Choose your options and apply the transform to see this sample data
          set changes.
        </NoDataPanel>
      );

    let columns = data.columns.map((column) => ({
      ...column,
      resizable: true,
    }));

    return (
      <ReactDataGrid
        columns={columns}
        rowGetter={(i) => data.values[i]}
        rowsCount={data.values.length}
        minHeight={300}
        enableCellSelect={false}
      />
    );
  };

  return (
    <Panel>
      <DataPanel className="dark-grid-panel">
        <Title>Before</Title>
        {getDataGridForData(beforeData)}
      </DataPanel>
      <ArrowPanel>
        <ArrowRightOutlined />
      </ArrowPanel>
      <DataPanel className="dark-grid-panel">
        <Title>After</Title>
        {getDataGridForData(afterData)}
      </DataPanel>
    </Panel>
  );
};
