import React from 'react';
import styled from 'styled-components';
import { fromUnixTime, format } from 'date-fns';
import FreeTrialBanner from './FreeTrialBanner';
import useCurrentSubscription from './CurrentSubscriptionHook';
import { Button } from 'antd';
import { systemColors } from '../colors/systemColors';

const Title = styled.div`
  color: #000;
  font-weight: 600;
`;

const SubscriptionCostSummary = styled.div`
  font-weight: 300;
  font-size: 18px;
  color: #000;
`;

const SubscriptionSummary = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 1px solid #eeeeee;
  padding: 20px 0px;
  width: 420px;
  margin-right: 50px;
  background: #ffffff;

  > ${Title}, > ${SubscriptionCostSummary} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const ChangePlanButton = styled(Button)`
  margin-left: 20px;
  margin-top: 10px;
`;

const FreeTrialBannerStyle = {
  backgroundColor: systemColors.background.fill,
  margin: '15px 15px 0px',
  border: '1px solid #e9f4ff',
};

export default ({ hideFreeTrialBanner = false, onChangePlanClick }) => {
  const currentSubscription = useCurrentSubscription();

  // Showing current subscription
  const endDate = fromUnixTime(currentSubscription.current_period_end);
  if (currentSubscription && currentSubscription.id === null) {
    return (
      <SubscriptionSummary>
        <Title>You're not subscribed to Intersect Labs</Title>
        {onChangePlanClick && (
          <ChangePlanButton onClick={onChangePlanClick}>
            See Subscription Options
          </ChangePlanButton>
        )}
      </SubscriptionSummary>
    );
  }
  const endDateFormatted = format(endDate, 'PPP');

  if (currentSubscription.amount_due === 0) {
    return (
      <SubscriptionSummary>
        <Title>You're subscription is set to end on {endDateFormatted}.</Title>
        {onChangePlanClick && (
          <ChangePlanButton onClick={onChangePlanClick}>
            See Subscription Options
          </ChangePlanButton>
        )}
      </SubscriptionSummary>
    );
  }

  const title = `${currentSubscription.product_name}`;
  const costText = `$${currentSubscription.amount_due / 100} on ${format(
    endDate,
    'PPP'
  )}`;

  return (
    <SubscriptionSummary>
      <Title>{title}</Title>
      <SubscriptionCostSummary>{costText}</SubscriptionCostSummary>
      {onChangePlanClick && (
        <ChangePlanButton onClick={onChangePlanClick}>
          Change Plan
        </ChangePlanButton>
      )}
      {!hideFreeTrialBanner && <FreeTrialBanner style={FreeTrialBannerStyle} />}
    </SubscriptionSummary>
  );
};
