import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  Tooltip,
  Input,
  Form,
  InputNumber,
  Select,
  Space,
  Spin,
} from 'antd';
import * as Actions from '../redux/actions';
import { get, mapValues, forEach } from 'lodash';
import { themeColors } from '../colors/themeColors';
import { systemColors } from '../colors/systemColors';

const Panel = styled(Space)`
  padding: 20px;
  background-color: ${systemColors.background.fill};
  border: 1px solid #dbe4ec;
  border-radius: 5px;
  text-align: center;
  min-width: 360px;
  max-width: 360px;
`;

const Subtitle = styled.div`
  font-weight: 300;
`;

const ColumnInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
`;

const ColumnInputLabel = styled.div`
  font-weight: 400;
`;

const ColumnInputForXAxis = styled.div`
  color: ${themeColors.primary.base};
  cursor: default;
`;

const getXAxisValues = (data) => {
  return get(data, 'scenarios[0].x_y_values.x');
};

const highestNumberInScenarioNames = (scenarios) => {
  let highestNumber = scenarios.length;
  forEach(scenarios, (scenario) => {
    const prefix = 'Scenario #';
    if (scenario.name.startsWith(prefix)) {
      const numberPiece = parseInt(scenario.name.substring(prefix.length), 10);
      if (!isNaN(numberPiece) && highestNumber < numberPiece) {
        highestNumber = numberPiece;
      }
    }
  });

  return highestNumber;
};

const doesNameExistInScenarios = (scenarios, scenarioName) => {
  const nameMatches = scenarios.filter(
    (scenario) => scenario.name === scenarioName
  );
  return nameMatches.length !== 0;
};

export default ({
  data,
  columnsSortedByPredictiveness,
  targetColumn = 'the outcome',
  projectId,
  modelId,
  simulationId,
  user,
  updateDataWithNewScenario,
}) => {
  const lineCount = highestNumberInScenarioNames(data.scenarios);
  const columnMetadata = data.column_metadata;
  const [loading, setLoading] = useState(false);
  const [scenarioName, setScenarioName] = useState(
    `Scenario #${lineCount + 1}`
  );

  const scenarioCount = get(data, 'scenarios.length');

  useEffect(() => {
    setScenarioName(`Scenario #${lineCount + 1}`);
  }, [lineCount, scenarioCount]);

  const defaultColumnData = mapValues(
    data.column_metadata,
    (col) => col.latest_value
  );
  const [columnData, setColumnData] = useState(defaultColumnData);
  const xAxisColumn = get(data, 'metadata.x_axis_column');

  const addScenario = () => {
    let params = {
      project_id: projectId,
      model_id: modelId,
      simulation_id: simulationId,
      scenario_name: scenarioName,
      column_values: JSON.stringify(columnData),
    };

    if (get(data, 'metadata.simulation_type') !== 'seeded') {
      params = {
        ...params,
        x_axis_column: xAxisColumn,
        x_axis_values: getXAxisValues(data),
      };
    }

    setLoading(true);
    fetch(`${Actions.API_PREFIX}/simulator/addScenario`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        ...Actions.getAuthHeaders(user),
        'Content-Type': 'application/json',
      },
    })
      .then(Actions.checkResponseSuccess)
      .then((data) => {
        console.log(data);
        const scenarioObject = {
          name: scenarioName,
          simulationId,
          id: data.id,
          column_values: columnData,
          x_y_values: { x: data.x, y: data.y },
        };
        updateDataWithNewScenario(scenarioObject);
        setLoading(false);
      })
      .catch((error) => {
        console.log('Add Scenario Error:', error);
        setLoading(false);
      });
  };

  const columnDataChanged = (columnName, value) => {
    setColumnData({
      ...columnData,
      [columnName]: value,
    });
  };

  const getColumnInput = (columnName) => {
    let input;
    const columnType = columnMetadata[columnName].type;

    if (columnName === xAxisColumn) {
      input = <ColumnInputForXAxis>x-axis</ColumnInputForXAxis>;
    } else if (columnType === 'continuous') {
      const { min, max } = columnMetadata[columnName];
      const currentValue = columnData[columnName];

      input = (
        <Tooltip placement="right" title={`Min: ${min}, Max: ${max}`}>
          <InputNumber
            min={min}
            max={max}
            value={currentValue}
            onChange={(value) => columnDataChanged(columnName, value)}
          />
        </Tooltip>
      );
    } else if (columnType === 'categorical') {
      const { options } = columnMetadata[columnName];
      const currentValue = columnData[columnName];
      input = (
        <Select
          value={currentValue}
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          style={{ minWidth: 120 }}
          onChange={(value) => columnDataChanged(columnName, value)}
        >
          {options.map((value) => (
            <Select.Option key={value} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      );
    }

    return (
      <ColumnInput key={columnName}>
        <ColumnInputLabel>{columnName}</ColumnInputLabel>
        {input}
      </ColumnInput>
    );
  };

  const nameExistsAlready = doesNameExistInScenarios(
    data.scenarios,
    scenarioName
  );
  let validateStatus = 'success';
  let helpMessage = '';
  if (nameExistsAlready) {
    validateStatus = 'error';
    helpMessage = 'Scenario name already exists.';
  }

  const inputHeader = (
    <Form layout="inline">
      <Form.Item
        hasFeedback={nameExistsAlready}
        validateStatus={validateStatus}
        help={helpMessage}
      >
        <Input
          placeholder="Scenario name..."
          onChange={(e) => setScenarioName(e.target.value)}
          value={scenarioName}
        />
      </Form.Item>
      <Form.Item style={{ marginRight: 0 }}>
        <Button
          type="primary"
          disabled={scenarioName.length === 0 || nameExistsAlready}
          onClick={addScenario}
        >
          Add Scenario
        </Button>
      </Form.Item>
    </Form>
  );

  const resetButtonClicked = () => {
    const defaultColumnData = mapValues(
      data.column_metadata,
      (col) => col.default_value
    );
    setColumnData(defaultColumnData);
  };

  return (
    <Spin spinning={loading} tip="Adding Scenario...">
      <Panel direction="vertical">
        {inputHeader}
        <Subtitle>
          Change the values and see how {targetColumn} changes.
        </Subtitle>
        <Button shape="round" onClick={resetButtonClicked}>
          Reset To Default Values
        </Button>
        <div>
          {columnsSortedByPredictiveness.map((columnName) =>
            getColumnInput(columnName)
          )}
        </div>
      </Panel>
    </Spin>
  );
};
