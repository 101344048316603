import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';
import styled from 'styled-components';
import IntersectLogo from '../images/Logo.svg';
import firebase from 'firebase/app';
import { emailVerified, markEmailVerified } from '../redux/authActions';
import { systemColors } from '../colors/systemColors';

const Logo = styled.img`
  margin: 50px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
`;

const MessageContainer = styled(Card)`
  max-width: 100%;
  width: 400px;
  margin: auto;
  padding: 10px 30px;
  background: ${systemColors.background.fill};
  margin-top: 3%;
  border-radius: 4px;
  border: 1px solid ${systemColors.background.border};
`;

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.loading,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    emailVerified: () => dispatch(emailVerified()),
    markEmailVerified: (email) => dispatch(markEmailVerified(email)),
  };
};

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: '',
      buttonVisible: false,
    };
  }

  handleVerifyEmail() {
    firebase
      .auth()
      .checkActionCode(this.props.actionCode)
      .then(
        function (resp) {
          this.props.markEmailVerified(resp.data.email);
        }.bind(this)
      );
    firebase
      .auth()
      .applyActionCode(this.props.actionCode)
      .then(
        function (resp) {
          this.setState({
            alertMessage: 'Your email has been verified!',
          });
          this.props.emailVerified();
        }.bind(this)
      )
      .catch(
        function (error) {
          this.setState({
            alertMessage: 'There was an error. Please contact system admin.',
          });
        }.bind(this)
      );
  }

  componentWillMount() {
    this.handleVerifyEmail();
  }

  render() {
    return (
      <StyledDiv>
        <Logo src={IntersectLogo} title="Intersect Labs" />
        <MessageContainer>
          <p>{this.state.alertMessage}</p>
        </MessageContainer>
      </StyledDiv>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
