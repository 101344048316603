export default (getPipelineContext) => {
  const { network, setters } = getPipelineContext();

  setters.setIsLoading(true);
  fetch(`${network.API_PREFIX}/pasteur/transforms`, {
    method: 'GET',
    headers: {
      ...network.authHeaders,
    },
  })
    .then(network.checkResponseSuccess)
    .then((data) => {
      setters.setAllTransformsList(data);
      setters.setIsLoading(false);
    })
    .catch((error) => {
      setters.setIsLoading(false);
      console.error(`Error fetching data list`, error);
      // notification.error({
      //   message: "Couldn't fetch data",
      //   description:
      //     "Please check your internet connection or contact support."
      // });
    });
};
