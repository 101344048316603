import React from 'react';
import {
  Button,
  Empty,
  Switch,
  Input,
  InputNumber,
  Form as AntForm,
  Select,
  Tooltip,
} from 'antd';
import {
  UpOutlined,
  DownOutlined,
  PlusOutlined,
  MinusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { get, unset } from 'lodash';
import styled from 'styled-components';

const ArrayItemRemoveButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    margin-right: 5px;
    margin-top: 5px;
  }

  button:last-child {
    margin-right: -15px;
  }
`;

const ArrayItem = styled.div`
  padding: 0px 20px;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-bottom: 10px;

  & .field-object {
    margin-bottom: 0px;
  }
`;

const CustomTextInput = function (props) {
  const adaptedProps = {
    ...props,
    autoFocus: props.autofocus,
    readOnly: props.readonly,
  };

  unset(adaptedProps, 'autofocus');
  unset(adaptedProps, 'readonly');
  unset(adaptedProps, 'rawErrors');
  unset(adaptedProps, 'formContext');

  if (get(props, 'schema.type') === 'number') {
    return <InputNumber {...adaptedProps} />;
  }

  return (
    <Input
      {...adaptedProps}
      style={{ width: 300 }}
      onChange={(e) => props.onChange(e.currentTarget.value)}
    />
  );
};

const CustomCheckboxes = function (props) {
  return (
    <Switch
      onChange={(checked) => props.onChange(checked)}
      checked={props.value}
      autoFocus={props.autofocus}
      readOnly={props.readonly}
    />
  );
};

const CustomSelect = function (props) {
  const adaptedProps = {
    value: props.value,
    placeholder: props.placeholder,
    id: props.id,
    required: props.required,
    autoFocus: props.autofocus,
    readOnly: props.readonly,
  };

  return (
    <Select
      style={{ width: 300 }}
      {...adaptedProps}
      mode={props.multiple ? 'multiple' : 'default'}
      onChange={(value) => props.onChange(value)}
      showSearch
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      notFoundContent={
        <Empty description="No valid options are available for this data set" />
      }
    >
      {props.options.enumOptions.map(({ value, label }) => (
        <Select.Option key={value} value={value}>
          {label}
        </Select.Option>
      ))}
    </Select>
  );
};

const CustomTitleField = () => {
  return <></>;
};

export const CustomFieldTemplate = (props) => {
  const {
    id,
    classNames,
    label,
    help,
    required,
    // description,
    rawDescription,
    rawErrors,
    errors,
    children,
  } = props;
  if (id === 'root') {
    return (
      <div className={classNames}>
        {children}
        {errors}
        {help}
      </div>
    );
  }

  const labelView = (
    <span>
      {required && <span style={{ color: 'red' }}>* </span>}
      {label}
      {rawDescription && (
        <Tooltip title={rawDescription}>
          <QuestionCircleOutlined style={{ marginLeft: 5 }} />
        </Tooltip>
      )}
    </span>
  );
  const hasErrors = rawErrors !== undefined;
  let formattedErrors;
  if (hasErrors) {
    formattedErrors = rawErrors.map((error, i) => <div key={i}>{error}</div>);
  }

  return (
    <AntForm.Item
      label={label && labelView}
      hasFeedback={hasErrors}
      validateStatus={hasErrors ? 'error' : undefined}
      className={classNames}
      help={formattedErrors || undefined}
    >
      {children}
    </AntForm.Item>
  );
};

export const ArrayFieldTemplate = (props) => {
  return (
    <div>
      {props.items.map((item, index) => {
        return (
          <ArrayItem key={index}>
            <ArrayItemRemoveButtonRow>
              {item.hasMoveDown && (
                <Button
                  icon={<DownOutlined />}
                  size="small"
                  onClick={item.onReorderClick(item.index, item.index + 1)}
                />
              )}
              {item.hasMoveUp && (
                <Button
                  icon={<UpOutlined />}
                  size="small"
                  onClick={item.onReorderClick(item.index, item.index - 1)}
                />
              )}
              {item.hasRemove && (
                <Button
                  icon={<MinusOutlined />}
                  size="small"
                  type="danger"
                  onClick={item.onDropIndexClick(item.index)}
                />
              )}
            </ArrayItemRemoveButtonRow>
            {item.children}
          </ArrayItem>
        );
      })}
      {props.canAdd && (
        <Button
          icon={<PlusOutlined />}
          size="small"
          type="primary"
          onClick={props.onAddClick}
        >
          Add
        </Button>
      )}
    </div>
  );
};

export const fields = {
  TitleField: CustomTitleField,
};

export const widgets = {
  BaseInput: CustomTextInput,
  SelectWidget: CustomSelect,
  CheckboxWidget: CustomCheckboxes,
};
