import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, Checkbox, Card, Button } from 'antd';
import IntersectLogo from '../images/Logo.svg';
import firebase from 'firebase/app';
import {
  fetchToken,
  letsLogIn,
  needToVerifyEmail,
  loginInitialize,
  decryptSignupLink,
} from '../redux/authActions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from '../project-components/Loading';
import { withRouter } from 'react-router-dom';
import { systemColors } from '../colors/systemColors';
// import ReactPixel from "react-facebook-pixel";

// const options = {
//   autoConfig: true, // set pixel's autoConfig
//   debug: false // enable logs
// };

// ReactPixel.init("2221644288099193", {}, options);

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
  padding-bottom: 40px;
`;

const SignUpForm = styled(Card)`
  max-width: 100%;
  width: 400px;
  margin: auto;
  padding: 10px 30px;
  background: ${systemColors.background.fill};
  margin-top: 3%;
  border-radius: 4px;
  border: ${systemColors.background.border};
`;

const Title = styled.p`
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 300;
  margin-bottom: 25px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 15px;
`;

const StyledPasswordInput = styled(Input.Password)`
  margin-bottom: 15px;
`;

const Logo = styled.img`
  margin-top: 50px;
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: red;
  margin: 10px;
`;

const TCPPWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const TCPPText = styled.p`
  font-size: 13px;
  margin-left: 13px;
  text-align: left;
`;

const SubmitButton = styled(Button)`
  margin: 15px;
`;

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.loading,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchCustomToken: (name, utm, product) => {
      dispatch(fetchToken(name, utm, product));
    },
    letsLogin: () => dispatch(letsLogIn()),
    needToVerifyEmail: () => dispatch(needToVerifyEmail()),
    initializeLogin: () => {
      dispatch(loginInitialize());
    },
    decryptSignupLink: (signupLink) => {
      dispatch(decryptSignupLink(signupLink));
    },
  };
};

const SignUp = ({
  match,
  location,
  user,
  initializeLogin,
  decryptSignupLink,
  fetchCustomToken,
}) => {
  const [fields, setFields] = useState({
    fullName: '',
    emailAddress: undefined,
    passwordOne: '',
    passwordTwo: '',
  });
  const [error, setError] = useState('');
  const [tcpp, setTcpp] = useState(false);
  const [signupInProgress, setSignupInProgress] = useState(false);
  const [emailDisabledBool, setEmailDisabledBool] = useState(false);

  const signupLink = match.params.signupLink;

  useEffect(() => {
    initializeLogin();
    if (signupLink !== undefined) {
      decryptSignupLink(signupLink);
    }
  }, [signupLink, initializeLogin, decryptSignupLink]);

  useEffect(() => {
    setFields((fields) => ({ ...fields, emailAddress: user.email }));
    console.log('user', user);
    if (user.email !== '') {
      setEmailDisabledBool(true);
    } else {
      setEmailDisabledBool(false);
    }
  }, [user]);

  const handleChangeInput = (field, value) => {
    setFields((fields) => ({ ...fields, [field]: value }));
    setError('');
  };

  const handleChangeTcpp = () => {
    setTcpp((tcpp) => !tcpp);
    setError('');
  };

  const stringNotEmpty = (x) => {
    return x.length > 0;
  };

  const incompleteInputs = () => {
    const inputArray = [
      fields.emailAddress,
      fields.passwordOne,
      fields.passwordTwo,
      fields.fullName,
    ];
    if (!inputArray.every(stringNotEmpty)) {
      setError('All inputs are required');
      return true;
    } else if (fields.passwordOne !== fields.passwordTwo) {
      setError("Passwords don't match");
      return true;
    } else if (!tcpp) {
      setError(
        'You cannot signup if you do not agree with the Terms of Service and Privacy Policy'
      );
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = () => {
    const inputIncompleteStatus = incompleteInputs();
    if (!inputIncompleteStatus) {
      setSignupInProgress(true);
      firebase
        .auth()
        .createUserWithEmailAndPassword(fields.emailAddress, fields.passwordOne)
        .then(function () {
          fetchCustomToken(fields.fullName, location.search, user.product);
          // ReactPixel.track("StartTrial", "");
        })
        .catch(function (error) {
          setSignupInProgress(false);
          var errorCode = error.code;
          if (errorCode === 'auth/weak-password') {
            setError('Password is too weak');
          } else if (errorCode === 'invalid-email') {
            setError('Invalid email');
          } else if (errorCode === 'auth/email-already-in-use') {
            setError('Account exists already. Sign in instead');
          }
        });
    }
  };

  const getStyledInput = (placeholder, fieldName, Input = StyledInput) => {
    return (
      <Input
        placeholder={placeholder}
        onChange={(e) => {
          handleChangeInput(fieldName, e.target.value);
        }}
      />
    );
  };

  return (
    <StyledDiv>
      <Logo src={IntersectLogo} title="Intersect Labs" />
      <SignUpForm>
        <Title>Sign Up</Title>

        {getStyledInput('Full name', 'fullName')}
        <StyledInput
          value={fields.emailAddress}
          placeholder="Work email address"
          onChange={(e) => {
            handleChangeInput('emailAddress', e.target.value);
          }}
          disabled={emailDisabledBool}
        />
        {getStyledInput('Password', 'passwordOne', StyledPasswordInput)}
        {getStyledInput(
          'Re-enter password',
          'passwordTwo',
          StyledPasswordInput
        )}

        <ErrorText>{error}</ErrorText>
        <TCPPWrapper>
          <Checkbox onChange={handleChangeTcpp} />
          <TCPPText>
            By signing up, you confirm that you agree with the{' '}
            <a
              href="https://www.intersectlabs.io/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://www.intersectlabs.io/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </TCPPText>
        </TCPPWrapper>
        {signupInProgress ? (
          <Loading />
        ) : (
          <SubmitButton onClick={onSubmit} type="primary">
            Sign up
          </SubmitButton>
        )}
      </SignUpForm>
      <SignUpForm>
        <Link to={`/login`}>
          <SubmitButton>Log In</SubmitButton>
        </Link>
      </SignUpForm>
    </StyledDiv>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUp));
