import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Tooltip, InputNumber, Input, Select, Space, Spin } from 'antd';
import { mapValues } from 'lodash';
import { systemColors } from '../colors/systemColors';
import { smartRound } from './Helpers';
import { useAPI } from '../api/useAPI';

const Panel = styled(Space)`
  padding: 20px;
  background-color: ${systemColors.background.fill};
  border: 1px solid #dbe4ec;
  border-radius: 5px;
  min-width: 500px;
  max-width: 500px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PredictionValue = styled.div`
  font-weight: bold;
  font-size: 24px;
`;

const PredictionValueWrapper = styled.div`
  visibility: ${({ predictionValue }) =>
    predictionValue ? 'visible' : 'hidden'};
  text-align: right;
`;

const Prediction = ({ predictionValue }) => {
  if (!predictionValue) return null;
  if (typeof predictionValue === 'object') {
    const likelihoods = Object.values(predictionValue);
    const maxLikelihood = Math.max(...likelihoods);
    const maxIndex = likelihoods.indexOf(maxLikelihood);
    const mostLikelyCategory = Object.keys(predictionValue)[maxIndex];
    return (
      <PredictionValueWrapper predictionValue={predictionValue}>
        Prediction {`Most likely category is ${mostLikelyCategory}`}
        <PredictionValue>
          {smartRound(100 * maxLikelihood, 0)}% likelihood
        </PredictionValue>
      </PredictionValueWrapper>
    );
  }
  return (
    <PredictionValueWrapper predictionValue={predictionValue}>
      Prediction:{' '}
      <PredictionValue>{smartRound(predictionValue)}</PredictionValue>
    </PredictionValueWrapper>
  );
};

const ColumnInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //align-items: center;
  margin: 15px 0px;
`;

const ColumnInputLabel = styled.div`
  font-weight: 400;
  text-align: left;
`;

export default ({ title, projectId }) => {
  const [data, { isLoading }] = useAPI.makeCall({
    endpoint: `/predict/form/metadata/${projectId}`,
  });

  const columnMetadata = data && data.column_metadata;
  const [predictionIsLoading, setLoading] = useState(false);

  const [columnData, setColumnData] = useState(null);
  useEffect(
    // Seed column data with default data when data loads
    () =>
      data &&
      setColumnData(
        mapValues(data.column_metadata, (col) => col.default_value)
      ),
    [data, setColumnData]
  );

  // Data fetching
  const [predictionValue, setPredictionValue] = useState(null);
  const fetchPrediction = useAPI.getCallbackFn({
    endpoint: '/predict/form/',
    method: 'POST',
  });

  const handleMakePrediction = () => {
    if (!columnData) return;
    const pandasReadyData = Object.keys(columnData).reduce(
      (accum, col) => ({ ...accum, [col]: [columnData[col]] }),
      {}
    );
    setLoading(true);
    fetchPrediction({
      body: { project: projectId, data: pandasReadyData },
    })
      .then((res) => {
        const _predictionValue = JSON.parse(res[0])[0];
        setPredictionValue(_predictionValue);
      })
      .finally(() => setLoading(false));
  };

  const columnDataChanged = (columnName, value) => {
    setColumnData({
      ...columnData,
      [columnName]: value,
    });
    setPredictionValue(null);
  };

  const getColumnInput = (columnName) => {
    if (!columnData) return;
    let input;
    const columnType = columnMetadata[columnName].type;
    if (!columnType) {
      input = (
        <Input
          onChange={(value) => columnDataChanged(columnName, value)}
          style={{ width: 90 }}
        />
      );
    } else if (columnType === 'continuous') {
      const { min, max } = columnMetadata[columnName];
      const currentValue = columnData[columnName];

      input = (
        <Tooltip placement="right" title={`Min: ${min}, Max: ${max}`}>
          <InputNumber
            min={min}
            max={max}
            value={currentValue}
            onChange={(value) => columnDataChanged(columnName, value)}
          />
        </Tooltip>
      );
    } else if (columnType === 'categorical') {
      const { options } = columnMetadata[columnName];
      const currentValue = columnData[columnName];
      input = (
        <Select
          value={currentValue}
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          style={{ minWidth: 120 }}
          onChange={(value) => columnDataChanged(columnName, value)}
        >
          {options.map((value) => (
            <Select.Option key={value} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      );
    }

    return (
      <ColumnInput key={columnName}>
        <ColumnInputLabel>{columnName}</ColumnInputLabel>
        {input}
      </ColumnInput>
    );
  };

  return (
    <Spin spinning={predictionIsLoading || isLoading} tip="Fetching data...">
      <Panel direction="vertical">
        <HeaderContainer>
          <Button
            type="primary"
            disabled={false}
            onClick={handleMakePrediction}
          >
            {title}
          </Button>

          <Prediction predictionValue={predictionValue} />
        </HeaderContainer>

        <div>
          {columnMetadata &&
            Object.keys(columnMetadata).map((columnName) =>
              getColumnInput(columnName)
            )}
        </div>
      </Panel>
    </Spin>
  );
};
