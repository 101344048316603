import { TYPES } from '../authActions';

const user = (state = {}, action) => {
  switch (action.type) {
    case TYPES.RECEIVE_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
      };
    case TYPES.LOGOUT:
      return {
        ...state,
        accessToken: undefined,
        refreshToken: undefined,
        name: undefined,
        email: undefined,
        user_id: undefined,
        created_time: undefined,
      };
    case TYPES.SIGNUP_INTENDED:
      return { ...state, intent: 'signup' };
    case TYPES.LOGIN_INTENDED:
      return { ...state, intent: 'login' };
    case TYPES.PASSWORD_RESET:
      return { ...state, intent: 'password_reset' };
    case TYPES.EMAIL_VERIFICATION_NEEDED:
      return { ...state, email_verified: false };
    case TYPES.EMAIL_VERIFIED:
      return { ...state, email_verified: true };
    case TYPES.RECEIVE_SUBSCRIPTION_INFO:
      return {
        ...state,
        subscription: { ...state.subscription, ...action.subscription },
      };
    case TYPES.RECEIVE_BASIC_INFO:
      return {
        ...state,
        name: action.name,
        email: action.email,
        user_id: action.id,
        created_time: action.created_time,
        product: action.product,
        subscription: action.subscription,
      };
    default:
      return state;
  }
};

export default user;
