import React from 'react';

export const LATEST_PREDICTION_DATA_KEY =
  'intersectlabs.io.latestPredictionData';
export const LATEST_PREDICTION_RESULTS_KEY =
  'intersectlabs.io.latestPredictionResults';

export const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    JSON.parse(localStorage.getItem(localStorageKey))
  );

  React.useEffect(() => {
    try {
      localStorage.setItem(localStorageKey, JSON.stringify(value));
    } catch (domException) {
      if (
        ['QuotaExceededError', 'NS_ERROR_DOM_QUOTA_REACHED'].includes(
          domException.name
        )
      ) {
        // handle quota limit exceeded error
        console.error(
          'Could not save predictions locally because predictions file was too large'
        );
      } else {
        console.error(
          'Could not save predictions locally for some other reason',
          domException.name
        );
      }
    }
    // eslint-disable-next-line
  }, [value]);

  return [value, setValue];
};
