import React from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import { WarningFilled, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { themeColors } from '../colors/themeColors';
import { systemColors } from '../colors/systemColors';

export const Colors = {
  default: {
    base: themeColors.secondary.dark,
    darker: themeColors.secondary.darker,
    hover: themeColors.secondary.hover,
    dimText: themeColors.secondary.dimText,
  },
  selected: {
    dimText: themeColors.secondary.selected.dimText,
    hover: themeColors.secondary.selected.base,
    base: themeColors.secondary.selected.base,
    darker: themeColors.secondary.selected.darker,
  },
  error: {
    base: '#636363',
    darker: '#424242',
    hover: '#717171',
    dimText: '#A8A8A8',
  },
  errorSelected: {
    base: '#272727',
    darker: '#4E4E4E',
    hover: '#272727',
    dimText: '#A8A8A8',
  },
};

const PipelineStep = styled.div`
  padding: 10px;
  background-color: ${(p) => p.colors.base};
  margin: 0px ${(p) => (p.extraMargin ? '40px' : '30px')};
  border-radius: 5px;
  cursor: pointer;
  transition: ${(p) => (p.selected ? 'none' : '0.2s background-color')};
  color: #ffffff;

  :hover {
    background-color: ${(p) => p.colors.hover};
  }
`;

const ApplyPipelineStepWrapper = styled(PipelineStep)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const KeyValueTitle = styled.span`
  color: ${(p) => p.colors.dimText};
  font-weight: 600;
  margin-right: 10px;
  flex-shrink: 0;
`;

const ColumnInfo = styled.div`
  background-color: ${(p) => p.colors.darker};
  color: #fff;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 5px;
  flex-grow: 1;
  margin-top: ${(p) => (p.marginTop ? '10px' : '0px')};
`;

const EllipsisText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PipelineStepTitle = styled(EllipsisText)`
  padding: 0px 5px;
`;

const ErrorIcon = styled(WarningFilled)`
  color: ${systemColors.gold.base};
  font-size: 22px;
  margin-right: 5px;
`;

const DividerLine = styled.div`
  background-color: ${systemColors.background.border};
  width: 5px;
  height: 25px;
  overflow: visible;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MiniAddTransformButton = styled(Button)`
  opacity: 0;
  transition: opacity 50ms;

  min-width: 30px;
  min-height: 16px;
  max-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    opacity: 1;
    color: #ffffff;
    font-size: 12px;
    border-radius: 4px;
    background-color: ${themeColors.secondary.darker};
    border-color: transparent;
  }

  &:hover > span.anticon-plus {
    padding-top: 2px;
  }
`;

const getColors = (selected, stepHasError) => {
  if (selected) {
    if (stepHasError) {
      return Colors.errorSelected;
    }

    return Colors.selected;
  } else if (stepHasError) {
    return Colors.error;
  }

  return Colors.default;
};

export const ApplyPipelineStep = ({ selected, aPipelineExists, onClick }) => {
  const colors = getColors(selected, false);
  const stepText = aPipelineExists ? 'Apply Pipeline' : 'Get Started';
  return (
    <ApplyPipelineStepWrapper
      selected={selected}
      extraMargin
      onClick={onClick}
      colors={colors}
    >
      <span>{stepText}</span>
      <RightOutlined />
    </ApplyPipelineStepWrapper>
  );
};

const getKeyValueView = (
  key,
  value,
  selected,
  colors,
  isDraft = false,
  marginTop = true
) => {
  const valueIsArray = Array.isArray(value);
  if (isDraft) {
    return (
      <ColumnInfo marginTop={marginTop} selected={selected} colors={colors}>
        <KeyValueTitle colors={colors}>Not Applied</KeyValueTitle>
      </ColumnInfo>
    );
  } else if (value === undefined || (valueIsArray && value.length === 0)) {
    return <></>;
  }

  let formattedValue = value;
  if (valueIsArray) formattedValue = value.join(', ');
  return (
    <Tooltip title={formattedValue} placement="right">
      <ColumnInfo marginTop={marginTop} selected={selected} colors={colors}>
        <KeyValueTitle colors={colors}>{key}</KeyValueTitle>
        <EllipsisText>{formattedValue}</EllipsisText>
      </ColumnInfo>
    </Tooltip>
  );
};

const getDataSetStep = (dataSetName, selected, onClick) => {
  const colors = getColors(selected, false);
  return (
    <PipelineStep
      selected={selected}
      extraMargin
      onClick={onClick}
      colors={colors}
    >
      {getKeyValueView('Data Set', dataSetName, selected, colors, false, false)}
    </PipelineStep>
  );
};

const getPipelineStep = (
  key,
  stepName,
  columns,
  selected,
  onClick,
  stepHasError,
  isDraft
) => {
  const colors = getColors(selected, stepHasError || isDraft);
  return (
    <PipelineStep
      key={key}
      selected={selected}
      colors={colors}
      onClick={onClick}
      stepHasError={stepHasError}
    >
      <Row>
        {stepHasError && <ErrorIcon />}
        <PipelineStepTitle>{stepName}</PipelineStepTitle>
      </Row>
      {getKeyValueView('Columns', columns, selected, colors, isDraft)}
    </PipelineStep>
  );
};

const NodeConnector = ({
  key = undefined,
  onAddClick = false,
  disabled = false,
}) => (
  <DividerLine key={key}>
    {onAddClick && (
      <MiniAddTransformButton
        onClick={onAddClick}
        disabled={disabled}
        data-btn-id="mid-pipeline-add-transform"
      >
        <PlusOutlined />
      </MiniAddTransformButton>
    )}
  </DividerLine>
);

export { getDataSetStep, getPipelineStep, NodeConnector };
