import React, { useState } from 'react';
import { Menu, Button, Tag } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import CSVUpload from './upload-panels/csvUpload';
import CurrentDataSetListView from './CurrentDataSetListView';
import { systemColors } from '../colors/systemColors';

const KEYS = {
  currentDataSets: 'currentDataSets',
  uploadCSV: 'uploadCSV',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60vh;
`;

const Footer = styled.div`
  padding: 15px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Error = styled.div`
  color: red;
`;

const Selections = styled.div``;

const SelectedDataSource = styled(Tag)``;

const UploadModal = ({ dataSetsSelected, getDataSetName }) => {
  const [selectedPanelKey, setSelectedPanelKey] = useState(
    KEYS.currentDataSets
  );
  const [selectedExistingDataSets, setSelectedExistingDataSets] = useState([]);
  const [selectedNewDataSets, setSelectedNewDataSets] = useState([]);

  const sidebar = (
    <Menu
      onClick={({ key }) => setSelectedPanelKey(key)}
      style={{ width: 200, background: systemColors.background.fill }}
      defaultSelectedKeys={[selectedPanelKey]}
      mode="inline"
    >
      <Menu.ItemGroup key="g1" title="Existing Data">
        <Menu.Item key={KEYS.currentDataSets}>Current Data Sets</Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup key="g2" title="Upload Data">
        <Menu.Item key={KEYS.uploadCSV}>Upload CSV</Menu.Item>
        <Menu.Item key="placeholder1" disabled={true}>
          Google Sheets
        </Menu.Item>
        <Menu.Item key="placeholder2" disabled={true}>
          MongoDB
        </Menu.Item>
        <Menu.Item key="placeholder2" disabled={true}>
          SQL database
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  let content;
  if (selectedPanelKey === KEYS.currentDataSets) {
    content = (
      <CurrentDataSetListView
        setSelectedExistingDataSets={setSelectedExistingDataSets}
        selectedExistingDataSets={selectedExistingDataSets}
      />
    );
  } else {
    content = <CSVUpload setSelectedNewDataSets={setSelectedNewDataSets} />;
  }

  let selections = [...selectedExistingDataSets, ...selectedNewDataSets];

  let selectionNames = selections.map((dataSet) => {
    const name = getDataSetName(dataSet);
    return <SelectedDataSource key={name}>{name}</SelectedDataSource>;
  });
  const noDatasetSelected = selections.length === 0;
  const multipleDataSetsSelected = selections.length > 1;
  if (noDatasetSelected) selections = 'No datasets selected';

  return (
    <Container>
      <Body>
        {sidebar}
        {content}
      </Body>
      <Footer>
        <Selections>{selectionNames}</Selections>
        {multipleDataSetsSelected && (
          <Error>Please only select one dataset.</Error>
        )}
        <Button
          disabled={noDatasetSelected || multipleDataSetsSelected}
          onClick={() => dataSetsSelected(selections)}
          type="primary"
        >
          Confirm Columns <RightOutlined />
        </Button>
      </Footer>
    </Container>
  );
};

export default UploadModal;
