import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Table, Popconfirm } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as Actions from '../redux/actions';
import { themeColors } from '../colors/themeColors';

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

const Subtitle = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const SimulationTable = styled(Table)`
  margin-bottom: 15px;
  border: 2px solid #f4f5f2;

  thead > tr > th {
    background: #f4f3fb !important;
    color: ${themeColors.secondary.dark} !important;
  }
`;

export default ({
  projectName,
  projectId,
  modelId,
  predictiveColumns,
  user,
}) => {
  const [simulations, setSimulations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const deleteSimulation = (simulationId) => {
    const updatedSimulations = simulations.filter(
      (simulation) => simulation.id !== simulationId
    );
    setSimulations(updatedSimulations);
    fetch(`${Actions.API_PREFIX}/simulator/delete/${simulationId}`, {
      method: 'POST',
      headers: {
        ...Actions.getAuthHeaders(user),
        'Content-Type': 'application/json',
      },
    })
      .then(Actions.checkResponseSuccess)
      .then((data) => {
        console.log('Deleted simulation:', simulationId);
      })
      .catch((error) => {
        console.error('deleteSimulation Error:', error);
        // TODO handle error
      });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <Link
          to={{
            pathname: `/simulation/${projectId}/${modelId}/${record.id}`,
            state: {
              predictiveColumns,
              projectName,
            },
          }}
        >
          {name}
        </Link>
      ),
    },
    {
      title: 'Date Updated',
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'right',
      width: 200,
      className: 'simulation-date-column',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      align: 'right',
      width: 75,
      render: (name, record) => (
        <Popconfirm
          title="Are you sure delete this simulation?"
          onConfirm={() => deleteSimulation(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    const authHeaders = Actions.getAuthHeaders(user);
    fetch(`${Actions.API_PREFIX}/simulator/project/${projectId}`, {
      method: 'GET',
      headers: {
        ...authHeaders,
      },
    })
      .then(Actions.checkResponseSuccess)
      .then((data) => {
        setIsLoading(false);
        console.log('data', data);
        if (Array.isArray(data)) setSimulations(data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching simulations', error);
      });
  }, [projectId, modelId, user]);

  return (
    <>
      <Title>Simulations</Title>
      <Subtitle>See how changing your data affects your outcomes.</Subtitle>
      <SimulationTable
        columns={columns}
        loading={isLoading}
        rowClassName="simulation-table-row"
        dataSource={simulations}
        locale={{
          filterConfirm: 'Ok',
          filterReset: 'Reset',
          emptyText: 'Add a simulation to get started.',
        }}
        pagination={{
          size: 'small',
          style: { float: 'none' },
          hideOnSinglePage: true,
          defaultPageSize: 5,
        }}
      />
      <Link
        to={{
          pathname: `/simulation/${projectId}/${modelId}/new`,
          state: {
            predictiveColumns,
            projectName,
            simulations,
          },
        }}
      >
        <Button type="primary" className="btn-purple" icon={<PlusOutlined />}>
          New Simulation
        </Button>
      </Link>
    </>
  );
};
