import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Input } from 'antd';
import { getColumnSelect } from '../project-components/Question';
import { API_PREFIX, checkResponseSuccess } from '../redux/actions';
import useAuthHeaders from '../redux/authHeadersHook';

const InitialQuestion = styled.div`
  margin: 40px 10px;
`;

export default ({
  projectId,
  isSeeded = false,
  savedSimulationName,
  columns,
  simulationsList,
  startSimulation,
  error = {},
}) => {
  const [simulationName, setSimulationName] = useState(
    error.newSimulationName || savedSimulationName
  );
  const [xAxisColumn, setXAxisColumn] = useState(columns[0]);

  const extractNamesFromList = (list) =>
    list.map((simulation) => simulation.name);
  const [usedSimulationNames, setUsedSimulationNames] = useState(
    simulationsList && extractNamesFromList(simulationsList)
  );
  const authHeaders = useAuthHeaders();

  const onStartSimulationClick = () => {
    startSimulation(simulationName, xAxisColumn);
  };

  useEffect(() => {
    console.log(usedSimulationNames);
    if (!usedSimulationNames) {
      fetch(`${API_PREFIX}/simulator/project/${projectId}`, {
        method: 'GET',
        headers: {
          ...authHeaders,
        },
      })
        .then(checkResponseSuccess)
        .then((data) => {
          if (Array.isArray(data))
            setUsedSimulationNames(extractNamesFromList(data));
        })
        .catch((error) => {
          console.error('Error fetching simulations', error);
        });
    }
  }, [usedSimulationNames, authHeaders, projectId]);

  const submittedNameMatchesCurrentName =
    error.newSimulationName === simulationName;
  const serverSaysNameExistsAlready =
    error.message === 'SIMULATION_NAME_EXISTS_ALREADY' &&
    submittedNameMatchesCurrentName;
  const nameExistsAlready =
    (usedSimulationNames && usedSimulationNames.includes(simulationName)) ||
    serverSaysNameExistsAlready;
  let validateStatus = 'success';
  let helpMessage = '';
  if (nameExistsAlready) {
    validateStatus = 'error';
    helpMessage = 'Simulation name already exists.';
  }

  return (
    <InitialQuestion>
      <Form layout="horizontal" style={{ textAlign: 'left' }}>
        <Form.Item
          label="Name your simulation:"
          hasFeedback={nameExistsAlready}
          validateStatus={validateStatus}
          help={helpMessage}
        >
          <Input
            placeholder="Simulation name..."
            onChange={(e) => setSimulationName(e.target.value)}
            defaultValue={simulationName}
          />
        </Form.Item>
        {!isSeeded && (
          <Form.Item label="What should the x-axis of the simulation be?">
            {getColumnSelect(columns, setXAxisColumn, columns[0])}
          </Form.Item>
        )}
        <Form.Item>
          <Button
            type="primary"
            disabled={
              !simulationName ||
              (!isSeeded && !xAxisColumn) ||
              nameExistsAlready
            }
            onClick={onStartSimulationClick}
          >
            Start Simulation
          </Button>
        </Form.Item>
      </Form>
    </InitialQuestion>
  );
};
