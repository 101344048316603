import React, { useState } from 'react';
import CurrentDataSetListView from '../data-set-components/CurrentDataSetListView';
import styled from 'styled-components';
import { Input } from 'antd';

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 0px 0px 30px;
  min-height: 28px;
`;

const Title = styled.div`
  font-size: 18px;
`;

const DataSetListPage = () => {
  const [filter, setFilter] = useState(null);

  return (
    <Container>
      <TitleRow>
        <Title>Data Sets</Title>
        <div>
          <Input.Search
            placeholder="Find a data set..."
            onChange={(e) => setFilter(e.target.value)}
            style={{ width: 200, marginRight: 30 }}
          />
          {/* <NewDataSetButton icon={<UploadOutlined />} onClick={() => console.log('show modal')}>
            New Data Set
          </NewDataSetButton> */}
        </div>
      </TitleRow>
      <CurrentDataSetListView expanded={true} searchFilter={filter} />
    </Container>
  );
};

export default DataSetListPage;
