export const systemColors = {
  background: {
    fill: '#f8fbfd',
    border: '#e6f5ff',
  },
  gold: {
    // both only used in css:
    light: '#ffe490',
    border: '#e8c353',

    base: '#ffd555',
    cheezIt: '#faad14',
    dark: '#b98b00', // 1nce in css
    tiredSand: '#776520',
    opaqueFill: 'rgba(255,213,24,0.08)', // @Sinchan 1nce in css
  },
  error: {
    hot: '#ff0000',
    fratPants: '#ff7875',
    beerStain: 'rgba(255, 77, 79, 0.2)', // css only
  },
};
