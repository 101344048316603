import React from 'react';
import { Button, Popover, Tooltip, Popconfirm } from 'antd';
import {
  ProjectOutlined,
  DownloadOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
  InfoOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

const DetailsElement = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsElementTitle = styled.div`
  margin-top: ${({ isFirst }) => (isFirst ? 0 : 10)}px;
  text-transform: uppercase;
  color: #bbb;
  font-size: 12px;
  font-weight: 600;
`;

const ProjectIcon = styled(ProjectOutlined)`
  color: #bbb;
  margin-right: 5px;
`;

const ActionButtonCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ActionButton = styled(Button)`
  margin-left: 10px;
`;

const MutedText = styled.div`
  color: #bbb;
`;

const renderUsedInProjects = (projects) =>
  projects.map((project) => (
    <div key={project.name}>
      <ProjectIcon />
      {project.name}
    </div>
  ));

export const expandedColumns = (deleteDataSet, downloadDataSet) => [
  {
    title: 'Filename',
    dataIndex: 'filename',
  },
  {
    title: 'Used In Projects',
    dataIndex: 'projects',
    key: 'projects',
    render: (projects, dataset) => {
      let cellContent = <MutedText>Not used yet</MutedText>;
      const projectCount = projects.length;

      if (projectCount > 0) {
        const firstProject = `${projects[0].name}`;
        if (projectCount > 1) {
          const content = (
            <DetailsElement>
              <div>{renderUsedInProjects(projects).slice(1)}</div>
            </DetailsElement>
          );

          cellContent = (
            <>
              {firstProject}
              <Popover content={content} title={null} placement="right">
                <ActionButton size="small" shape="round">
                  {`and ${projectCount - 1} more projects`}
                </ActionButton>
              </Popover>
            </>
          );
        } else {
          cellContent = firstProject;
        }
      }

      return <div key={`usedIn ${dataset.id}`}>{cellContent}</div>;
    },
  },
  {
    title: 'Uploaded At',
    dataIndex: 'created_time',
    key: 'created_time',
    width: 250,
  },
  {
    title: '',
    dataIndex: 'projects',
    key: 'actions',
    width: 100,
    render: (projects, dataset) => {
      const downloadButton = (
        <Button
          shape="circle"
          icon={<DownloadOutlined />}
          onClick={() => downloadDataSet(dataset.id)}
        />
      );
      let content = <DetailsElement>{downloadButton}</DetailsElement>;

      return <div key={`actionsFor ${dataset.id}`}>{content}</div>;
    },
  },
  {
    title: '',
    dataIndex: 'projects',
    key: 'actions',
    width: 100,
    render: (projects, dataset) => {
      const projectCount = projects.length;

      const deleteButton = (
        <Button
          shape="circle"
          disabled={projectCount > 0}
          icon={<DeleteOutlined />}
        />
      );
      let content = (
        <Popconfirm
          title="Are you sure you want to delete this data set? This is irreversable."
          onConfirm={() => deleteDataSet(dataset.id)}
          okText="Yes"
          cancelText="No"
          placement="topRight"
        >
          {deleteButton}
        </Popconfirm>
      );
      if (projectCount > 0) {
        content = (
          <Tooltip
            placement="left"
            title="A data set cannot be deleted if it is being used in a project."
          >
            {deleteButton}
          </Tooltip>
        );
      }

      return <div key={`actionsFor ${dataset.id}`}>{content}</div>;
    },
  },
];

export const condensedColumns = (
  selectedExistingDataSets,
  selectRow,
  deselectRow
) => [
  {
    title: 'Filename',
    dataIndex: 'filename',
  },
  {
    title: '',
    dataIndex: 'projects',
    key: 'projects',
    width: '40%',
    render: (projects, dataset) => {
      let usedInProjects = renderUsedInProjects(projects);
      if (projects.length === 0) usedInProjects = <i>Not used yet</i>;
      const content = (
        <div>
          <DetailsElement>
            <DetailsElementTitle isFirst>Uploaded At</DetailsElementTitle>
            <div>{dataset.created_time}</div>
          </DetailsElement>
          <DetailsElement>
            <DetailsElementTitle>Used In Projects</DetailsElementTitle>
            <div>{usedInProjects}</div>
          </DetailsElement>
        </div>
      );

      let actionButton = (
        <ActionButton
          onClick={() => selectRow(dataset)}
          icon={<PlusOutlined />}
        >
          Select
        </ActionButton>
      );
      if (selectedExistingDataSets.includes(dataset))
        actionButton = (
          <ActionButton
            type="primary"
            onClick={() => deselectRow(dataset)}
            icon={<CheckOutlined />}
          >
            Selected
          </ActionButton>
        );
      return (
        <ActionButtonCell key={dataset.id}>
          {actionButton}
          <Popover content={content} title={null} placement="right">
            <ActionButton shape="circle" icon={<InfoOutlined />} />
          </Popover>
        </ActionButtonCell>
      );
    },
  },
];
