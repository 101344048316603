import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { themeColors } from '../colors/themeColors';

const Loading = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${themeColors.primary.base};
  font-size: 30px;
  height: 100vh;
`;

export default ({ tip, style }) => (
  <Loading style={style}>
    <Spin tip={tip} />
  </Loading>
);
