import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Upload, Spin } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import * as Actions from '../redux/actions';
import useAuthHeaders from '../redux/authHeadersHook';
import SyncedDataTables from '../project-components/SyncedDataTables';
import ILDataRowDrawer from '../data-set-components/ILDataRowDrawer';
import {
  LATEST_PREDICTION_DATA_KEY,
  useStateWithLocalStorage,
  LATEST_PREDICTION_RESULTS_KEY,
} from '../data-set-components/ILLocalStorage';
import { exportAsCSVFile } from '../pipeline-components/utilsCSV';

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60vw;
  height: 100%;
`;

const DownloadButton = styled(Button)`
  margin-right: 15px;
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ResultsDataTable = styled.div`
  margin-top: 10px;
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

export default ({ requiredColumns, modelId, projectId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [missingColumns, setMissingColumns] = useState();
  const [predictionData, setPredictionData] = useStateWithLocalStorage(
    `${LATEST_PREDICTION_DATA_KEY}.${projectId}`
  );
  const [predictedColumn, setPredictedColumn] = useStateWithLocalStorage(
    `${LATEST_PREDICTION_RESULTS_KEY}.${projectId}`
  );
  const [selectedRowIndex, setSelectedRowIndex] = useState();
  const authHeaders = useAuthHeaders();

  const applyModel = (dataFile) => {
    if (!authHeaders) return;

    const data = new FormData();
    data.append('file', dataFile);

    fetch(`${Actions.API_PREFIX}/apply/${modelId}`, {
      method: 'POST',
      body: data,
      headers: {
        ...authHeaders,
      },
    })
      .then(Actions.checkResponseSuccess)
      .then(({ input, output }) => {
        setPredictionData(input);
        setPredictedColumn(output);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(`Error applying model`, error);
        // notification.error({
        //   message: "Couldn't fetch data",
        //   description:
        //     "Please check your internet connection or contact support."
        // });
      });
  };

  const uploadProps = {
    accept: '.csv',
    name: 'file',
    action: '/data/upload',
    disabled: false,
    showUploadList: false,
    beforeUpload: () => false,
    onChange: (info) => {
      let reader = new FileReader();
      setIsLoading(true);
      reader.onload = (e) => {
        let columns = e.target.result
          .replace(/\r\n/g, '\n')
          .split('\n', 1)[0]
          .split(',');
        var index = columns.indexOf('');

        if (index !== -1) {
          columns[index] = 'Unnamed: 0';
        }
        let requiredColumnNames = requiredColumns.map((x) => x['name']);
        let missingColumns = requiredColumnNames.filter(function (x) {
          return !columns.includes(x);
        });
        if (missingColumns.length > 0) {
          setIsLoading(false);
          setMissingColumns(missingColumns.toString());
        } else {
          setMissingColumns(null);
          applyModel(info.fileList[0].originFileObj);
        }
      };
      reader.readAsText(info.fileList[0].originFileObj);
    },
  };

  if (isLoading)
    return (
      <Centered>
        <Spin />
      </Centered>
    );

  const prepDataAndExportCSV = () => {
    const columnsMerged = [
      ...predictedColumn.columns,
      ...predictionData.columns,
    ];
    const dataMerged = predictionData.values.map((row, index) => [
      ...predictedColumn.values[index],
      ...row,
    ]);

    exportAsCSVFile(
      columnsMerged.map((c) => c['name']),
      dataMerged,
      'predictions'
    );
  };

  return (
    <ViewWrapper>
      <div>
        <ActionRow>
          <Upload {...uploadProps}>
            <Button
              type="primary"
              className="btn-purple"
              icon={<UploadOutlined />}
            >
              Predict With CSV
            </Button>
          </Upload>
          {predictionData && (
            <DownloadButton
              type="primary"
              className="btn-purple"
              icon={<DownloadOutlined />}
              onClick={prepDataAndExportCSV}
            >
              Download Predictions
            </DownloadButton>
          )}
        </ActionRow>
        {missingColumns && (
          <div style={{ color: 'red' }}>
            You are missing columns: {missingColumns}
          </div>
        )}
      </div>
      {predictionData && (
        <ResultsDataTable>
          <SyncedDataTables
            predictionData={predictionData}
            predictionResults={predictedColumn}
            selectedRowState={[selectedRowIndex, setSelectedRowIndex]}
          />
        </ResultsDataTable>
      )}
      <ILDataRowDrawer
        isVisible={selectedRowIndex !== undefined}
        onClose={() => setSelectedRowIndex(undefined)}
        columns={get(predictionData, 'columns')}
        values={get(predictionData, ['values', selectedRowIndex])}
        rowIndex={selectedRowIndex}
        outcomeColumns={get(predictedColumn, 'columns')}
        outcomeValue={get(predictedColumn, ['values', selectedRowIndex])}
        projectId={projectId}
        modelId={modelId}
      />
    </ViewWrapper>
  );
};
