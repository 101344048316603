import React from 'react';
import { Table, Popconfirm } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { difference } from 'lodash';
import { findSubsetOfChangedValues } from './SimulatorUtils';
import styled from 'styled-components';

const TableWrapper = styled.div`
  max-width: 100%;
  margin-top: 30px;
  margin-bottom: 100px;
`;

const ColumnTitle = styled.div`
  font-weight: 300;
`;

const SenarioColumnTitle = styled(ColumnTitle)`
  width: 150px;
`;

const DeleteIcon = styled(CloseCircleFilled)`
  text-align: center;
  width: 100%;
`;

export default ({
  colors,
  scenarios,
  columnMetadata,
  columnsSortedByPredictiveness,
  deleteScenarioViaName,
}) => {
  const defaults = {};
  const columns = columnsSortedByPredictiveness;
  columns.forEach(
    (column) => (defaults[column] = columnMetadata[column].default_value)
  );
  const [changesforEachScenario, changedColumns] = findSubsetOfChangedValues(
    defaults,
    scenarios
  );

  const unchangedColumns = difference(columns, changedColumns);
  const getColumnConfig = (columnName) => ({
    title: columnName,
    dataIndex: ['column_values', columnName],
    render: (value, record, index) => {
      const hasChanged = changesforEachScenario[record.name].includes(
        columnName
      );
      const style = {
        color: hasChanged ? '#000' : '#ddd',
      };
      return <div style={style}>{value}</div>;
    },
  });

  const colorForIndex = (index) => colors[index % colors.length];
  const columnsForTable = [
    {
      title: <SenarioColumnTitle>Simulated Scenarios</SenarioColumnTitle>,
      dataIndex: 'name',
      fixed: 'left',
      render: (text, _, index) => {
        return (
          <b
            style={{
              color: colorForIndex(index),
            }}
            title={text}
          >
            {text}
          </b>
        );
      },
    },
    ...changedColumns.map(getColumnConfig),
    ...unchangedColumns.map(getColumnConfig),
  ];

  if (scenarios.length > 1) {
    columnsForTable.push({
      title: <ColumnTitle>Delete</ColumnTitle>,
      dataIndex: 'name',
      fixed: 'right',
      render: (scenarioName, _, index) => {
        return (
          <Popconfirm
            title="Are you sure delete this scenario?"
            onConfirm={() => deleteScenarioViaName(scenarioName)}
            okText="Yes"
            cancelText="No"
            placement="topRight"
          >
            <DeleteIcon style={{ color: colorForIndex(index) }} />
          </Popconfirm>
        );
      },
    });
  }

  return (
    <TableWrapper>
      <Table
        columns={columnsForTable}
        dataSource={scenarios}
        bordered
        rowKey={(record) => record.name}
        size="small"
        scroll={{ x: true }}
        pagination={false}
      />
    </TableWrapper>
  );
};
