import React, { Component } from 'react';
import { Modal } from 'antd';

export default class DeleteProjectModal extends Component {
  deleteButtonClicked = () => {
    this.props.removeProject(this.props.project);
    this.props.closeModal();
  };

  render() {
    const projectName =
      this.props.project !== null ? this.props.project.name : '';
    return (
      <Modal
        title="Delete Project"
        visible={this.props.showModal}
        onOk={this.deleteButtonClicked}
        onCancel={this.props.closeModal}
        okText="Delete"
      >
        Are you sure you want to delete `{projectName}`? This is irreversible.
      </Modal>
    );
  }
}
