import React, { useState } from 'react';
import IntersectLogo from '../images/Logo.svg';
import styled from 'styled-components';
import { last } from 'lodash';
import { Upload, Button } from 'antd';
import { checkResponseSuccess, API_PREFIX } from '../redux/actions';
import { exportCSVToBrowserDownloads } from '../pipeline-components/utilsCSV';
const Dragger = Upload.Dragger;

const DraggerContent = styled(Dragger)`
  color: #1890ff !important;
  font-size: 16px;
  font-weight: 300;
  background: #00346b !important;
  border-radius: 6px;
  box-shadow: inset 0px 1px 8px rgba(0, 0, 0, 0.25);
  margin-top: 25px !important;
  padding: 40px 50px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: #1890ff !important;

  :hover,
  :active {
    border-color: #ffffff !important;
  }

  span {
    color: #1890ff !important;
  }
`;

const DraggerContentTitle = styled.div`
  font-weight: 600;
  margin-top: 45px;
`;

const DraggerContentSubtitle = styled.div`
  max-width: 360px;
  display: inline-block;
`;

const UploadButton = styled(Button)`
  margin-top: 25px;
`;

const UploaderContainer = styled.div`
  background-color: #00346a;
  border-radius: 12px;
  padding: 24px;
`;

const Uploader = ({ setResponse }) => {
  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState();

  const draggerProps = {
    name: 'file',
    action: '/data/upload',
    fileList,
    showUploadList: true,
    multiple: true,
    beforeUpload: () => false,
    onChange: (info) => {
      const latestFile = last(info.fileList);
      console.log('files', info.fileList);

      if (latestFile === undefined) {
        setFileList([]);
      } else {
        setFileList(info.fileList);
      }
    },
  };

  const uploadFiles = async () => {
    if (!fileList || fileList.length === 0) return;

    setIsUploading(true);
    setResponse(null);

    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files', file.originFileObj);
    });

    fetch(`${API_PREFIX}/ford/sandbox/tee91`, {
      method: 'POST',
      body: formData,
    })
      .then(checkResponseSuccess)
      .then((res) => {
        setIsUploading(false);
        setFileList([]);
        setResponse({
          data: res,
          fileNames: fileList.map(({ name }) => name),
        });
      })
      .catch((error) => {
        setIsUploading(false);
        setError(error);
        console.error(`Error uploading demo call data`, error);
      });
  };

  return (
    <UploaderContainer className="demo-call-uploader">
      <UploadButton
        loading={isUploading}
        type="primary"
        size="large"
        onClick={uploadFiles}
        disabled={fileList.length === 0}
      >
        {isUploading ? 'Uploading...' : 'Process Data Files'}
      </UploadButton>

      <DraggerContent {...draggerProps}>
        <DraggerContentTitle>
          Click to select or drag and drop files here
        </DraggerContentTitle>
        <DraggerContentSubtitle>
          For extra security, we use 64-bit TLS security protocols to upload
          your data.
        </DraggerContentSubtitle>
      </DraggerContent>

      {error && (
        <div>
          Whoops, looks like there was an error. Try refreshing the page and
          double checking your uploads!
        </div>
      )}
    </UploaderContainer>
  );
};

const Logo = styled.img`
  margin-top: 50px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin: 64px auto 30px;
`;

const toCSV = ({ data, fileNames }) => {
  return data.reduce(
    (csvStr, num, i) => `${csvStr}\n${num},${fileNames[i].replace(',', '')}`,
    'Results,File Name'
  );
};

export default () => {
  const [response, setResponse] = useState(null);
  return (
    <StyledDiv>
      <Logo src={IntersectLogo} title="Intersect Labs" />
      <Title>
        Drag and and drop or click to select the Excel files to process
      </Title>
      {response && (
        <div style={{ margin: 24 }}>
          <Title style={{ color: '#1b90ff' }}>
            Success! Download a CSV containing the results for each file:
          </Title>
          <Button onClick={() => exportCSVToBrowserDownloads(toCSV(response))}>
            Download CSV
          </Button>
        </div>
      )}
      <Uploader setResponse={setResponse} />
    </StyledDiv>
  );
};
