import React, { useState } from 'react';
import styled from 'styled-components';
import PipelineViewHeader from './PipelineViewHeader';
import {
  getDataSetStep,
  NodeConnector,
  getPipelineStep,
  ApplyPipelineStep,
} from './PipelineViewComponents';
import AddTransformModal from './transform-components/AddTransformModal';
import PurpleButton from './PurpleButton';
import { get, find, forOwn, isString } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';

const ViewWrapper = styled.div`
  height: 100vh;
  width: 100%;
  border-right: 1px solid #eaeff2;
  display: flex;
  flex-direction: column;
`;

const Steps = styled.div`
  padding-top: 20px;
  padding-bottom: 40px;
  overflow: auto;

  ::after {
    content: ' ';
    display: block;
    padding-bottom: 80px;
    height: 0px;
  }
`;

export const NON_TRANSFORM_STEPS = {
  after: 'after',
  before: 'before',
  applyPipeline: 'applyPipeline',
};

const compileRelevantColumns = (options) => {
  let columns = [];
  forOwn(options, (value, key) => {
    if (key.includes('column') && isString(value)) {
      columns = [...columns, value];
    } else if (
      key.includes('column') &&
      Array.isArray(value) &&
      find(value, (el) => !isString(el)) === undefined
    ) {
      columns = [...columns, ...value];
    } else if (typeof value === 'object' && value !== null) {
      columns = [...columns, ...compileRelevantColumns(value)];
    }
  });

  return columns;
};

export default ({
  steps = null,
  projectId,
  dataSetName,
  selectedStep,
  setSelectedStep,
  transformMap,
  pipelineError = {},
  addDraftTransform,
  allTransformsList,
  aPipelineExists,
  pipelineInUse = false,
}) => {
  const [addModalVisible, setAddModalVisible] = useState({
    visible: false,
    atIndex: -1,
  });

  const renderedSteps = [];
  let containsDraftTransform = false;
  if (steps !== null) {
    containsDraftTransform =
      find(steps, (s) => s.isDraft === true) !== undefined;
    steps.forEach(
      (
        { module: transformModule, transform_name, options = {}, isDraft },
        index
      ) => {
        const relevantColumns = compileRelevantColumns(options);
        const transform = get(transformMap, [
          transformModule + transform_name,
          'public_name',
        ]);
        const stepHasError = pipelineError.error_transform === index;
        if (transform === undefined) return;
        renderedSteps.push(
          NodeConnector({
            key: index,
            onAddClick: pipelineInUse
              ? false
              : () => setAddModalVisible({ visible: true, atIndex: index }),
            disabled: containsDraftTransform,
          })
        );
        renderedSteps.push(
          getPipelineStep(
            `${index}-step`,
            transform,
            relevantColumns,
            index === selectedStep,
            () => setSelectedStep(index),
            stepHasError,
            isDraft
          )
        );
      }
    );
  }

  const addTransformButton = (
    <PurpleButton
      icon={<PlusOutlined />}
      onClick={() =>
        setAddModalVisible({ visible: true, atIndex: steps.length })
      }
      disabled={containsDraftTransform}
      data-btn-id="add-transform"
    >
      Add Transform
    </PurpleButton>
  );

  return (
    <ViewWrapper>
      <PipelineViewHeader
        projectId={projectId}
        onCurrentProjectStepClick={() =>
          setSelectedStep(NON_TRANSFORM_STEPS.applyPipeline)
        }
      />
      <Steps>
        <ApplyPipelineStep
          selected={selectedStep === NON_TRANSFORM_STEPS.applyPipeline}
          aPipelineExists={aPipelineExists}
          onClick={() => setSelectedStep(NON_TRANSFORM_STEPS.applyPipeline)}
        />
        <NodeConnector />
        {getDataSetStep(
          dataSetName,
          selectedStep === NON_TRANSFORM_STEPS.before,
          () => setSelectedStep(NON_TRANSFORM_STEPS.before)
        )}
        {renderedSteps}
        {steps !== null && (
          <>
            <NodeConnector />
            {!pipelineInUse && addTransformButton}
            {!pipelineInUse && <NodeConnector key={'final'} />}
            {getDataSetStep(
              'transformed',
              selectedStep === NON_TRANSFORM_STEPS.after,
              () => setSelectedStep(NON_TRANSFORM_STEPS.after)
            )}
          </>
        )}
        <AddTransformModal
          onAddTransformClick={addDraftTransform.bind(
            this,
            addModalVisible.atIndex
          )}
          isOpen={addModalVisible.visible}
          close={() => setAddModalVisible(false)}
          allTransformsList={allTransformsList}
        />
      </Steps>
    </ViewWrapper>
  );
};
