export default (getPipelineContext, pipelineName) => {
  const { network, setters, state } = getPipelineContext();

  setters.setIsLoading(true);
  fetch(`${network.API_PREFIX}/pasteur/pipeline`, {
    method: 'POST',
    body: JSON.stringify({
      project_id: state.projectId,
      pipeline_name: pipelineName,
    }),
    headers: {
      ...network.authHeaders,
      'Content-Type': 'application/json',
    },
  })
    .then(network.checkResponseSuccess)
    .then(({ pipeline, id }) => {
      setters.setPipelineData(pipeline);
      setters.setIsLoading(false);
    })
    .catch((error) => {
      console.log('Create New Pipeline error:', error.message);
      // TODO handle error
      // setError({ message: error.message, newSimulationName, xAxisColumn });
      setters.setIsLoading(false);
    });
};
