import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions';
import Loading from '../project-components/Loading';
import { sortColumnsByMostPredictive } from '../model-report-components/Helpers';
import ModelDetailsView, {
  VIEWS,
} from '../model-report-components/ModelDetailsView';
import PredictView from '../model-report-components/PredictView';
import SimulationsBox from '../model-report-components/SimulationsBox';
import { themeColors } from '../colors/themeColors';
import PredictiveColumnsView from '../model-report-components/PredictiveColumnsView';

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: grid;
  grid-template-columns: 330px [middle-separator] 1fr [end];
  grid-template-rows: 1fr [middle-separator] 450px [end];
`;

const ModelDetailsViewGridWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: span middle-separator;
  grid-row-start: 1;
  grid-row-end: span end;
`;

const MainContentGridWrapper = styled.div`
  grid-column-start: middle-separator;
  grid-column-end: span end;
  grid-row-start: 1;
  grid-row-end: span end;
  background: #fafaff;
  color: ${themeColors.secondary.dark};
  padding: 50px;
  overflow: auto;
`;

const ModelReportPageWrapper = styled.div`
  flex-grow: 1;
  display: block;
  color: #333333;
`;

const ErrorMessage = styled.p`
  margin-top: 30px;
`;

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match.params.projectId;
  return {
    model: state.training,
    user: state.user,
    projectId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const ModelReport = (props) => {
  const [processing, setProcessing] = useState('init');
  const [selection, setSelection] = useState('predictions');
  const [apiModalVisible, setApiModalVisible] = useState(false);
  const [modelData, setModelData] = useState({});
  const projectId = props.projectId;

  useEffect(() => {
    const authHeaders = Actions.getAuthHeaders(props.user);
    let matrix = [];
    fetch(`${Actions.API_PREFIX}/apply/emptyTableInfo/${projectId}`, {
      method: 'GET',
      headers: {
        ...authHeaders,
      },
    })
      .then(Actions.checkResponseSuccess)
      .then((data) => {
        setModelData({
          targetColumn: data.target_column,
          columns: data.columns,
          goal: data.goal,
          data: matrix,
          accuracy: data.accuracy,
          modelId: data.model_id,
          projectName: data.project_name,
          predictiveFeatures: data.predictive_features,
        });
        setProcessing('done');
      })
      .catch((error) => {
        setProcessing('error');
      });
  }, [projectId, props.user]);

  const showAPIModal = () => {
    setApiModalVisible(true);
  };

  const handleCancel = () => {
    setApiModalVisible(false);
  };

  if (processing === 'init') {
    return <Loading />;
  } else if (processing === 'error') {
    return (
      <ErrorMessage>
        There was an error. Please contact us at hello@intersectlabs.io
      </ErrorMessage>
    );
  }

  const accuracyWord =
    modelData.goal === 'Predict' ? 'Error margin' : 'Accuracy';
  const errorMetric =
    modelData.goal === 'Predict'
      ? Math.abs(modelData.accuracy.toFixed(2))
      : Math.abs(modelData.accuracy.toFixed(4)) * 100;

  const names = modelData.columns.map((x) => x['name']);
  const namesList = names.map(function (listValue, index) {
    return <li key={index}>{listValue}</li>;
  });

  const sortedPredictiveColumns = sortColumnsByMostPredictive(
    modelData.predictiveFeatures
  );

  let mainContent;
  if (selection === VIEWS.simulator) {
    mainContent = (
      <SimulationsBox
        projectId={props.projectId}
        modelId={modelData.modelId}
        user={props.user}
        projectName={modelData.projectName}
        predictiveColumns={sortedPredictiveColumns}
      />
    );
  } else if (selection === VIEWS.predictiveColumns) {
    mainContent = (
      <PredictiveColumnsView predictiveColumns={sortedPredictiveColumns} />
    );
  } else {
    mainContent = (
      <PredictView
        namesList={namesList}
        requiredColumns={modelData.columns}
        showAPIModal={showAPIModal}
        apiModalVisible={apiModalVisible}
        handleCancel={handleCancel}
        projectId={props.projectId}
        modelId={modelData.modelId}
        user={props.user}
        projectName={modelData.projectName}
        title={`${modelData.goal} ${modelData.targetColumn}`}
      />
    );
  }

  return (
    <ModelReportPageWrapper>
      <PageContainer>
        <ModelDetailsViewGridWrapper>
          <ModelDetailsView
            projectId={projectId}
            accuracyWord={accuracyWord}
            errorMetric={errorMetric}
            predictiveColumns={sortedPredictiveColumns}
            selection={selection}
            setSelection={setSelection}
          />
        </ModelDetailsViewGridWrapper>
        <MainContentGridWrapper>{mainContent}</MainContentGridWrapper>
      </PageContainer>
    </ModelReportPageWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelReport);
