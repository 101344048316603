import React, { useEffect, useState } from 'react';
import { Modal, Spin, Radio } from 'antd';
import styled from 'styled-components';
import CheckoutForm from './CheckoutForm';
import useAuthHeaders from '../redux/authHeadersHook';
import {
  getPricingRate,
  getPriceId,
  productsContainPricingForPeriod,
  PRICING_FLOWS,
  CHECKOUT_VISIBILITY,
  SUBSCRIPTIONS_PERIODS,
} from './SubscriptionUtils';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import PlanColumn from './PlanColumn';
import useCurrentSubscription from './CurrentSubscriptionHook';
import { TYPES, fetchSubscriptionInfo } from '../redux/authActions';
import {
  beginSubscription,
  changeSubscription,
  getProducts,
} from '../redux/billingActions';

const SelectPlanPanel = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanColumnRow = styled.div`
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  margin: 40px;
`;

const Title = styled.div`
  font-size: 18px;
  color: #000;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
`;

const Subtitle = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 300;
`;

export default ({
  showModal,
  onClose,
  pricingFlow = PRICING_FLOWS.changePlans,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [subscriptionPeriod, setSubscriptionPeriod] = useState('month');
  const [unsortedProducts, setUnsortedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [isCheckoutFormVisible, setIsCheckoutFormVisible] = useState(
    pricingFlow.showCheckoutForm === CHECKOUT_VISIBILITY.show
  );
  const currentSubscription = useCurrentSubscription();
  const dispatch = useDispatch();
  const authHeaders = useAuthHeaders();

  useEffect(() => {
    setIsCheckoutFormVisible(
      pricingFlow.showCheckoutForm === CHECKOUT_VISIBILITY.show
    );
  }, [pricingFlow]);

  useEffect(() => {
    const productsCopy = [...unsortedProducts];
    productsCopy.sort(
      (a, b) =>
        getPricingRate(a, subscriptionPeriod) -
        getPricingRate(b, subscriptionPeriod)
    );
    setProducts(productsCopy);
  }, [unsortedProducts, subscriptionPeriod]);

  useEffect(() => {
    if (unsortedProducts.length > 0) return;

    setIsLoading(true);
    getProducts(authHeaders, (data) => {
      console.log(data);
      setUnsortedProducts(data);
      setIsLoading(false);
    });
    // eslint-disable-next-line
  }, [unsortedProducts.length]);

  const updatePlanSelection = (product, paymentMethod) => {
    const price_id = getPriceId(product, subscriptionPeriod);
    const onUpdateComplete = () => {
      dispatch({
        type: TYPES.RECEIVE_SUBSCRIPTION_INFO,
        subscription: {
          product_name: product.name,
          amount_due: getPricingRate(product, subscriptionPeriod),
        },
      });
      dispatch(fetchSubscriptionInfo());
      cleanUpAndClose();
    };

    if (get(currentSubscription, 'id') === null) {
      beginSubscription(
        price_id,
        paymentMethod,
        setIsLoading,
        authHeaders,
        dispatch,
        onUpdateComplete
      );
    } else {
      changeSubscription(
        price_id,
        setIsLoading,
        authHeaders,
        dispatch,
        onUpdateComplete
      );
    }
  };

  // Should do start subscription or update with CC as a parameter in instead of calling update credit card
  const shouldGetCCWhileSelectingPlan = (product = {}) => {
    const { id, has_cc_on_file, feature_flags } = currentSubscription;
    const noCCFiled = id === null || !has_cc_on_file;
    return (get(feature_flags, 'galileo') || product.no_trial) && noCCFiled;
  };

  const onProductSelect = (product) => {
    setSelectedProduct(product);
    const { status, has_cc_on_file } = currentSubscription;

    let continueToCheckout = false;
    if (shouldGetCCWhileSelectingPlan(product)) {
      continueToCheckout = true;
    } else if (pricingFlow.showCheckoutForm === CHECKOUT_VISIBILITY.show) {
      continueToCheckout = true;
    } else if (
      pricingFlow.showCheckoutForm ===
      CHECKOUT_VISIBILITY.showIfNotTrialAndNoPaymentMethod
    ) {
      if (status !== 'trialing' && has_cc_on_file === false) {
        continueToCheckout = true;
      }
    }

    setIsCheckoutFormVisible(continueToCheckout);
    if (!continueToCheckout) {
      updatePlanSelection(product);
    }
  };

  const onSuccessfulPaymentMethodUpdate = (paymentMethod) => {
    if (selectedProduct) {
      updatePlanSelection(selectedProduct, paymentMethod);
    } else {
      cleanUpAndClose();
    }

    return Promise.resolve();
  };

  const indexOfMiddleElement = Math.floor(products.length / 2);
  const plansRendered = products.map((product, index) => (
    <PlanColumn
      key={product.id}
      product={product}
      isEmphasized={indexOfMiddleElement === index}
      onSelect={onProductSelect}
      currentSubscription={currentSubscription}
      subscriptionPeriod={subscriptionPeriod}
      actionButtonText={pricingFlow.actionButtonText}
    />
  ));

  const cleanUpAndClose = () => {
    if (!onClose) return;

    onClose();
    setSelectedProduct(undefined);
  };

  const showMonthlyPricing = productsContainPricingForPeriod(
    products,
    SUBSCRIPTIONS_PERIODS.month
  );
  const showYearlyPricing = productsContainPricingForPeriod(
    products,
    SUBSCRIPTIONS_PERIODS.year
  );

  let checkoutFormCallbackProps = {
    onSuccessfulPaymentMethodUpdate: onSuccessfulPaymentMethodUpdate,
  };
  if (shouldGetCCWhileSelectingPlan(selectedProduct)) {
    checkoutFormCallbackProps = {
      onlyCapturePaymentMethodWithCallback: onSuccessfulPaymentMethodUpdate,
    };
  }

  return (
    <Modal
      visible={showModal}
      header={null}
      footer={null}
      width="70vw"
      onCancel={cleanUpAndClose}
      closable={onClose !== null}
    >
      <Spin spinning={isLoading} tip="Updating subscription...">
        {isCheckoutFormVisible ? (
          <CheckoutForm
            showPlans={() => setIsCheckoutFormVisible(false)}
            product={{ ...selectedProduct, subscriptionPeriod }}
            {...checkoutFormCallbackProps}
          />
        ) : (
          <SelectPlanPanel>
            <Title>{pricingFlow.title}</Title>
            <Subtitle>{pricingFlow.subtitle}</Subtitle>
            <Radio.Group
              onChange={(e) => setSubscriptionPeriod(e.target.value)}
              defaultValue={subscriptionPeriod}
              style={{ margin: 'auto' }}
            >
              {showMonthlyPricing && (
                <Radio.Button value={SUBSCRIPTIONS_PERIODS.month}>
                  Monthly
                </Radio.Button>
              )}
              {showYearlyPricing && (
                <Radio.Button value={SUBSCRIPTIONS_PERIODS.year}>
                  Annual
                </Radio.Button>
              )}
            </Radio.Group>
            <PlanColumnRow>{plansRendered}</PlanColumnRow>
          </SelectPlanPanel>
        )}
      </Spin>
    </Modal>
  );
};
