import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import IntersectLogo from '../images/Logo.svg';

import CheckoutForm from '../checkout-components/CheckoutForm';
import { useAPI } from '../api/useAPI';

const Logo = styled.img`
  margin-top: 50px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 300;
  margin: 64px auto 30px;
`;

const ErrorView = ({ children }) => (
  <StyledDiv>
    <Title>{children}</Title>
  </StyledDiv>
);

const UnauthedPaymentUpdate = () => {
  const { encryptedUserId } = useParams();
  const [email = 'Loading...', { error }] = useAPI.makeCall({
    endpoint: `/user/decrypt-user-id/${encryptedUserId}`,
    // errorNotification: true, // or any custom string
  });

  const [paymentUpdateComplete, setPaymentUpdateComplete] = useState(false);
  const [updateError, setUpdateError] = useState();
  const updateBilling = useAPI.getCallbackFn({
    endpoint: `/billing/card/unauth-update/${encryptedUserId}`,
    method: 'POST',
  });

  const handlePaymentMethodCapture = async (payment_method) => {
    const [, error] = await updateBilling({ body: { payment_method } });
    if (error) {
      setUpdateError(error);
    } else {
      setPaymentUpdateComplete(true);
    }
  };

  const anError = error || updateError;
  if (anError) {
    return <ErrorView>{anError.message}</ErrorView>;
  }

  return (
    <StyledDiv>
      <Logo src={IntersectLogo} title="Intersect Labs" />
      {paymentUpdateComplete ? (
        <Title>We've updated your credit card, you're all set!</Title>
      ) : (
        <>
          <Title>Update your credit card</Title>
          <CheckoutForm
            onlyCapturePaymentMethodWithCallback={handlePaymentMethodCapture}
            predefinedEmail={email}
          />
        </>
      )}
    </StyledDiv>
  );
};

export default UnauthedPaymentUpdate;
