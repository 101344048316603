export const themeColors = {
  primary: {
    activeText: '#40a9ff',
    base: '#007bff',
    darkText: '#24313b',
  },
  secondary: {
    selected: {
      sunsetPink: '#e0bbff',
      dimText: '#BF76FF',
      base: '#8D47CB',
      darker: '#6d26ab',
    },
    dimText: '#867DD2',
    base: '#7566e8', // 2 css, 1 js
    hover: '#554bb1',
    dark: '#443d82',
    gridBackground: '#352f69', // 3 uses
    darker: '#2c2471',
    darkest: '#171242', // css only
  },
};
