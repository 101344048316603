import React, { useState } from 'react';
import styled from 'styled-components';
import { Radio, Button } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import ApplyPipelineViaWeb from './ApplyPipelineViaWeb';
import { useHistory } from 'react-router-dom';
import MLAccessWrapper from '../project-components/MLAccessWrapper';
import { PROJECT_STEPS } from '../project-components/NewProjectModal';
import { themeColors } from '../colors/themeColors';

const PageWrapper = styled.div`
  background: #fafaff;
  color: ${themeColors.secondary.dark};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 50px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

const Subtitle = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const ActionsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
`;

const VerticalSeparator = styled.div`
  width: 2px;
  background-color: #eaeff2;
  margin-left: 30px;
  margin-right: 30px;
  align-self: stretch;
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  margin-top: 40px;
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #eaeff2;
  border-radius: 5px;
  flex-grow: 1;
  width: 63vw;
`;

const CodeBlock = styled.code`
  border: 1px solid #eaeff2;
  padding: 20px;
  background: #fbfbfb;
  font-size: 13px;
`;

const applyOptions = {
  api: 'api',
  web: 'web',
};

const applyAPIPanel = (projectId = 'projectId') => (
  <Panel>
    <Title>Apply via API</Title>
    <Subtitle>
      Use the following command to apply this pipeline via API.
    </Subtitle>
    <CodeBlock>
      curl -X POST "https://api.intersectlabs.io/pipeline/" \
      <br />
      {'-F "file=@/absolute/path/to/data_file.csv" \\'}
      <br />
      {`-F "project=${projectId}" \\`}
      <br />
      {'-H "Authorization: Bearer  <token_generated_in_settings>"'}
    </CodeBlock>
  </Panel>
);

export default ({ projectId, requiredColumns }) => {
  const [selectedOption, setSelectedOption] = useState(applyOptions.api);
  const history = useHistory();

  const MLAccessButton = MLAccessWrapper(Button);

  return (
    <PageWrapper>
      <Title>Apply Pipeline</Title>
      <Subtitle>
        In addition to using the pipeline with your other project steps like a
        machine learning step, you can use this pipeline by itself.
      </Subtitle>
      <ActionsWrapper>
        <Radio.Group
          defaultValue={selectedOption}
          buttonStyle="solid"
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <Radio.Button value={applyOptions.api}>Apply via API</Radio.Button>
          <Radio.Button value={applyOptions.web}>Apply via Web</Radio.Button>
        </Radio.Group>
        <VerticalSeparator />
        <MLAccessButton
          type="primary"
          icon={<LoginOutlined />}
          onClick={() => {
            history.push(`/projects/${projectId}/${PROJECT_STEPS.ml.url}`);
          }}
        >
          Use in Machine Learning
        </MLAccessButton>
      </ActionsWrapper>
      {selectedOption === applyOptions.api ? (
        applyAPIPanel(projectId)
      ) : (
        <Panel>
          <Title>Apply via Web</Title>
          <Subtitle>
            Upload a CSV file with the same columns as the data you created this
            pipeline with and see the transformed output.
          </Subtitle>
          <ApplyPipelineViaWeb
            projectId={projectId}
            requiredColumns={requiredColumns}
          />
        </Panel>
      )}
    </PageWrapper>
  );
};
