import React, { useState, useEffect } from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { last } from 'lodash';

const Dragger = Upload.Dragger;

const Container = styled.div`
  width: 100%;
  padding: 40px;
`;

const CSVUpload = ({ setSelectedNewDataSets }) => {
  const [fileList, setFileList] = useState([]);
  const [hasDuplicateColums, setHasDuplicateColums] = useState(false);

  useEffect(() => {
    const file = fileList[0];
    if (file && !hasDuplicateColums) {
      setSelectedNewDataSets([
        { file: file.originFileObj, columns: file.columns },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const checkIfDuplicateExists = (stringArray) => {
    return new Set(stringArray).size !== stringArray.length;
  };

  const draggerProps = {
    accept: '.csv',
    name: 'file',
    action: '/data/upload',
    fileList,
    showUploadList: true,
    beforeUpload: () => false,
    onChange: (info) => {
      let columns = [];
      let reader = new FileReader();
      const latestFile = last(info.fileList);
      reader.onload = (e) => {
        columns = e.target.result
          .replace(/\r\n/g, '\n')
          .split('\n', 1)[0]
          .split(',');

        var index = columns.indexOf('');
        if (index !== -1) {
          columns[index] = 'Unnamed: 0';
        }

        setFileList([{ ...latestFile, columns }]);
        setHasDuplicateColums(checkIfDuplicateExists(columns));
      };

      if (latestFile !== undefined) {
        const originFileObj = latestFile.originFileObj;
        reader.readAsText(originFileObj);
      } else {
        setHasDuplicateColums(false);
        setFileList([]);
      }
    },
  };

  return (
    <Container>
      <Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Drag & Drop or Click to Upload Data</p>
        <p className="ant-upload-hint">Upload a CSV file</p>
      </Dragger>
    </Container>
  );
};

export default CSVUpload;
