import { useSelector } from 'react-redux';
import { get, isEqual } from 'lodash';

const useAuthHeaders = () => {
  const accessToken = useSelector(
    (state) => get(state, 'user.accessToken'),
    isEqual
  );

  if (accessToken === undefined) {
    return undefined;
  }

  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export default useAuthHeaders;
