export default (
  getPipelineContext,
  fetchPipeline,
  pipelineId,
  pipelineName
) => {
  const { network, setters, state } = getPipelineContext();

  setters.setIsLoading(true);
  fetch(`${network.API_PREFIX}/pasteur/pipeline/copy`, {
    method: 'POST',
    body: JSON.stringify({
      project_id: state.projectId,
      pipeline_name: pipelineName,
      pipeline_id: pipelineId,
    }),
    headers: {
      ...network.authHeaders,
      'Content-Type': 'application/json',
    },
  })
    .then(network.checkResponseSuccess)
    .then((data) => {
      console.log('clone', data);
      fetchPipeline(getPipelineContext);
    })
    .catch((error) => {
      console.log('Create New Pipeline error:', error.message);
      // TODO handle error
      // setError({ message: error.message, newSimulationName, xAxisColumn });
      setters.setIsLoading(false);
    });
};
