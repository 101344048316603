import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Input, Space, Button, Spin } from 'antd';
import styled from 'styled-components';
import NewSubscriptionSummary from './NewSubscriptionSummary';
import CurrentSubscriptionSummary from './CurrentSubscriptionSummary';
import { updateCard } from '../redux/billingActions';
import useAuthHeaders from '../redux/authHeadersHook';
import { useDispatch } from 'react-redux';
import { systemColors } from '../colors/systemColors';

const CheckoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 40px;
  background: ${systemColors.background.fill};
  border: 1px solid #e9f4ff;
`;

const SpacedContainer = styled(Space)`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 1px solid #eeeeee;
  padding: 20px;
  min-width: 360px;
  background: #ffffff;
`;

const SectionTitle = styled.div`
  color: #000;
  font-weight: 600;
`;

const Title = styled.div`
  text-align: center;
  color: #000;
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Error = styled.div`
  color: ${systemColors.error.hot};
`;

const getFullCheckoutInputs = (
  fullName,
  setFullName,
  organization,
  setOrganization
) => [
  <Input
    key="Name"
    placeholder="Full name..."
    value={fullName}
    onChange={(e) => {
      setFullName(e.target.value);
    }}
  />,
  <Input
    key="Org"
    placeholder="Organization..."
    value={organization}
    onChange={(e) => {
      setOrganization(e.target.value);
    }}
  />,
];

const PresetEmail = ({ email }) => <Input value={email} disabled />;

const CheckoutPaymentForm = ({
  onSuccessfulPaymentMethodUpdate,
  setIsLoading,
  predefinedEmail,
  onlyCapturePaymentMethodWithCallback,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [fullName, setFullName] = useState('');
  const [organization, setOrganization] = useState('');
  const [error, setError] = useState();
  const authHeaders = useAuthHeaders();
  const dispatch = useDispatch();

  const invalidInput =
    !predefinedEmail && (fullName === '' || organization === '');

  const handleSubmit = async () => {
    setIsLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setIsLoading(false);
      setError(error.message);
    } else if (onlyCapturePaymentMethodWithCallback) {
      onlyCapturePaymentMethodWithCallback(paymentMethod.id).finally(() =>
        setIsLoading(false)
      );
    } else {
      updateCard(
        paymentMethod.id,
        setIsLoading,
        authHeaders,
        dispatch,
        (error) => {
          if (error) {
            setError(error.message);
          } else {
            onSuccessfulPaymentMethodUpdate();
          }
        }
      );
    }
  };

  return (
    <SpacedContainer direction="vertical" size="middle">
      <SectionTitle>Credit Card Details</SectionTitle>
      {predefinedEmail ? (
        <PresetEmail email={predefinedEmail} />
      ) : (
        getFullCheckoutInputs(
          fullName,
          setFullName,
          organization,
          setOrganization
        )
      )}
      <CardElement
        className="ant-input stripe-card-input"
        onChange={() => setError(undefined)}
        options={{
          style: {
            base: {
              fontFamily: 'Lato',
              '::placeholder': {
                color: '#bfbfbf',
              },
            },
          },
        }}
      />
      {error && <Error>{error}</Error>}
      <Button
        onClick={handleSubmit}
        type="primary"
        className="btn-bright-purple"
        disabled={!stripe || invalidInput}
      >
        Submit
      </Button>
    </SpacedContainer>
  );
};

let stripePromise;
if (
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_ENVIRONMENT === 'staging'
) {
  stripePromise = loadStripe('pk_test_aDLApTYedojjAi9zpiXYuIvm');
} else {
  stripePromise = loadStripe('pk_live_ZcwXJXqEODvvw8cwEpoZnE28');
}

const SubscriptionSummary = ({ product, showPlans }) => {
  return product.id ? (
    <NewSubscriptionSummary
      onChangePlanClick={showPlans}
      subscribingToProduct={product}
      borderTop
    />
  ) : (
    <CurrentSubscriptionSummary
      hideFreeTrialBanner
      onChangePlanClick={showPlans}
    />
  );
};

export default ({
  product,
  showPlans,
  onSuccessfulPaymentMethodUpdate,
  predefinedEmail = '',
  onlyCapturePaymentMethodWithCallback,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Elements stripe={stripePromise}>
      {!predefinedEmail && <Title>Checkout</Title>}
      <Spin spinning={isLoading} tip="Updating payment method...">
        <CheckoutWrapper>
          {!predefinedEmail && (
            <SubscriptionSummary product={product} showPlans={showPlans} />
          )}
          <CheckoutPaymentForm
            onSuccessfulPaymentMethodUpdate={onSuccessfulPaymentMethodUpdate}
            setIsLoading={setIsLoading}
            predefinedEmail={predefinedEmail}
            onlyCapturePaymentMethodWithCallback={
              onlyCapturePaymentMethodWithCallback
            }
          />
        </CheckoutWrapper>
      </Spin>
    </Elements>
  );
};
