import React from 'react';
import useCurrentSubscription from '../checkout-components/CurrentSubscriptionHook';
import { get } from 'lodash';
import { Popover } from 'antd';

export default (WrappedComponent) => {
  return (props) => {
    const currentSubscription = useCurrentSubscription();
    const hasAccessToGalileo =
      get(currentSubscription, 'feature_flags.galileo') !== false;

    const noAccessPopoverContent = (
      <div>
        Please{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://calendly.com/intersect-labs/intersect-labs-machine-learning"
        >
          get a call with us
        </a>{' '}
        to get started
      </div>
    );

    const mainContent = (
      <WrappedComponent {...props} disabled={!hasAccessToGalileo} />
    );

    if (hasAccessToGalileo) return mainContent;

    return (
      <Popover
        content={noAccessPopoverContent}
        title={<b>Your plan doesn't include Machine Learning</b>}
      >
        {mainContent}
      </Popover>
    );
  };
};
