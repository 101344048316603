import { notification } from 'antd';

export default (getPipelineContext, position) => {
  const { network, state, setters } = getPipelineContext();

  if (state.pipelineData[position].isDraft) {
    setters.setPipelineData((p) => {
      const dataCopy = [...p];
      dataCopy.splice(position, 1);
      return dataCopy;
    });
    return;
  }

  setters.setIsLoading(true);
  fetch(`${network.API_PREFIX}/pasteur/pipeline/transform/delete`, {
    method: 'POST',
    body: JSON.stringify({
      project_id: state.projectId,
      position,
      // subset_start: data_set_subset_start?,
      // subset_end: data_set_subset_end?,
    }),
    headers: {
      ...network.authHeaders,
      'Content-Type': 'application/json',
    },
  })
    .then(network.checkResponseSuccess)
    .then(({ pipeline, final, error, error_transform, message }) => {
      setters.setPipelineData(pipeline);
      if (error) {
        setters.setPipelineError({ error, error_transform, message });
      } else {
        setters.setPipelineError(undefined);
      }
      setters.setAfterData(final);
      setters.setIsLoading(false);
      console.log('transform deleted', pipeline, final);
    })
    .catch((error) => {
      setters.setIsLoading(false);
      console.error(`Error deleting transform`, error);
      notification.error({
        message: "Couldn't delete transform",
        description:
          'Please check your internet connection or contact support at hello@intersectlabs.io',
      });
    });
};
