import React from 'react';
import styled from 'styled-components';
import PipelineViewHeader from '../pipeline-components/PipelineViewHeader';
import PredictiveColumnsView from './PredictiveColumnsView';
import { RightOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { themeColors } from '../colors/themeColors';
import { systemColors } from '../colors/systemColors';

const ViewWrapper = styled.div`
  height: 100vh;
  width: 100%;
  /* border-right: 1px solid #EAEFF2; */
  display: flex;
  flex-direction: column;
  background: #f4f3fb;
  overflow: auto;
`;

const BaseItem = styled.div`
  margin: 25px 25px 0px;
  color: ${themeColors.secondary.darker};
  font-weight: 600;
  text-align: left;
`;

const DetailPanel = styled(BaseItem)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  /* border: 1px solid #eaeff2; */
  padding: 15px;
  border-radius: 5px;
`;

const RowDetailPanel = styled(DetailPanel)`
  display: flex;
  flex-direction: row;
  padding: 8px 15px;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
`;

const BorderDetailed = styled.span`
  padding: 3px 15px;
  background: #ffffff;
  border: 1px solid #eaeff2;
  border-radius: 24px;
`;

const DetailDescription = styled.div`
  font-weight: 300;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const DetailButtonPanel = styled(BaseItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(p) => (p.active ? systemColors.gold.base : '#695FBC')};
  color: ${(p) => (p.active ? themeColors.secondary.darker : '#FFFFFF')};
  box-shadow: ${(p) =>
    `${p.active && 'inset'} 0px 1px 4px rgba(0, 0, 0, 0.15)`};
  border-radius: 3px;
  cursor: pointer;
  padding: 10px 15px;
  text-align: center;
  transition: 0.3s all;

  :hover {
    box-shadow: ${(p) => !p.active && '0px 1px 4px rgba(0, 0, 0, 0.25)'};
    background-color: ${(p) => !p.active && themeColors.secondary.base};
  }
`;

const DetailButton = styled(DetailButtonPanel)`
  margin: 10px 0px 0px;
  padding: 8px 15px;
`;

const QuestionIcon = styled(QuestionCircleFilled)`
  color: #a3a1b7;
  margin-left: 4px;
`;

export const VIEWS = {
  predictions: 'predictions',
  simulator: 'simulator',
  predictiveColumns: 'predictiveColumns',
};

export default ({
  selection,
  setSelection,
  projectId,
  accuracyWord,
  errorMetric,
  predictiveColumns,
}) => {
  return (
    <ViewWrapper>
      <PipelineViewHeader
        projectId={projectId}
        onCurrentProjectStepClick={() => console.log('clicked')}
      />
      <DetailButtonPanel
        active={selection === VIEWS.predictions}
        onClick={() => setSelection(VIEWS.predictions)}
      >
        <span>Make Predictions</span>
        <RightOutlined />
      </DetailButtonPanel>
      <RowDetailPanel>
        <span>
          {accuracyWord}{' '}
          <a
            title="How to interpret error margin / accuracy?"
            href="https://intercom.help/intersect-labs/en/articles/3797615-how-to-interpret-error-margin-accuracy-of-model"
            target="_blank"
            rel="noopener noreferrer"
          >
            <QuestionIcon />
          </a>
        </span>
        <BorderDetailed>{errorMetric.toFixed(2)}%</BorderDetailed>
      </RowDetailPanel>
      <DetailPanel>
        <div>Simulate Outcomes</div>
        <DetailDescription>
          See how changing values can affect your business outcomes.
        </DetailDescription>
        <DetailButton
          active={selection === VIEWS.simulator}
          onClick={() => setSelection(VIEWS.simulator)}
        >
          <span>Show Simulator</span>
          <RightOutlined />
        </DetailButton>
      </DetailPanel>
      <DetailPanel>
        <div>Your Data's MVP</div>
        <DetailDescription>
          See which columns are influencing your outcomes the most.
        </DetailDescription>
        <DetailButton
          active={selection === VIEWS.predictiveColumns}
          onClick={() => setSelection(VIEWS.predictiveColumns)}
        >
          <span>Show Predictive Columns</span>
          <RightOutlined />
        </DetailButton>
      </DetailPanel>
    </ViewWrapper>
  );
};
