import React from 'react';
import styled from 'styled-components';
import PlanProsAndCons from './PlanProsAndCons';
import { getPricingRate } from './SubscriptionUtils';
import { Button } from 'antd';

const Title = styled.div`
  color: #000;
  font-weight: 600;
`;

const SubscriptionCostSummary = styled.div`
  font-weight: 300;
  font-size: 18px;
  color: #000;
`;

const SubscriptionSummary = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 1px solid #eeeeee;
  padding: 20px 0px;
  width: 420px;
  margin-right: 50px;
  background: #ffffff;

  > ${Title}, > ${SubscriptionCostSummary} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const ChangePlanButton = styled(Button)`
  margin-left: 20px;
  margin-top: 10px;
`;

export default ({ subscribingToProduct, borderTop, onChangePlanClick }) => {
  // In subscribing to product flow
  const pricingRateInCents = getPricingRate(
    subscribingToProduct,
    subscribingToProduct.subscriptionPeriod
  );
  const pricingRateInDollars = pricingRateInCents / 100;
  const title = `${subscribingToProduct.name}`;
  const costText = `$${pricingRateInDollars} / ${subscribingToProduct.subscriptionPeriod}`;
  const pros = subscribingToProduct.pros;

  return (
    <SubscriptionSummary>
      <Title>{title}</Title>
      <SubscriptionCostSummary>{costText}</SubscriptionCostSummary>
      <ChangePlanButton onClick={onChangePlanClick}>
        Change Plan
      </ChangePlanButton>
      <PlanProsAndCons pros={pros} borderTop={borderTop} />
    </SubscriptionSummary>
  );
};
