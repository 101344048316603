import { TYPES } from '../actions';

const billing = (state = [], action) => {
  switch (action.type) {
    case TYPES.RECEIVE_COUPON_DETAILS:
      return {
        ...state,
        couponDetails: action.coupon_details,
        couponCode: action.couponCode,
      };

    default:
      return state;
  }
};

export default billing;
