import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input } from 'antd';

export default ({
  project = {},
  doesProjectNameAlreadyExist,
  closeModal,
  showModal,
  renameProject,
}) => {
  if (!showModal) return <></>;
  const [name, setName] = useState();

  useEffect(() => {
    if (project.name) setName(project.name);
  }, [project.name]);

  const renameButtonClicked = () => {
    renameProject(project.id, name);
    closeModal();
  };

  let nameError = undefined;
  const nameExists = doesProjectNameAlreadyExist(name);
  if (nameExists && name !== project.name) {
    nameError = 'Another project with this name already exists';
  }

  const nameIsEmpty =
    name === undefined || name.length === 0 || name.trim().length === 0;

  return (
    <Modal
      title="Rename Project"
      visible={showModal}
      onOk={renameButtonClicked}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          disabled={nameExists || nameIsEmpty}
          type="primary"
          onClick={renameButtonClicked}
        >
          Rename
        </Button>,
      ]}
      okText="Rename"
    >
      <Form.Item
        hasFeedback={nameError !== undefined}
        validateStatus={nameError ? 'error' : ''}
        help={nameError || ''}
      >
        <Input
          type="text"
          placeholder="Enter a new name"
          defaultValue={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          onPressEnter={renameButtonClicked}
        />
      </Form.Item>
    </Modal>
  );
};
