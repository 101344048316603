import React, { Component } from 'react';
import VerifyEmail from '../auth-action-components/VerifyEmail';
import Loading from '../project-components/Loading';
import ResetPassword from '../auth-action-components/ResetPassword';

class AuthTasks extends Component {
  render() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const mode = params.get('mode');
    const actionCode = params.get('oobCode');

    switch (mode) {
      case 'verifyEmail':
        return <VerifyEmail actionCode={actionCode} />;
      case 'resetPassword':
        return <ResetPassword actionCode={actionCode} />;
      default:
        return <Loading />;
    }
  }
}

export default AuthTasks;
