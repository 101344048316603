import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button } from 'antd';
import { useDataSetDownloadCallback } from '../data-set-components/useDataSetDownloadCallback';
import { exportAsCSVFile } from './utilsCSV';

const DownloadButton = styled(Button)`
  margin-right: 15px;
`;

export default ({
  data,
  fetchDataExportFromServer = false,
  projectId = null,
  buttonText = 'Download Transformed Data',
}) => {
  const {
    downloadTransformedDataSet,
    dataSetIsLoading,
  } = useDataSetDownloadCallback();

  const handleClick = fetchDataExportFromServer
    ? () => downloadTransformedDataSet(projectId)
    : () =>
        exportAsCSVFile(
          data.columns.map((x) => x['name']),
          data.values,
          data.filename
        );
  return (
    <DownloadButton
      type="primary"
      loading={dataSetIsLoading}
      icon={<DownloadOutlined />}
      onClick={handleClick}
    >
      {buttonText}
    </DownloadButton>
  );
};
