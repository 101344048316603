import { isEqual, reduce, union } from 'lodash';

export const findChangedValues = (a, b) => {
  return reduce(
    a,
    function (result, value, key) {
      return isEqual(value, b[key]) ? result : result.concat(key);
    },
    []
  );
};

export const findSubsetOfChangedValues = (defaults, scenarios) => {
  const changesByScenario = {};
  const changesByScenarioArray = scenarios.map(({ name, column_values }) => {
    changesByScenario[name] = findChangedValues(defaults, column_values);
    return changesByScenario[name];
  });
  return [changesByScenario, union(...changesByScenarioArray)];
};
