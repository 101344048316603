import { useEffect, useState } from 'react';
import { API_PREFIX } from '../redux/actions';
import useAuthHeaders from '../redux/authHeadersHook';
import ShowBlockedUserNotification from '../auth-action-components/ShowBlockedUserNotification';
import { notification } from 'antd';

const displayErrorNotification_useAPI = ({
  message = '',
  errorNotification = true,
  duration = null,
}) => {
  const _message =
    typeof errorNotification === 'string' ? errorNotification : message;
  notification.error({
    duration, // By default, duration = null, meaning user must manually close it
    description:
      _message ||
      'Please check your internet connection or contact support at hello@intersectlabs.io',
  });
};

const checkResponseSuccess_useAPI = async (response) => {
  if (response.status !== 200 && response.status !== 201) {
    const body = await response.json();
    const errorMessage = `Status ${response.status}: ${response.statusText}`;
    console.error(errorMessage, body);
    if (response.status === 403 && body.message === 'BLOCKED_CONTACT_US') {
      ShowBlockedUserNotification();
    }
    return body.message.error
      ? body.message
      : { error: { message: body.message } }; // Since generic errors look like { message: 'Some error string' }
  }

  return response.json();
};

export const makeAPICall = ({
  endpoint,
  method,
  body = null,
  authHeaders = {},
  setIsLoading = null,
  setFetchedValue = null,
  setError = null,
  errorNotification = false,
}) => {
  setIsLoading && setIsLoading(true);
  return fetch(`${API_PREFIX}${endpoint}`, {
    method: method.toUpperCase(),
    body: body && JSON.stringify(body),
    headers: {
      ...authHeaders, // if any
      'Content-Type': 'application/json',
    },
  })
    .then(checkResponseSuccess_useAPI)
    .then((fetchedValue) => {
      if (fetchedValue.error) {
        setError && setError(fetchedValue.error);
        errorNotification &&
          displayErrorNotification_useAPI({
            message: fetchedValue.error.message,
            errorNotification,
          });
        return [undefined, fetchedValue.error];
      }
      setFetchedValue && setFetchedValue(fetchedValue);
      return [fetchedValue];
    })
    .catch((error) => {
      setError &&
        setError({
          message: `Something went wrong! Please check your internet connection or contact hello@intersectlabs.io with this error: ${
            error.message || error
          }`,
        });
    })
    .finally(() => setIsLoading && setIsLoading(false));
};

const _makeCall = ({
  endpoint,
  method = 'GET',
  body = undefined,
  errorNotification = false,
}) => {
  const authHeaders = useAuthHeaders();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedValue, setFetchedValue] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    !isLoading &&
      makeAPICall({
        endpoint,
        method,
        body,
        authHeaders,
        setIsLoading,
        setFetchedValue,
        setError,
        errorNotification,
      });
    // eslint-disable-next-line
  }, []);

  return [
    fetchedValue,
    {
      isLoading,
      error,
    },
  ];
};

const _getCallbackFn = ({
  endpoint,
  method = 'GET',
  body = undefined,
  errorNotification = false,
}) => {
  const authHeaders = useAuthHeaders();

  return ({ ...params }) =>
    makeAPICall({
      endpoint,
      method,
      body,
      authHeaders,
      errorNotification,
      ...params,
    });
};

export const useAPI = {
  makeCall: _makeCall,
  getCallbackFn: _getCallbackFn,
};
