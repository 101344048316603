import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Button, Spin } from 'antd';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import PurpleButton from '../PurpleButton';
import TransformConfigView from '../TransformConfigView';
import BeforeAfterDataView from './BeforeAfterDataView';
import useAuthHeaders from '../../redux/authHeadersHook';
import { API_PREFIX, checkResponseSuccess } from '../../redux/actions';
import { get } from 'lodash';
import { themeColors } from '../../colors/themeColors';

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f6f5ff;
  height: 100vh;
`;

const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 15px 25px;
`;

const Title = styled.div`
  font-weight: 600;
  color: ${themeColors.secondary.dark};
  font-size: 16px;
`;

const BackButton = styled(Button)`
  margin-right: 15px;
  color: ${themeColors.secondary.dark};
`;

export default ({
  id,
  module,
  transform_name,
  public_name,
  icon_file_svg,
  closeModalAndAddTransform,
  default_form_schema,
  default_transform_dict,
  sample_data,
  onClose,
}) => {
  const getDefaultConfigOptions = useCallback(
    () => get(default_transform_dict, 'node.options', {}),
    [default_transform_dict]
  );
  const [transformedData, setTransformedData] = useState();
  const [configOptions, setConfigOptions] = useState(getDefaultConfigOptions());
  const [loading, setLoading] = useState();
  const authHeaders = useAuthHeaders();

  useEffect(() => {
    setTransformedData(undefined);
    setConfigOptions(getDefaultConfigOptions());
  }, [id, getDefaultConfigOptions]);

  const applyDemoTransform = (configOptions) => {
    setLoading('Applying transform...');
    setConfigOptions(configOptions);
    fetch(`${API_PREFIX}/pasteur/demo/apply`, {
      method: 'POST',
      body: JSON.stringify({
        transform_id: id,
        options: JSON.stringify(configOptions),
      }),
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
    })
      .then(checkResponseSuccess)
      .then((data) => {
        setTransformedData(data);
        setLoading(undefined);
      })
      .catch((error) => {
        console.log('Apply Demo Transform Error:', error.message);
        // TODO handle error
        // setError({ message: error.message, newSimulationName, xAxisColumn });
        setLoading(undefined);
      });
  };

  return (
    <Spin
      tip={loading}
      spinning={loading !== undefined}
      wrapperClassName="flex-column-stretch"
    >
      <Panel>
        <HeaderRow>
          <Title>
            <BackButton icon={<ArrowLeftOutlined />} onClick={onClose} />{' '}
            {public_name}
          </Title>
          <PurpleButton
            icon={<PlusOutlined />}
            onClick={() => {
              onClose();
              closeModalAndAddTransform({ id, module, transform_name });
            }}
          >
            Add Transform
          </PurpleButton>
        </HeaderRow>
        <TransformConfigView
          defaultSchema={default_form_schema}
          renderTitle={false}
          onApplyNewTransform={applyDemoTransform}
          formData={configOptions}
          isPreview
        />
        <BeforeAfterDataView
          beforeData={sample_data}
          afterData={transformedData}
        />
      </Panel>
    </Spin>
  );
};
