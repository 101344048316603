import React, { useState, useEffect } from 'react';
import Loading from '../project-components/Loading';
import useAuthHeaders from '../redux/authHeadersHook';
import {
  API_PREFIX,
  checkResponseSuccess,
  checkResponseSuccess403ok,
} from '../redux/actions';
import ProjectEditor from '../project-components/ProjectEditor';
import PROJECT_SUBSTEPS from '../project-components/ProjectSteps';
import PasteurPipeline from './PasteurPipeline';
import { PROJECT_STEPS } from '../project-components/NewProjectModal';
import { useHistory } from 'react-router-dom';
import { displayErrorNotification } from '../pipeline-components/ApplyPipelineViaWeb';

const shouldShowML = (latest_step) => {
  const stepsToShowPipeline = [
    PROJECT_SUBSTEPS.MachineLearningPrepDone,
    PROJECT_SUBSTEPS.ModelTrainingStarted,
    PROJECT_SUBSTEPS.ModelsCreated,
  ];
  return stepsToShowPipeline.includes(latest_step);
};

export default ({ match }) => {
  const projectId = match.params.projectId;
  const showStep = match.params.showStep;
  const authHeaders = useAuthHeaders();
  const history = useHistory();
  const [projectStep, setProjectStep] = useState();
  const [dataSetName, setDataSetName] = useState();
  const [projectStepLoading, setProjectStepLoading] = useState(true);
  const [isPreppingForML, setIsPreppingForML] = useState(false);

  const prepForML = () => {
    if (isPreppingForML) return;

    setIsPreppingForML(true);
    fetch(`${API_PREFIX}/learning/prep/${projectId}`, {
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
    })
      .then(checkResponseSuccess403ok)
      .then((res) => {
        if (res.message && res.message.error) {
          history.push(`/projects/${projectId}/${PROJECT_STEPS.pipeline.url}`);
          displayErrorNotification(res.message);
          return;
        }
        const { latest_step } = res;
        setProjectStep(latest_step);
      })
      .finally(() => setIsPreppingForML(false));
  };

  useEffect(() => {
    setProjectStepLoading(true);
    fetch(`${API_PREFIX}/project/step/${projectId}`, {
      method: 'GET',
      headers: {
        ...authHeaders,
      },
    })
      .then(checkResponseSuccess)
      .then((project) => {
        setProjectStep(project.current_step);
        setDataSetName(project.dataset_filename || 'unchanged data');
        setProjectStepLoading(false);
      })
      .catch((error) => {
        setProjectStepLoading(false);
        console.error(`Error opening project`, error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!projectStep) return;

    if (
      showStep &&
      showStep === PROJECT_STEPS.ml.url &&
      !shouldShowML(projectStep)
    ) {
      prepForML();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStep, showStep]);

  if (isPreppingForML) {
    return <Loading tip="Prepping data for machine learning..." />;
  } else if (projectStepLoading) {
    return <Loading tip="Loading.." />;
  }

  if (
    (!showStep || showStep === PROJECT_STEPS.ml.url) &&
    shouldShowML(projectStep)
  ) {
    return (
      <ProjectEditor projectId={projectId} currentProjectStep={projectStep} />
    );
  } else {
    return (
      <PasteurPipeline
        projectId={projectId}
        dataSetName={dataSetName}
        currentProjectStep={projectStep}
        pipelineInUse={shouldShowML(projectStep)}
      />
    );
  }
};
