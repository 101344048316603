import { combineReducers } from 'redux';
import loading from './loading';
import user from './user';
import projects from './projects';
import data from './data';
import training from './training';
import billing from './billing';
import pipelines from './pipelines';

const app = combineReducers({
  loading,
  user,
  projects,
  data,
  training,
  billing,
  pipelines,
});

export default app;
