import React, { useCallback } from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';
import { CaretDownOutlined, RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import MLAccessWrapper from './MLAccessWrapper';
import { PROJECT_STEPS } from './NewProjectModal';
import { themeColors } from '../colors/themeColors';
import { systemColors } from '../colors/systemColors';

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  background: ${themeColors.secondary.dark};
  color: #ffffff;
  padding: 7px 7px 7px 12px;
  height: 42px;
  cursor: pointer;

  :hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }
`;

const StepTitle = styled.div`
  background: ${themeColors.secondary.darker};
  color: ${themeColors.secondary.dimText};
  font-weight: 600;
  padding: 3px 6px;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    padding-left: 3px;
  }
`;

const ProjectTitle = styled.div`
  flex-basis: 100%;
  padding-right: 10px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProjectStepsPopoutWrapper = styled.div`
  padding: 30px;
  max-width: 350px;
`;

const ProjectStepsTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${themeColors.secondary.dark};
`;

const ProjectStep = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const ProjectStepNumber = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #c4cdd3;
`;

const ProjectStepButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${(p) =>
    p.active
      ? 'inset 0px 1px 4px rgba(0, 0, 0, 0.25)'
      : '0px 1px 4px rgba(0, 0, 0, 0.1)'};
  border-radius: 2px;
  margin-left: 15px;
  padding: 8px 15px;
  font-size: 16px;
  color: ${themeColors.secondary.darker};
  width: 200px;
  transition: 0.3 all;
  background: ${(p) => (p.active ? systemColors.gold.base : '#ffffff')};
  opacity: ${(p) => (p.disabled ? 0.3 : 1)};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};

  :hover {
    box-shadow: ${(p) => !p.active && '0px 1px 4px rgba(0, 0, 0, 0.2)'};
  }
`;

const ProjectStepPopout = (projectId, history, activeStep, projectName) => {
  const ProjectStepButton = ({ active, step, disabled = false }) => {
    return (
      <ProjectStepButtonWrapper
        active={active}
        onClick={() => {
          !disabled && history.push(`/projects/${projectId}/${step.url}`);
        }}
        disabled={disabled}
      >
        <span>{step.display}</span>
        <RightOutlined style={{ fontSize: 12 }} />
      </ProjectStepButtonWrapper>
    );
  };
  const MLAccessProjectStepButton = MLAccessWrapper(ProjectStepButton);

  return (
    <ProjectStepsPopoutWrapper>
      <ProjectStepsTitle>{projectName}</ProjectStepsTitle>
      <ProjectStep>
        <ProjectStepNumber>1)</ProjectStepNumber>
        <ProjectStepButton
          active={PROJECT_STEPS.pipeline.display === activeStep}
          step={PROJECT_STEPS.pipeline}
        />
      </ProjectStep>
      <ProjectStep>
        <ProjectStepNumber>2)</ProjectStepNumber>
        <MLAccessProjectStepButton
          active={PROJECT_STEPS.ml.display === activeStep}
          step={PROJECT_STEPS.ml}
        />
      </ProjectStep>
    </ProjectStepsPopoutWrapper>
  );
};

export default ({ projectId, isInMLStep = false }) => {
  const getProjectName = useCallback(
    (state) => {
      const projects = get(state, ['projects'], []);
      const currentProject =
        projects.find((project) => project.id === projectId) || {};
      return currentProject.name || 'Project';
    },
    [projectId]
  );
  const projectName = useSelector(getProjectName);
  const history = useHistory();
  const currentPathname = history.location.pathname;
  let currentStep = isInMLStep
    ? PROJECT_STEPS.ml.display
    : PROJECT_STEPS.pipeline.display;
  if (
    currentPathname.endsWith(`/${PROJECT_STEPS.ml.url}`) ||
    currentPathname.includes(`/${PROJECT_STEPS.ml.url}/`)
  )
    currentStep = PROJECT_STEPS.ml.display;

  return (
    <Popover
      placement="bottom"
      title={null}
      content={ProjectStepPopout(projectId, history, currentStep, projectName)}
      trigger="hover"
    >
      <MenuWrapper>
        <ProjectTitle key="projectName">{projectName}</ProjectTitle>
        <StepTitle key="currentStep">
          {currentStep}
          <CaretDownOutlined key="caret" />
        </StepTitle>
      </MenuWrapper>
    </Popover>
  );
};
