import React, { useState, useEffect } from 'react';
import { Drawer, Menu, Input, Empty } from 'antd';
import { PlusOutlined, CaretRightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import _ from 'lodash';
import PurpleButton from '../PurpleButton';
import TransformPreviewPanel from './TransformPreviewPanel';
import { themeColors } from '../../colors/themeColors';

const Body = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  color: #fff;
`;

const MenuItem = styled(Menu.Item)`
  text-transform: capitalize;
`;

const Transforms = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: center;
  align-content: flex-start;
  width: 100%;
  padding: 40px;
  background: ${themeColors.secondary.dark};

  ::after {
    content: ' ';
    display: block;
    padding-bottom: 40px;
    height: 0px;
    width: 100%;
  }
`;

const TransformsPanelTitle = styled.div`
  font-size: 21px;
  font-weight: 300;
  text-transform: capitalize;
  width: 100%;
  margin: 0px 25px 20px;
  border-bottom: 1px solid ${themeColors.secondary.darker};
  padding-bottom: 10px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NoTransformsFoundMessage = styled.div`
  font-weight: 300;
  font-size: 18px;
  margin-top: 30px;
`;

const TransformCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${themeColors.secondary.darker};
  border-radius: 6px;
  color: #ffffff;
  flex-basis: 235px;
  max-height: 320px;
  margin: 25px;
  text-align: center;
  overflow: auto;
`;

const CardTitle = styled.div`
  font-weight: 600;
  padding: 20px 20px 2px;
`;

const CardSubtitle = styled.div`
  font-weight: 300;
  margin-top: 5px;
  padding: 0px 20px 10px;
  flex-grow: 1;
  overflow: auto;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding: 2px;

  > * {
    flex-basis: 50%;
    margin: 3px;
  }
`;

const searchFilterAndDeprecatedChecker = (searchFilter) => {
  return (t) => {
    if (!t.deprecated) {
      const lowerCaseName = t.public_name.toLowerCase();
      return lowerCaseName.includes(searchFilter.toLowerCase());
    }

    return false;
  };
};

const deprecatedChecker = (t) => !t.deprecated;

export default ({ isOpen, close, onAddTransformClick, allTransformsList }) => {
  const categories = { all: [] };
  allTransformsList.forEach((transform) => {
    categories.all.push(transform);
    transform.tags.forEach((tag) => {
      const transforms = _.get(categories, tag, []);
      transforms.push(transform);
      categories[tag] = transforms;
    });
  });
  const tags = Object.keys(categories);

  const transformsView = React.createRef();
  const [searchFilter, setSearchFilter] = useState();
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [previewTransform, setPreviewTransform] = useState();
  const [previewDrawerVisible, setPreviewDrawerVisible] = useState(false);

  useEffect(() => {
    if (transformsView && transformsView.current) {
      transformsView.current.scrollTo(0, 0);
    }
  }, [selectedCategory, transformsView]);

  const showPreviewForTransform = (transform) => {
    setPreviewTransform(transform);
    setPreviewDrawerVisible(true);
  };

  const hidePreviewForTransform = () => {
    setPreviewDrawerVisible(false);
  };

  const closeModalAndAddTransform = (transform) => {
    onAddTransformClick(
      transform.id,
      transform.module,
      transform.transform_name
    );
    close();
  };

  const checker =
    searchFilter && searchFilter.length > 0
      ? searchFilterAndDeprecatedChecker(searchFilter)
      : deprecatedChecker;
  let transformsToShow = categories[selectedCategory].filter(checker);

  let transformCards = transformsToShow.map((transform) => (
    <TransformCard key={transform.id} data-transform={transform.id}>
      <CardTitle>{transform.public_name}</CardTitle>
      <CardSubtitle>{transform.short_description}</CardSubtitle>
      <ButtonRow>
        <PurpleButton
          icon={<PlusOutlined />}
          onClick={() => closeModalAndAddTransform(transform)}
        >
          Add
        </PurpleButton>
        <PurpleButton
          icon={<CaretRightOutlined />}
          onClick={() => showPreviewForTransform(transform)}
          data-btn-id="preview-transform"
        >
          Preview
        </PurpleButton>
      </ButtonRow>
    </TransformCard>
  ));

  if (transformCards.length === 0) {
    transformCards = (
      <Empty
        description={
          <NoTransformsFoundMessage>
            <p>
              Couldn't find any transforms matching "<b>{searchFilter}</b>" in{' '}
              <b>{selectedCategory}</b> transforms.
            </p>
            {selectedCategory !== 'all' && (
              <p>
                Try searching in the <b>All</b> category on the sidebar.
              </p>
            )}
          </NoTransformsFoundMessage>
        }
      />
    );
  }

  const sidebarStyle = {
    width: 250,
    minWidth: 250,
    overflowY: 'auto',
    overflowX: 'hidden',
    background: '#362f73',
  };

  const onCategorySelect = ({ key }) => {
    if (!key) return;

    setSelectedCategory(key);
    setSearchFilter(undefined);
  };

  const sidebar = (
    <Menu
      className="ant-menu-dark-purple"
      onClick={onCategorySelect}
      style={sidebarStyle}
      defaultSelectedKeys={[selectedCategory]}
      mode="inline"
      theme="dark"
    >
      <Menu.ItemGroup key="g1" title="Transforms">
        {tags.map((category) => (
          <MenuItem key={category} data-transform-category={category}>
            {category}
          </MenuItem>
        ))}
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Drawer
      title={null}
      placement="right"
      width="75vw"
      bodyStyle={{ padding: 0, height: '100%' }}
      closable={true}
      onClose={close}
      visible={isOpen}
    >
      <Body>
        {sidebar}
        <Transforms ref={transformsView}>
          <TransformsPanelTitle>
            <div>{selectedCategory} Transforms</div>
            <Input.Search
              placeholder="Find transform..."
              onChange={(e) => setSearchFilter(e.target.value)}
              style={{ width: 200, borderRadius: 16 }}
              allowClear
              value={searchFilter}
            />
          </TransformsPanelTitle>
          {transformCards}
        </Transforms>
      </Body>
      <Drawer
        title={null}
        placement="right"
        width="75vw"
        bodyStyle={{ padding: 0, height: '100%' }}
        closable={false}
        onClose={() => hidePreviewForTransform()}
        visible={previewDrawerVisible}
      >
        <TransformPreviewPanel
          {...previewTransform}
          closeModalAndAddTransform={closeModalAndAddTransform}
          onClose={() => hidePreviewForTransform()}
        />
      </Drawer>
    </Drawer>
  );
};
