import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchDemoToken } from '../redux/authActions';
import { Redirect } from 'react-router-dom';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchCustomToken: () => {
      dispatch(fetchDemoToken());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

class YcDemoPage extends Component {
  componentWillMount() {
    const fetchCustomToken = this.props.fetchCustomToken;
    fetchCustomToken();
  }

  render() {
    return <Redirect to={`/`} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(YcDemoPage);
