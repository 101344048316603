import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions';
import { find, get } from 'lodash';
import Question from './Question';
import Loading from './Loading';
import ModelingStatus from './ModelingStatus';
import ILDataTable from '../data-set-components/ILDataTable';
import ProjectBreadcrumbMenu from './ProjectBreadcrumbMenu';

const WizardPageWrapper = styled.div`
  width: 100%;
  display: block;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  background: #fafaff;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 30px;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 50vh;
  border: 1px solid #eee;
  border-top-left-radius: 5px;
  background: #ffffff;
`;

const DataTableWrapper = styled.div`
  flex-grow: 1;
`;

const DataDescripton = styled.div`
  text-align: left;
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
`;

const Light = styled.div`
  font-weight: 300;
`;

/**
 * Redux Helpers
 */
const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.projectId;
  const project = find(state.projects, (p) => p.id === projectId);
  return {
    projects: state.projects,
    project,
    data: get(state.data, projectId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchData: () => dispatch(Actions.fetchData(ownProps.projectId)),
    fetchProjects: () => dispatch(Actions.fetchProjects()),
    submitAnswers: (goal, targetColumn) =>
      dispatch(
        Actions.submitProjectAnswer(ownProps.projectId, goal, targetColumn)
      ),
    startTraining: () => dispatch(Actions.startTraining(ownProps.projectId)),
  };
};

class ProjectEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      stepNumber: this.getCurrentStep(props),
      answers: {},
    };
  }

  getCurrentStep = (props) => {
    const { data } = props;

    if (data) {
      if (data.training_done) {
        return 4;
      }
      if (data.training_started) {
        return 3;
      } else if (data.answers !== 'NOT_COMPLETE') {
        return 2;
      }

      if (data.possible_goal_per_column) {
        return 1;
      }
      return 0;
    }

    return -1;
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.data !== prevProps.data) {
      this.setState({ stepNumber: this.getCurrentStep(this.props) });
    }
  };

  componentDidMount = () => {
    if (this.props.projects === null) this.props.fetchProjects();

    this.props.fetchData();
  };

  recordAnswer = (key, value) => {
    if (key === 'start_training') {
      this.props
        .submitAnswers(
          this.state.answers.goal,
          this.state.answers.target_column
        )
        .then(() => {
          this.props.startTraining();
        });
    } else if (
      key === 'target_column' &&
      this.props.data.possible_goal_per_column
    ) {
      this.setState((prevState) => {
        return {
          stepNumber: prevState.stepNumber + 1,
          answers: {
            ...prevState.answers,
            [key]: value,
            goal: this.props.data.possible_goal_per_column[value],
          },
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          stepNumber: prevState.stepNumber + 1,
          answers: {
            ...prevState.answers,
            [key]: value,
          },
        };
      });
    }
  };

  resetAnswers = () => {
    if (this.props.data.possible_goal_per_column) {
      this.setState({
        stepNumber: 1,
        answers: {},
      });
    } else {
      this.setState({
        stepNumber: 0,
        answers: {},
      });
    }
  };

  render() {
    let question;
    if (this.state.stepNumber === -1) {
      return <Loading tip="Loading..." />;
    } else if (this.state.stepNumber === 0) {
      question = (
        <Question questionKey="goal" recordAnswer={this.recordAnswer} />
      );
    } else if (this.state.stepNumber === 1) {
      const possibleGoalsPerColumn = this.props.data.possible_goal_per_column;
      const columnOptions = possibleGoalsPerColumn
        ? Object.keys(possibleGoalsPerColumn)
        : this.props.data.columns.map((col) => col.name);
      question = (
        <Question
          questionKey="target_column"
          verb={this.state.answers.goal}
          columns={columnOptions}
          recordAnswer={this.recordAnswer}
        />
      );
    } else if (this.state.stepNumber === 2) {
      question = (
        <Question
          questionKey="start_training"
          recordAnswer={this.recordAnswer}
          resetAnswers={this.resetAnswers}
        />
      );
    } else if (this.state.stepNumber === 3) {
      return <ModelingStatus projectId={this.props.projectId} />;
    }
    let columns = [];
    if (this.props.data) {
      columns = this.props.data.columns.map((column) => ({
        ...column,
        resizable: true,
        width: 100,
      }));
    }

    return (
      <WizardPageWrapper>
        <MenuWrapper>
          <ProjectBreadcrumbMenu
            projectId={this.props.projectId}
            isInMLStep={true}
          />
        </MenuWrapper>

        {question}

        {this.props.data && (
          <DataWrapper>
            <DataDescripton>
              <b>Data Preview</b> <Light>30 randomly selected rows</Light>
            </DataDescripton>
            <DataTableWrapper>
              <ILDataTable
                className="project-data-table"
                columns={columns}
                dataSource={this.props.data.data}
              />
            </DataTableWrapper>
          </DataWrapper>
        )}
      </WizardPageWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditor);
