import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, Select } from 'antd';
import { themeColors } from '../colors/themeColors';
const Option = Select.Option;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  text-align: left;
`;

const QuestionText = styled.div`
  font-size: 18px;
  color: ${themeColors.primary.darkText};
  margin-bottom: 20px;
  text-align: left;
`;

const Options = styled.div`
  > * {
    margin-right: 20px;
  }
`;

export const getColumnSelect = (columns, onChange, defaultValue) => {
  const options = columns.map((col) => (
    <Option key={col} value={col}>
      {col}
    </Option>
  ));

  return (
    <Select
      showSearch
      style={{ width: 300 }}
      placeholder="Select a column  "
      optionFilterProp="children"
      defaultValue={defaultValue}
      onChange={(value) => onChange(value)}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default class Question extends Component {
  state = {};

  renderAction() {}

  onOptionSelect = (question, value) => this.setState({ [question]: value });

  renderQuestion = (questionText, actionItems) => {
    return (
      <QuestionWrapper>
        {questionText && <QuestionText>{questionText}</QuestionText>}

        {actionItems}
      </QuestionWrapper>
    );
  };

  renderQuestion1 = () => {
    const options = ['Classify', 'Predict'];
    const buttons = options.map((action) => (
      <Button
        key={action}
        onClick={() => this.props.recordAnswer(this.props.questionKey, action)}
      >
        {action}
      </Button>
    ));

    return this.renderQuestion(
      'What do you want to do?',
      <Options>{buttons}</Options>
    );
  };

  renderQuestion2 = () => {
    return this.renderQuestion(
      'Select the column that represents your outcome variable.',
      getColumnSelect(this.props.columns, (value) =>
        this.props.recordAnswer(this.props.questionKey, value)
      )
    );
  };

  renderQuestion3 = () => {
    return this.renderQuestion(
      'Now we can train different machine learning algorithms and see which one works best.',
      <Options>
        <Button
          type="primary"
          onClick={() =>
            this.props.recordAnswer(this.props.questionKey, 'start')
          }
        >
          Start Training
        </Button>
        <Button onClick={() => this.props.resetAnswers()}>Reset</Button>
      </Options>
    );
  };

  render() {
    const { questionKey } = this.props;
    if (questionKey === 'goal') return this.renderQuestion1();
    else if (questionKey === 'target_column') return this.renderQuestion2();
    else if (questionKey === 'start_training') return this.renderQuestion3();
    else return <div />;
  }
}
