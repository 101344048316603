import { TYPES } from '../actions';

const pipelines = (state = [], action) => {
  switch (action.type) {
    case TYPES.RECEIVE_PIPELINES:
      return action.pipelines;

    default:
      return state;
  }
};

export default pipelines;
