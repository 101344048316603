import * as AuthActions from './authActions';
import ShowBlockedUserNotification from '../auth-action-components/ShowBlockedUserNotification';
import { makeAPICall } from '../api/useAPI';

export const TYPES = {
  RECEIVE_PROJECTS: 'RECEIVE_PROJECTS',
  ADD_NEW_PROJECT: 'ADD_NEW_PROJECT',
  RECEIVE_DATA: 'RECEIVE_DATA',
  TRAINING_STARTED: 'TRAINING_STARTED',
  TRAINING_DONE: 'TRAINING_DONE',
  RECEIVE_PREDICTION_TOKEN: 'RECEIVE_PREDICTION_TOKEN',
  DELETE_PROJECT: 'DELETE_PROJECT',
  RENAME_PROJECT: 'RENAME_PROJECT',
  PROJECTS_FETCHING_START: 'PROJECTS_FETCHING_START',
  PROJECTS_FETCHING_END: 'PROJECTS_FETCHING_END',
  PROJECT_ANSWER_SUBMISSION_START: 'PROJECT_ANSWER_SUBMISSION_START',
  PROJECT_ANSWER_SUBMISSION_END: 'PROJECT_ANSWER_SUBMISSION_END',
  TRAINING_ERROR: 'TRAINING_ERROR',
  RECEIVE_COUPON_DETAILS: 'RECEIVE_COUPON_DETAILS',
  RECEIVE_PIPELINES: 'RECEIVE_PIPELINES',
};

export const API_PREFIX = process.env.REACT_APP_PROPHET_ENDPOINT;

export const getAuthHeaders = (user) => {
  const accessToken = user.accessToken;
  if (accessToken === undefined) {
    return undefined;
  }

  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

// TODO: this is temporary hack for product hunt launch of Pasteur
export const checkResponseSuccess403ok = async (response) => {
  if (
    response.status !== 200 &&
    response.status !== 201 &&
    response.status !== 403
  ) {
    const body = await response.json();
    throw new Error(body.message);
  }
  return response.json();
};

export const checkResponseSuccess = async (response) => {
  if (response.status !== 200 && response.status !== 201) {
    const body = await response.json();
    const errorMessage = `Status ${response.status}: ${response.statusText}`;
    console.error(errorMessage, body);
    if (response.status === 403 && body.message === 'BLOCKED_CONTACT_US') {
      ShowBlockedUserNotification();
    }

    throw new Error(body.message);
  }
  return response.json();
};

export const applyCoupon = (couponCode) => async (dispatch, getState) => {
  console.log(couponCode);
  const authHeaders = getAuthHeadersWithRedux(dispatch, getState);
  if (authHeaders === undefined) return;

  fetch(`${API_PREFIX}/billing/coupon/${couponCode}/apply`, {
    method: 'GET',
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
  })
    .then(checkResponseSuccess)
    .then((data) => {
      dispatch(receiveCouponDetails(couponCode, data.coupon_details));
    })
    .catch((error) => dispatch(receiveCouponDetails('', 'INVALID')));
};

export const receiveCouponDetails = (couponCode, couponDetails) => {
  return {
    type: TYPES.RECEIVE_COUPON_DETAILS,
    coupon_details: couponDetails,
    couponCode: couponCode,
  };
};

export const getAuthHeadersWithRedux = (dispatch, getState) => {
  const { user } = getState();
  const accessToken = user.accessToken;
  if (accessToken === undefined) {
    dispatch(AuthActions.logout());
    return undefined;
  }

  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export const receiveProjects = (projects) => {
  return {
    type: TYPES.RECEIVE_PROJECTS,
    projects,
  };
};

export const receivePipelines = (pipelines) => {
  return {
    type: TYPES.RECEIVE_PIPELINES,
    pipelines,
  };
};

export const addNewProject = (project) => {
  return {
    type: TYPES.ADD_NEW_PROJECT,
    project,
  };
};

export const deleteProjectFromRedux = (project) => {
  return {
    type: TYPES.DELETE_PROJECT,
    project,
  };
};

export const fetchProjects = () => async (dispatch, getState) => {
  dispatch(projectFetchingStart());
  const authHeaders = getAuthHeadersWithRedux(dispatch, getState);
  if (authHeaders === undefined) return;

  try {
    const response = await fetch(`${API_PREFIX}/user/projects`, {
      method: 'GET',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
    });
    const data = await checkResponseSuccess(response);
    dispatch(receiveProjects(data.projects));
    dispatch(receivePipelines(data.pipelines));
    dispatch(projectFetchingEnd());
  } catch (error) {
    console.error('Fetch Projects Error', error);
  }
};

export const createProject = (
  name,
  dataFile,
  uploadedDataId,
  columns,
  updateProjectCreationStatus
) => async (dispatch, getState) => {
  const authHeaders = getAuthHeadersWithRedux(dispatch, getState);
  if (authHeaders === undefined) return;
  updateProjectCreationStatus(true);

  const data = new FormData();
  data.append('name', name);
  if (dataFile) data.append('file', dataFile);
  if (uploadedDataId) data.append('uploaded_data_id', uploadedDataId);
  if (columns) data.append('columns', JSON.stringify(columns));

  fetch(`${API_PREFIX}/project/v2`, {
    method: 'POST',
    body: data,
    headers: {
      ...authHeaders,
    },
  })
    .then(checkResponseSuccess)
    .then((project) => {
      dispatch(addNewProject(project));
      updateProjectCreationStatus(false, project.id);
    });
};

export const removeProject = (project) => async (dispatch, getState) => {
  dispatch(deleteProjectFromRedux(project));
  dispatch(deleteProjectFromServer(project.id));
};

export const deleteProjectFromServer = (projectId) => async (
  dispatch,
  getState
) => {
  const authHeaders = getAuthHeadersWithRedux(dispatch, getState);
  if (authHeaders === undefined) return;

  fetch(`${API_PREFIX}/project/${projectId}/delete`, {
    method: 'POST',
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
  }).then(checkResponseSuccess);
};

export const renameProject = (projectId, newName) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: TYPES.RENAME_PROJECT,
    projectId,
    newName,
  });
  dispatch(renameProjectOnServer(projectId, newName));
};

export const renameProjectOnServer = (projectId, newName) => async (
  dispatch,
  getState
) => {
  const authHeaders = getAuthHeadersWithRedux(dispatch, getState);
  if (authHeaders === undefined) return;

  fetch(`${API_PREFIX}/project/${projectId}/rename`, {
    method: 'POST',
    body: JSON.stringify({
      name: newName,
    }),
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
  }).then(checkResponseSuccess);
};

export const submitProjectAnswer = (project_id, goal, target_column) => async (
  dispatch,
  getState
) => {
  dispatch(projectAnswerSubmissionStart());
  const authHeaders = getAuthHeadersWithRedux(dispatch, getState);
  if (authHeaders === undefined) return;

  fetch(`${API_PREFIX}/project/${project_id}/answers`, {
    method: 'POST',
    body: JSON.stringify({ goal, target_column }),
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
  })
    .then(checkResponseSuccess)
    .then((data) => {
      dispatch(projectAnswerSubmissionEnd());
    });
};

export const fetchData = (projectId) => async (dispatch, getState) => {
  const authHeaders = getAuthHeadersWithRedux(dispatch, getState);
  if (authHeaders === undefined) return;

  fetch(`${API_PREFIX}/project/launch/${projectId}`, {
    method: 'GET',
    headers: {
      ...authHeaders,
    },
  })
    .then(checkResponseSuccess)
    .then((data) => {
      // console.log('fetchData', data)
      dispatch({
        type: TYPES.RECEIVE_DATA,
        projectId,
        data,
      });
    });
};

export const startTraining = (projectId) => async (dispatch, getState) => {
  const authHeaders = getAuthHeadersWithRedux(dispatch, getState);
  if (authHeaders === undefined) return;

  const setIsLoading = (isLoading) =>
    isLoading &&
    dispatch({
      type: TYPES.TRAINING_STARTED,
      projectId,
    });

  const setError = (error) => dispatch(receiveTrainingError(projectId, error));

  makeAPICall({
    endpoint: `/learning/train/${projectId}`,
    method: 'POST',
    authHeaders,
    setIsLoading,
    setError,
    errorNotification: true,
  });
};

export const receiveTrainingError = (projectId, error) => {
  return {
    type: TYPES.TRAINING_ERROR,
    projectId,
    error,
  };
};

export const trainingDone = (modelId) => {
  return {
    type: TYPES.TRAINING_DONE,
    modelId,
  };
};

export const receivePredictionAPIToken = (predictionAPIToken) => {
  return {
    type: TYPES.RECEIVE_PREDICTION_TOKEN,
    predictionAPIToken,
  };
};

export const fetchPredictionAPIToken = () => async (dispatch, getState) => {
  const authHeaders = getAuthHeadersWithRedux(dispatch, getState);
  if (authHeaders === undefined) return;

  fetch(`${API_PREFIX}/user/predictionToken`, {
    method: 'GET',
    headers: {
      ...authHeaders,
    },
  })
    .then(checkResponseSuccess)
    .then((data) => {
      dispatch(receivePredictionAPIToken(data.prediction_api_token));
    });
};

export const projectFetchingStart = () => {
  return {
    type: TYPES.PROJECTS_FETCHING_START,
  };
};

export const projectFetchingEnd = () => {
  return {
    type: TYPES.PROJECTS_FETCHING_END,
  };
};

export const receiveSubscriptionStatus = (
  status,
  subscriptionId,
  product_name,
  current_period_end
) => {
  return {
    type: TYPES.RECEIVE_SUBSCRIPTION_STATUS,
    status,
    subscriptionId,
    product_name,
    current_period_end,
  };
};

export const projectAnswerSubmissionStart = () => {
  return {
    type: TYPES.PROJECT_ANSWER_SUBMISSION_START,
  };
};

export const projectAnswerSubmissionEnd = () => {
  return {
    type: TYPES.PROJECT_ANSWER_SUBMISSION_END,
  };
};
