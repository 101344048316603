import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover, Radio } from 'antd';
import PredictViaWeb from './PredictViaWeb';
import { themeColors } from '../colors/themeColors';
import PredictViaForm from './PredictViaForm';

const PageWrapper = styled.div`
  color: ${themeColors.secondary.dark};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

const Subtitle = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const ActionsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 25px;
  margin-top: 40px;
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #f4f5f2;
  border-radius: 5px;
  flex-grow: 1;
  width: 63vw;
`;

const CodeBlock = styled.code`
  border: 1px solid #f4f5f2;
  padding: 20px;
  background: #fafaff;
  font-size: 13px;
  max-width: 600px;
  border-radius: 5px;
`;

const PurpleLink = styled.span`
  text-decoration: underline;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;

  :hover {
    color: ${themeColors.secondary.base};
  }
`;

const ColumnNamesList = styled.div`
  max-height: 500px;
  overflow: auto;
`;

const applyOptions = {
  api: 'api',
  web: 'web',
  form: 'form',
};

const predictAPIPanel = (projectId = 'projectId') => (
  <Panel>
    <Title>Predict via API</Title>
    <Subtitle>
      Use the following command to make predictions using this model via API.
    </Subtitle>
    <CodeBlock>
      curl -X POST "https://api.intersectlabs.io/predict/"
      <br />
      {'-F file=@<file.csv>'}
      <br />
      {`-F "project=${projectId}"`}
      <br />
      {'-H "Authorization: Bearer  <token>"'}
    </CodeBlock>
  </Panel>
);

const PredictWebPanel = ({ requiredColumns, modelId, projectId }) => (
  <Panel>
    <Title>Predict via Web</Title>
    <Subtitle>
      Upload a CSV file with the same columns as the data you created this
      pipeline with and see the transformed output.
    </Subtitle>
    <PredictViaWeb
      requiredColumns={requiredColumns}
      modelId={modelId}
      projectId={projectId}
    />
  </Panel>
);

const PredictFormPanel = ({ projectId, title }) => (
  <Panel>
    <Title>Predict via Form</Title>
    <Subtitle>
      Ready to make a single prediction? Input the data for each column. We've
      gotten you started with some default values.
    </Subtitle>
    <PredictViaForm title={title} projectId={projectId} />
  </Panel>
);

export default ({ projectId, requiredColumns, title, namesList, modelId }) => {
  const [selectedOption, setSelectedOption] = useState(applyOptions.web);

  const columnsView = <ColumnNamesList>{namesList}</ColumnNamesList>;

  return (
    <PageWrapper>
      <Title>{title}</Title>
      <Subtitle>
        To make predictions, upload data that contains the{' '}
        <Popover
          content={columnsView}
          title="Columns"
          trigger="hover"
          placement="leftTop"
        >
          <PurpleLink>{namesList.length} columns</PurpleLink>
        </Popover>{' '}
        you trained the model with.
      </Subtitle>
      <ActionsWrapper>
        <Radio.Group
          className="yellow-radio-group"
          defaultValue={selectedOption}
          buttonStyle="solid"
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <Radio.Button value={applyOptions.web}>Predict via Web</Radio.Button>
          <Radio.Button value={applyOptions.api}>Predict via API</Radio.Button>
          <Radio.Button value={applyOptions.form}>
            Predict via Form
          </Radio.Button>
        </Radio.Group>
      </ActionsWrapper>
      {selectedOption === applyOptions.api && predictAPIPanel(projectId)}
      {selectedOption === applyOptions.web && (
        <PredictWebPanel
          requiredColumns={requiredColumns}
          modelId={modelId}
          projectId={projectId}
        />
      )}
      {selectedOption === applyOptions.form && (
        <PredictFormPanel projectId={projectId} title={title} />
      )}
    </PageWrapper>
  );
};
