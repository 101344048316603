import React, { Component } from 'react';
import { Button, Table } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NewProjectModal from '../project-components/NewProjectModal';
import DeleteProjectModal from '../project-components/DeleteProjectModal';
import * as Actions from '../redux/actions';
import { Input } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { format, parseISO } from 'date-fns';
import Loading from '../project-components/Loading';
import RenameProjectModal from '../project-components/RenameProjectModal';
import { themeColors } from '../colors/themeColors';

const ProjectListWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 50px;
`;
const ProjectOptionsWrapper = styled.div`
  display: flex;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
  min-height: 28px;
`;

const Title = styled.div`
  font-size: 18px;
`;

const LightText = styled.div`
  font-weight: 300;
`;

const DeleteIcon = styled(DeleteOutlined)`
  margin-left: 10px;
  margin-right: 10px;
  color: #444;

  :hover {
    color: #000;
  }
`;

const EditIcon = styled(EditOutlined)`
  margin-left: 10px;
  margin-right: 10px;
  color: #444;

  :hover {
    color: #000;
  }
`;

const ProjectLink = styled(Link)`
  font-size: 14px;
  color: ${themeColors.primary.base};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Search = styled(Input.Search)`
  margin-right: 20px;
`;

/**
 * Project Table Config
 */

/**
 * Redux Helpers
 */
const mapStateToProps = (state, ownProps) => {
  return {
    projects: state.projects,
    projectNames: state.projects.map((x) => x.name),
    loading: state.loading,
    pipelines: state.pipelines,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createProject: (
      name,
      dataFile,
      uploadedDataId,
      columns,
      creationInProgress
    ) =>
      dispatch(
        Actions.createProject(
          name,
          dataFile,
          uploadedDataId,
          columns,
          creationInProgress
        )
      ),
    fetchProjects: () => dispatch(Actions.fetchProjects()),
    removeProject: (project) => dispatch(Actions.removeProject(project)),
    renameProject: (projectId, newName) =>
      dispatch(Actions.renameProject(projectId, newName)),
  };
};

class ProjectList extends Component {
  state = {
    showCreateModal: false,
    hover: null,
    showDeleteProjectModal: false,
    projectToDelete: null,
    projectFilter: null,
  };

  componentDidMount = () => {
    // FYI Uncomment this to load projects from server
    this.props.fetchProjects();
  };

  createProject = (
    name,
    dataFile,
    uploadedDataId,
    columns,
    pipeline,
    creationInProgress
  ) => {
    this.props.createProject(
      name,
      dataFile,
      uploadedDataId,
      columns,
      pipeline,
      creationInProgress
    );
    this.setState({ showCreateModal: false });
  };

  removeProject = (project) => {
    this.props.removeProject(project);
  };

  openRenameProjectModal = (project) => {
    this.setState({
      showRenameProjectModal: true,
      projectToRename: project,
    });
  };

  openDeleteProjectModal = (project) => {
    this.setState({
      showDeleteProjectModal: true,
      projectToDelete: project,
    });
  };

  doesProjectNameAlreadyExist = (name) => {
    if (this.props.projectNames.includes(name)) {
      return true;
    }

    return false;
  };

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => (
          <ProjectLink to={`projects/${record.id}`}>{name}</ProjectLink>
        ),
      },
      {
        title: 'Created At',
        dataIndex: 'created_time',
        key: 'createdAt',
        render: (text) => (
          <LightText>{format(parseISO(text), 'PPp')}</LightText>
        ),
      },
      {
        title: 'Current Step',
        dataIndex: 'current_step',
        key: 'currentStep',
        render: (text) => {
          let currentStep = text;
          if (currentStep === null) currentStep = 'Initalized';
          return <LightText>{currentStep}</LightText>;
        },
      },
      {
        dataIndex: 'name',
        key: 'delete',
        width: 100,
        render: (name, record) => {
          const isRowHovered = this.state.hover === record.id;
          if (isRowHovered) {
            return (
              <>
                <DeleteIcon
                  onClick={() => this.openDeleteProjectModal(record)}
                />
                <EditIcon
                  type="edit"
                  onClick={() => this.openRenameProjectModal(record)}
                />
              </>
            );
          } else {
            return <></>;
          }
        },
      },
    ];

    let projects = this.props.projects;
    if (this.state.projectFilter !== null) {
      projects = projects.filter((project) => {
        const name = project.name.toLowerCase();
        return name.includes(this.state.projectFilter.toLowerCase());
      });
    }

    return this.props.loading.projectsFetching === 'started' ? (
      <Loading />
    ) : (
      <ProjectListWrapper>
        <TitleRow>
          <Title>Projects</Title>
          <ProjectOptionsWrapper>
            <Search
              placeholder="Find a project..."
              onChange={(e) =>
                this.setState({
                  projectFilter: e.target.value,
                })
              }
              style={{ width: 200 }}
            />
            <Button onClick={() => this.setState({ showCreateModal: true })}>
              New Project
            </Button>
          </ProjectOptionsWrapper>
        </TitleRow>
        <Table
          rowKey={(record) => record.name}
          dataSource={projects}
          columns={columns}
          rowClassName="project-row"
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: () => {
                this.setState({ hover: record.id });
              }, // mouse enter row
              onMouseLeave: (event) => {
                this.setState({ hover: null });
              }, // mouse leave row
            };
          }}
        />
        <NewProjectModal
          createProject={this.props.createProject}
          showModal={this.state.showCreateModal}
          closeModal={() => this.setState({ showCreateModal: false })}
          doesProjectNameAlreadyExist={this.doesProjectNameAlreadyExist}
        />
        <DeleteProjectModal
          removeProject={this.removeProject}
          project={this.state.projectToDelete}
          showModal={this.state.showDeleteProjectModal}
          closeModal={() => this.setState({ showDeleteProjectModal: false })}
        />
        <RenameProjectModal
          renameProject={this.props.renameProject}
          project={this.state.projectToRename}
          doesProjectNameAlreadyExist={this.doesProjectNameAlreadyExist}
          showModal={this.state.showRenameProjectModal}
          closeModal={() => this.setState({ showRenameProjectModal: false })}
        />
      </ProjectListWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
