import { TYPES } from '../actions';

const projects = (state = [], action) => {
  switch (action.type) {
    case TYPES.RECEIVE_PROJECTS:
      return action.projects;

    case TYPES.ADD_NEW_PROJECT:
      return [action.project, ...state];

    case TYPES.DELETE_PROJECT:
      return state.filter((project) => project !== action.project);

    case TYPES.RENAME_PROJECT:
      return state.map((project) => {
        if (project.id !== action.projectId) return project;
        return { ...project, name: action.newName };
      });

    default:
      return state;
  }
};

export default projects;
