import React from 'react';
import { notification } from 'antd';

export default () => {
  notification.error({
    duration: null,
    style: { background: '#fff9f9', border: '1px solid #ffa39e' },
    message: 'You do not have access!',
    description: (
      <div>
        It seems that you don't have access to Intersect Labs right now. <br />
        <br />
        Please contact us at{' '}
        <a href="mailto: hello@intersectlabs.io">hello@intersectlabs.io</a>
      </div>
    ),
  });
};
