import React, { Component } from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';
import ApiKeys from '../settings-components/ApiKeys';
import BillingView from '../settings-components/BillingView';

const { TabPane } = Tabs;

const SettingsPageWrapper = styled.div`
  flex-grow: 1;
  display: block;
  padding-left: 50px;
  padding-top: 50px;
`;

class SettingsPage extends Component {
  callback = (key) => {};

  render() {
    return (
      <SettingsPageWrapper>
        <Tabs type="card">
          <TabPane tab="Billing" key="checkout">
            <BillingView />
          </TabPane>
          <TabPane tab="API Token" key="apitoken">
            <ApiKeys />
          </TabPane>
        </Tabs>
      </SettingsPageWrapper>
    );
  }
}

export default SettingsPage;
