import React, { Component } from 'react';
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions';
import { find, get } from 'lodash';
import { Redirect } from 'react-router-dom';

import Loading from './Loading';
import { themeColors } from '../colors/themeColors';

const LoadingIcon = styled(LoadingOutlined)`
  margin-left: 10px;
  color: ${themeColors.secondary.selected.darker};
`;

const CheckIcon = styled(CheckOutlined)`
  margin-left: 10px;
  color: ${themeColors.secondary.selected.darker};
`;

const ModelsPageWrapper = styled.div`
  flex-grow: 1;
  display: block;
  padding: 50px;
  justify-content: center;
`;

const StatusNameWrapper = styled.div`
  font-size: 20px;
  color: ${themeColors.primary.darkText};
`;

const StatusRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.projectId;
  const project = find(state.projects, (p) => p.id === projectId);
  return {
    projects: state.projects,
    project,
    data: get(state.data, projectId),
    user: state.user,
    model: state.training,
    trainingError: state.trainingError,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchData: () =>
      dispatch(Actions.fetchData(ownProps.match.params.projectId)),
    submitAnswers: (goal, targetColumn) =>
      dispatch(
        Actions.submitProjectAnswer(
          ownProps.match.params.projectId,
          goal,
          targetColumn
        )
      ),

    trainingDone: (modelId) => dispatch(Actions.trainingDone(modelId)),
    receiveTrainingError: (projectId, error) =>
      dispatch(Actions.receiveTrainingError(projectId, error)),
  };
};

class ModelingStatus extends Component {
  state = {
    dataingested: true,
    dataCleaned: false,
    modelsTrained: null,
    model_array: null,
    numberOfModels: null,
    allTrainingDone: false,
  };

  fetchTrainingStatus = () => {
    if (!this.state.allTrainingDone) {
      const authHeaders = Actions.getAuthHeaders(this.props.user);
      if (authHeaders === undefined) return;

      fetch(
        `${Actions.API_PREFIX}/learning/status/project/${this.props.projectId}`,
        {
          method: 'GET',
          headers: {
            ...authHeaders,
          },
        }
      )
        .then(Actions.checkResponseSuccess)
        .then((data) => {
          const model_array = data;
          if (model_array === null) {
            this.setState({
              dataCleaned: false,
              model_array: data,
            });
          } else {
            const totalNumberOfModels = Object.values(model_array).length;
            const totalNumberOfModelsTrained = Object.values(model_array)
              .map(
                (elt) =>
                  elt.status.status === 'TRAINED' ||
                  elt.status.status === 'FAILURE'
              )
              .filter((v) => v).length;
            const trainedModelIds = Object.keys(data).filter(
              (k) => data[k].status.status === 'TRAINED'
            );
            const trainedModels = trainedModelIds.reduce(function (a, b) {
              a[b] = data[b];
              return a;
            }, {});
            if (
              totalNumberOfModels > 0 &&
              totalNumberOfModels === totalNumberOfModelsTrained
            ) {
              this.setState({
                model_array: trainedModels,
                dataCleaned: true,
                modelsTrained: totalNumberOfModelsTrained,
                numberOfModels: totalNumberOfModels,
                allTrainingDone: true,
              });
            } else {
              this.setState({
                model_array: data,
                dataCleaned: true,
                modelsTrained: totalNumberOfModelsTrained,
                numberOfModels: totalNumberOfModels,
                allTrainingDone: false,
              });
            }
          }
        })
        .catch((error) => {
          this.props.receiveTrainingError(this.props.projectId, error);
        });
    }
  };

  componentDidMount() {
    this.fetchTrainingStatus();
    this.intervalId = setInterval(this.fetchTrainingStatus, 1000);
  }

  componentWillUnmount = () =>
    this.intervalId && clearInterval(this.intervalId);

  render() {
    console.log(this.props);

    if (this.state.modelsTrained === null) {
      return <Loading />;
    } else {
      const dataIngestedIcon = this.state.dataingested ? (
        <CheckIcon />
      ) : (
        <LoadingIcon />
      );
      const dataCleanedIcon = this.state.dataCleaned ? (
        <CheckIcon />
      ) : (
        <LoadingIcon />
      );
      var modelsTrainedIcon = null;
      var modelsTrainedText =
        this.state.numberOfModels > 0
          ? this.state.modelsTrained.toString() +
            '/' +
            this.state.numberOfModels.toString()
          : '';
      var lookingForModelsIcon =
        this.state.numberOfModels > 0 ? <CheckIcon /> : <LoadingIcon />;

      if (this.state.allTrainingDone) {
        return <Redirect to={`/projects/${this.props.projectId}/ml/report`} />;
      }

      if (this.state.modelsTrained === this.state.numberOfModels) {
        if (this.state.modelsTrained > 0) {
          modelsTrainedIcon = <CheckIcon />;
        }
      } else {
        modelsTrainedIcon = <LoadingIcon />;
      }

      return (
        <ModelsPageWrapper>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StatusRowWrapper>
              <StatusNameWrapper>Ingesting Data</StatusNameWrapper>
              <div>{dataIngestedIcon}</div>
            </StatusRowWrapper>
            <StatusRowWrapper>
              <StatusNameWrapper>Cleaning Data</StatusNameWrapper>
              <div>{dataCleanedIcon}</div>
            </StatusRowWrapper>
            <StatusRowWrapper>
              <StatusNameWrapper>
                Looking for candidate models
              </StatusNameWrapper>
              <div>{lookingForModelsIcon}</div>
            </StatusRowWrapper>
            <StatusRowWrapper>
              <StatusNameWrapper>Training Models</StatusNameWrapper>
              <div>
                {modelsTrainedText}
                {modelsTrainedIcon}
              </div>
            </StatusRowWrapper>
          </div>
        </ModelsPageWrapper>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelingStatus);
