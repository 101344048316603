import { TYPES } from '../actions';

const training = (state = {}, action) => {
  switch (action.type) {
    case TYPES.TRAINING_DONE:
      return { ...state, model: action.modelId };

    case TYPES.RECEIVE_PREDICTION_TOKEN:
      return { ...state, predictionToken: action.predictionAPIToken };

    default:
      return state;
  }
};

export default training;
