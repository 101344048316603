import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Tooltip, Input, Form, InputNumber, Select, Spin } from 'antd';
import * as Actions from '../redux/actions';
import { get, mapValues, forEach, merge } from 'lodash';
import { getColumnNamesSortedByMostPredictive } from './Helpers';
import { themeColors } from '../colors/themeColors';
import { systemColors } from '../colors/systemColors';

const Panel = styled.div`
  padding: 20px;
  background-color: ${systemColors.background.fill};
  border: 1px solid #dbe4ec;
  border-radius: 5px;
  text-align: left;
  min-width: 360px;
`;

const Subtitle = styled.div`
  padding: 10px 0px;
`;

const ColumnInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
`;

const ColumnInputLabel = styled.div`
  font-weight: 400;
`;

const ColumnInputForXAxis = styled.div`
  color: ${themeColors.primary.base};
  cursor: default;
`;

const getXAxisValues = (data) => {
  return get(data, 'scenarios[0].x_y_values.x');
};

const highestNumberInScenarioNames = (scenarios) => {
  let highestNumber = scenarios.length;
  forEach(scenarios, (scenario) => {
    const prefix = 'Scenario #';
    if (scenario.name.startsWith(prefix)) {
      const numberPiece = parseInt(scenario.name.substring(prefix.length), 10);
      if (!isNaN(numberPiece) && highestNumber < numberPiece) {
        highestNumber = numberPiece;
      }
    }
  });

  return highestNumber;
};

const doesNameExistInScenarios = (scenarios, scenarioName) => {
  const nameMatches = scenarios.filter(
    (scenario) => scenario.name === scenarioName
  );
  return nameMatches.length !== 0;
};

const columnNameIsController = (columnName) =>
  columnName === 'ASIN' || columnName === 'Category';

export default ({
  data,
  targetColumn = 'the outcome',
  projectId,
  modelId,
  simulationId,
  user,
  updateDataWithNewScenario,
}) => {
  const predictiveColumns = getColumnNamesSortedByMostPredictive(
    data.feature_importance
  );
  const lineCount = highestNumberInScenarioNames(data.scenarios);
  const columnMetadata = data.column_metadata;
  const [loading, setLoading] = useState(false);
  const [scenarioName, setScenarioName] = useState(
    `Scenario #${lineCount + 1}`
  );

  const scenarioCount = get(data, 'scenarios.length');

  useEffect(() => {
    setScenarioName(`Scenario #${lineCount + 1}`);
  }, [lineCount, scenarioCount]);

  const defaultColumnData = mapValues(
    data.column_metadata,
    (col, columnName) => {
      if (columnNameIsController(columnName)) return undefined;
      else return col.default_value;
    }
  );
  const [columnData, setColumnData] = useState(defaultColumnData);
  const xAxisColumn = get(data, 'metadata.x_axis_column');

  const addScenario = () => {
    setLoading(true);
    fetch(`${Actions.API_PREFIX}/simulator/addScenario`, {
      method: 'POST',
      body: JSON.stringify({
        project_id: projectId,
        model_id: modelId,
        simulation_id: simulationId,
        scenario_name: scenarioName,
        x_axis_column: xAxisColumn,
        x_axis_values: getXAxisValues(data),
        column_values: JSON.stringify(columnData),
      }),
      headers: {
        ...Actions.getAuthHeaders(user),
        'Content-Type': 'application/json',
      },
    })
      .then(Actions.checkResponseSuccess)
      .then((data) => {
        const scenarioObject = {
          name: scenarioName,
          simulationId,
          id: data.id,
          column_values: columnData,
          x_y_values: { x: data.x, y: data.y },
        };
        updateDataWithNewScenario(scenarioObject);
        setLoading(false);
      })
      .catch((error) => {
        console.log('Add Scenario Error:', error);
        setLoading(false);
      });
  };

  const getDefaultValues = (column_name, value) => {
    setLoading(true);
    const authHeaders = Actions.getAuthHeaders(user);
    fetch(
      `${Actions.API_PREFIX}/simulator/defaultValues?column_name=${column_name}&value=${value}&simulation_id=${simulationId}`,
      {
        method: 'GET',
        headers: {
          ...authHeaders,
        },
      }
    )
      .then(Actions.checkResponseSuccess)
      .then((data) => {
        setLoading(false);
        setColumnData(merge(columnData, { ...data, [column_name]: value }));
      })
      .catch((error) => {
        setLoading(false);
        console.error(`Error fetching simulation id ${simulationId}`, error);
      });
  };

  const columnDataChanged = (columnName, value) => {
    setColumnData({
      ...columnData,
      [columnName]: value,
    });

    if (columnNameIsController(columnName)) {
      getDefaultValues(columnName, value);
    }
  };

  const getColumnInput = (columnName) => {
    let input;
    const columnType = columnMetadata[columnName].type;

    if (columnName === xAxisColumn) {
      input = <ColumnInputForXAxis>x-axis</ColumnInputForXAxis>;
    } else if (columnType === 'continuous') {
      const { min, max } = columnMetadata[columnName];
      const defaultValue = columnData[columnName];

      input = (
        <Tooltip placement="right" title={`Min: ${min}, Max: ${max}`}>
          <InputNumber
            min={min}
            max={max}
            value={defaultValue}
            onChange={(value) => columnDataChanged(columnName, value)}
          />
        </Tooltip>
      );
    } else if (columnType === 'categorical') {
      const { options } = columnMetadata[columnName];
      const defaultValue = columnData[columnName];

      input = (
        <Select
          value={defaultValue}
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          style={{ minWidth: 120 }}
          onChange={(value) => columnDataChanged(columnName, value)}
        >
          {options.map((value) => (
            <Select.Option key={value} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      );
    }

    return (
      <ColumnInput key={columnName}>
        <ColumnInputLabel>{columnName}</ColumnInputLabel>
        {input}
      </ColumnInput>
    );
  };

  const nameExistsAlready = doesNameExistInScenarios(
    data.scenarios,
    scenarioName
  );
  let validateStatus = 'success';
  let helpMessage = '';
  if (nameExistsAlready) {
    validateStatus = 'error';
    helpMessage = 'Scenario name already exists.';
  }

  const inputHeader = (
    <Form layout="inline">
      <Form.Item
        hasFeedback={nameExistsAlready}
        validateStatus={validateStatus}
        help={helpMessage}
      >
        <Input
          placeholder="Scenario name..."
          onChange={(e) => setScenarioName(e.target.value)}
          value={scenarioName}
        />
      </Form.Item>
      <Form.Item style={{ marginRight: 0 }}>
        <Button
          type="primary"
          disabled={scenarioName.length === 0 || nameExistsAlready}
          onClick={addScenario}
        >
          Add Scenario
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Spin spinning={loading} tip="Loading...">
      <Panel>
        {inputHeader}
        <Subtitle>
          Change the values and see how {targetColumn} changes.
        </Subtitle>
        <div>
          {predictiveColumns.map((columnName) => getColumnInput(columnName))}
        </div>
      </Panel>
    </Spin>
  );
};
