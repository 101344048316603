import React, { useState, useEffect, useCallback } from 'react';
import useAuthHeaders from '../redux/authHeadersHook';
import { API_PREFIX, checkResponseSuccess } from '../redux/actions';
import PipelineView, {
  NON_TRANSFORM_STEPS,
} from '../pipeline-components/PipelineView';
import TransformConfigView from '../pipeline-components/TransformConfigView';
import styled from 'styled-components';
import TransformDataPreview from '../pipeline-components/TransformDataPreview';
import { get, unset } from 'lodash';
import { PercentageOutlined } from '@ant-design/icons';
import FetchPipeline from '../pipeline-components/pipeline-calls/FetchPipeline';
import CreateNewPipeline from '../pipeline-components/pipeline-calls/CreateNewPipeline';
import CreateClonedPipeline from '../pipeline-components/pipeline-calls/CreateClonedPipeline';
import GetTransformForm from '../pipeline-components/pipeline-calls/GetTransformForm';
import ApplyNewTransform from '../pipeline-components/pipeline-calls/ApplyNewTransform';
import DeleteTransform from '../pipeline-components/pipeline-calls/DeleteTransform';
import ModifyTransform from '../pipeline-components/pipeline-calls/ModifyTransform';
import GetAllTransforms from '../pipeline-components/pipeline-calls/GetAllTransforms';
import { Spin } from 'antd';
import PipelineMainContent from '../pipeline-components/PipelineMainContent';

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: grid;
  grid-template-columns: 315px [middle-separator] 1fr [end];
  grid-template-rows: 1fr [middle-separator] 450px [end];
`;

const PipelineViewGridWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: span middle-separator;
  grid-row-start: 1;
  grid-row-end: span end;
`;

const TransformConfigViewGridWrapper = styled.div`
  grid-column-start: middle-separator;
  grid-column-end: span end;
  grid-row-start: 1;
  grid-row-end: span middle-separator;
  overflow: auto;
`;

const TransformDataPreviewGridWrapper = styled.div`
  grid-column-start: middle-separator;
  grid-column-end: span end;
  grid-row-start: ${(p) => (p.expand ? '1' : 'middle-separator')};
  grid-row-end: span end;
`;

export default ({ dataSetName, pipelineInUse, projectId }) => {
  const [beforeData, setBeforeData] = useState();
  const [afterData, setAfterData] = useState();
  const [pipelineError, setPipelineError] = useState();
  const [pipelineData, setPipelineData] = useState();
  const [selectedStep, setSelectedStep] = useState(
    NON_TRANSFORM_STEPS.applyPipeline
  );
  const [allTransformsList, setAllTransformsList] = useState([]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const authHeaders = useAuthHeaders();

  const getPipelineStateContext = useCallback(() => {
    return {
      projectId,
      beforeData,
      afterData,
      pipelineError,
      pipelineData,
      selectedStep,
      allTransformsList,
      isFormLoading,
      isLoading,
    };
  }, [
    projectId,
    beforeData,
    afterData,
    pipelineError,
    pipelineData,
    selectedStep,
    allTransformsList,
    isFormLoading,
    isLoading,
  ]);

  const getPipelineSetters = useCallback(() => {
    return {
      setBeforeData,
      setAfterData,
      setPipelineError,
      setPipelineData,
      setSelectedStep,
      setAllTransformsList,
      setIsFormLoading,
      setIsLoading,
    };
  }, [
    setBeforeData,
    setAfterData,
    setPipelineError,
    setPipelineData,
    setSelectedStep,
    setAllTransformsList,
    setIsFormLoading,
    setIsLoading,
  ]);

  const getPipelineContext = useCallback(() => {
    return {
      state: getPipelineStateContext(),
      setters: getPipelineSetters(),
      network: {
        API_PREFIX,
        authHeaders,
        checkResponseSuccess,
      },
    };
  }, [getPipelineStateContext, getPipelineSetters, authHeaders]);

  useEffect(() => {
    GetAllTransforms(getPipelineContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    FetchPipeline(getPipelineContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      pipelineData === null ||
      selectedStep === NON_TRANSFORM_STEPS.applyPipeline
    )
      return;

    let currentStep = selectedStep;
    const pipelineLength = pipelineData.length;
    if (selectedStep >= pipelineLength) {
      currentStep =
        pipelineLength > 0 ? pipelineLength - 1 : NON_TRANSFORM_STEPS.before;
      setSelectedStep(currentStep);
    }

    ensureFormAndDataAreLoadedForStep(currentStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineData]);

  const clearDirtyOptionsForStep = (stepIndex) => {
    const dataCopy = [...pipelineData];
    const stepData = { ...get(dataCopy, stepIndex, {}) };
    unset(stepData, 'dirtyOptions');
    dataCopy[stepIndex] = stepData;
    setPipelineData(dataCopy);
  };

  const setDirtyOptionsForStep = (stepIndex, dirtyOptions) => {
    const dataCopy = [...pipelineData];
    const stepData = get(dataCopy, stepIndex, {});
    dataCopy[stepIndex] = { ...stepData, dirtyOptions };
    setPipelineData(dataCopy);
  };

  const ensureFormAndDataAreLoadedForStep = (stepIndex) => {
    if (!NON_TRANSFORM_STEPS[stepIndex]) {
      const transform = getTransformAtIndex(stepIndex);
      if (!stepFormExistsLocally(stepIndex)) {
        GetTransformForm(stepIndex, transform.id, getPipelineContext);
      }
    }
  };
  const stepFormExistsLocally = (stepIndex) =>
    get(pipelineData[stepIndex], 'form') !== undefined;

  const addDraftTransform = (position, transformId, module, transform_name) => {
    setPipelineData((p) => {
      const dataCopy = [...p];
      dataCopy.splice(position, 0, {
        isDraft: true,
        draftTransformId: transformId,
        module,
        transform_name,
        options: {},
      });
      return dataCopy;
    });
    setSelectedStep(position);
  };

  const transformMap = {};
  const getTransformKey = (t) => (t ? t.module + t.transform_name : undefined);
  allTransformsList.forEach((t) => {
    transformMap[getTransformKey(t)] = t;
  });

  const getTransformAtIndex = (index) => {
    const stepData = pipelineData[index];
    return transformMap[getTransformKey(stepData)];
  };

  const onStepSelect = (stepIndex) => {
    setSelectedStep(stepIndex);
    ensureFormAndDataAreLoadedForStep(stepIndex);
  };

  let transformEditor;
  let displayStep = selectedStep;
  if (pipelineData) {
    if (selectedStep >= pipelineData.length) {
      displayStep = pipelineData.length - 1;
    }
    if (displayStep < 0) {
      displayStep = NON_TRANSFORM_STEPS.before;
    }
  }

  const dataSetStepSelected = NON_TRANSFORM_STEPS[displayStep] !== undefined;
  if (selectedStep !== NON_TRANSFORM_STEPS.applyPipeline) {
    let currentDataSetStepData;
    let currentBeforeData;
    let currentAfterData;
    let configView;
    let stepIsDraft = false;
    if (dataSetStepSelected) {
      currentDataSetStepData =
        NON_TRANSFORM_STEPS[displayStep] === NON_TRANSFORM_STEPS.before
          ? beforeData
          : afterData;
    } else {
      const selectedStepData = pipelineData[displayStep];
      currentBeforeData = selectedStepData.beforeData;
      currentAfterData = selectedStepData.afterData;
      stepIsDraft = selectedStepData.isDraft;
      let selectedTransform = transformMap[getTransformKey(selectedStepData)];
      configView = (
        <TransformConfigViewGridWrapper>
          <TransformConfigView
            pipelineInUse={pipelineInUse}
            icon={<PercentageOutlined />}
            formData={selectedStepData.options}
            isLoading={isFormLoading}
            onApplyNewTransform={ApplyNewTransform.bind(
              this,
              getPipelineContext
            )}
            onModifyTransform={ModifyTransform.bind(this, getPipelineContext)}
            onDeleteTransform={DeleteTransform.bind(this, getPipelineContext)}
            setDirtyOptionsForStep={setDirtyOptionsForStep}
            clearDirtyOptionsForStep={clearDirtyOptionsForStep}
            stepIndex={displayStep}
            stepData={selectedStepData}
            transform={selectedTransform}
            pipelineError={pipelineError}
          />
        </TransformConfigViewGridWrapper>
      );
    }

    transformEditor = (
      <>
        {configView}
        <TransformDataPreviewGridWrapper expand={dataSetStepSelected}>
          <TransformDataPreview
            fetchDataExportFromServer
            selectedStep={selectedStep}
            projectId={projectId}
            beforeData={currentBeforeData}
            afterData={currentAfterData}
            onlyData={currentDataSetStepData}
            isLoading={isFormLoading || isLoading}
            transformIsDraft={stepIsDraft}
          />
        </TransformDataPreviewGridWrapper>
      </>
    );
  }
  const aPipelineExists = pipelineData !== null && beforeData;
  return (
    <Spin spinning={isLoading} wrapperClassName="pipeline-spin-wrapper">
      <PageContainer>
        <PipelineViewGridWrapper>
          <PipelineView
            projectId={projectId}
            dataSetName={dataSetName}
            pipelineError={pipelineError}
            steps={pipelineData}
            selectedStep={displayStep}
            setSelectedStep={onStepSelect}
            transformMap={transformMap}
            addDraftTransform={addDraftTransform}
            allTransformsList={allTransformsList}
            pipelineInUse={pipelineInUse}
            aPipelineExists={aPipelineExists}
          />
        </PipelineViewGridWrapper>
        <PipelineMainContent
          pipelineInUse={pipelineInUse}
          transformEditor={transformEditor}
          selectedStep={selectedStep}
          pipelineData={pipelineData}
          beforeData={beforeData}
          projectId={projectId}
          aPipelineExists={aPipelineExists}
          createNewPipeline={CreateNewPipeline.bind(this, getPipelineContext)}
          createClonedPipeline={CreateClonedPipeline.bind(
            this,
            getPipelineContext,
            FetchPipeline
          )}
        />
      </PageContainer>
    </Spin>
  );
};
