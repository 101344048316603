import React from 'react';
import styled from 'styled-components';
import { getPricingRate } from './SubscriptionUtils';
import { get } from 'lodash';
import { Button } from 'antd';
import PlanProsAndCons from './PlanProsAndCons';
import { themeColors } from '../colors/themeColors';

const PlanColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding: 25px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  width: 300px;

  :last-of-type {
    border-right: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
  }
`;

const EmphasizedPlanColumn = styled(PlanColumn)`
  background: #f9f1ff;
  border: 1px solid ${themeColors.secondary.selected.sunsetPink};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  margin-top: -15px;
  margin-bottom: -15px;
  padding: 39px 0px;
`;

const Title = styled.div`
  color: ${themeColors.secondary.selected.darker};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
`;

const PriceDisplay = styled.div`
  font-size: 21px;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-left-width: 0px;
  border-right-width: 0px;
  align-self: stretch;
  text-align: center;
  color: #000;
`;

const ActionButton = styled(Button)`
  margin-top: 20px;
`;

export default ({
  product,
  isEmphasized = false,
  onSelect,
  currentSubscription,
  actionButtonText,
  subscriptionPeriod,
}) => {
  const { id, name, custom_price, pros } = product;
  let Column = PlanColumn;
  if (isEmphasized) Column = EmphasizedPlanColumn;
  const isCurrentSubscription = get(currentSubscription, 'product_id') === id;
  const pricing = getPricingRate(product, subscriptionPeriod);

  let buttonText = actionButtonText;
  if (isCurrentSubscription) buttonText = 'Continue Subscription';
  else if (product.no_trial || get(product, 'feature_flags.galileo')) {
    buttonText = 'Start Subscription';
  }
  let priceText = `$${pricing / 100} / ${subscriptionPeriod}`;
  let actionView = (
    <ActionButton
      onClick={() => onSelect(product)}
      type="primary"
      size="large"
      className="btn-bright-purple"
    >
      {buttonText}
    </ActionButton>
  );
  if (custom_price) {
    priceText = 'Custom';
    actionView = (
      <ActionButton
        href={
          'https://calendly.com/intersect-labs/intersect-labs-machine-learning?utm_source=prophet&utm_medium=pricing_view'
        }
        target="_blank"
        type="primary"
        size="large"
        className="btn-bright-purple"
      >
        Talk to us
      </ActionButton>
    );
  }

  return (
    <Column key={id}>
      <Title>{name}</Title>
      <PriceDisplay>{priceText}</PriceDisplay>
      <PlanProsAndCons pros={pros} />
      {actionView}
    </Column>
  );
};
