import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { fromUnixTime, differenceInDays, formatDistanceStrict } from 'date-fns';
import PricingView from './PricingView';
import useCurrentSubscription from './CurrentSubscriptionHook';
import { PRICING_FLOWS } from './SubscriptionUtils';

const Banner = styled.div`
  padding: 10px;
`;

const Title = styled.div`
  font-weight: 600;
`;

const Status = styled.div`
  color: ${(p) => (p.expired ? '#FF0000' : '#595959')};
  margin-bottom: 10px;
`;

export default ({ style }) => {
  const [isPricingViewVisible, setIsPricingViewVisible] = useState(false);
  const currentSubscription = useCurrentSubscription();

  if (
    currentSubscription === undefined ||
    currentSubscription.id === null ||
    currentSubscription.amount_due === 0 ||
    currentSubscription.has_cc_on_file === true
  )
    return null;

  let content;
  if (currentSubscription.status === 'past_due') {
    content = <Status expired>Subscription Expired</Status>;
  } else if (currentSubscription.status === 'trialing') {
    const endDate = fromUnixTime(currentSubscription.current_period_end);
    const daysRemaining = differenceInDays(endDate, new Date());
    let isExpired = false;
    let statusText = `${formatDistanceStrict(endDate, Date.now(), {
      unit: 'day',
    })} left`;
    if (daysRemaining < 0) {
      statusText = 'Expired';
      isExpired = true;
    }

    content = (
      <>
        <Title>Free Trial</Title>
        <Status expired={isExpired}>{statusText}</Status>
      </>
    );
  } else {
    return null;
  }

  return (
    <Banner style={style}>
      {content}
      <Button
        size="small"
        type="primary"
        onClick={() => setIsPricingViewVisible(true)}
      >
        Add Credit Card
      </Button>
      <PricingView
        pricingFlow={PRICING_FLOWS.updateCreditCard}
        showModal={isPricingViewVisible}
        onClose={() => setIsPricingViewVisible(false)}
      />
    </Banner>
  );
};
