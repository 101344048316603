import React, { useState } from 'react';
import styled from 'styled-components';
import {
  BarChart,
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
  YAxis,
  XAxis,
  Tooltip,
  ReferenceArea,
} from 'recharts';
import { Menu, Dropdown, Button, Space, InputNumber } from 'antd';
import { DownOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { get, find } from 'lodash';
import ScenarioTable from './ScenarioTable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 30px;
`;

const YAxisLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 40px;
  margin-bottom: 10px;
`;

const YAxisLabel = styled.div`
  font-weight: 600;
  margin-right: 5px;
`;

const ZoomControls = styled(Space)``;

const Colors = [
  '#469CF8',
  '#D370F5',
  '#82CA9D',
  '#E2BA2A',
  '#EF7F7F',
  '#CCE04F',
  '#828282',
  '#4E9A6E',
  '#F2994A',
  '#92A4E4',
  '#B6CBC7',
  '#C28C00',
  '#C7C47E',
];

const getXAxisColumn = (data) => get(data, 'metadata.x_axis_column');

const getScenarioFromData = (data, scenarioName) => {
  return find(data.scenarios, (value) => {
    return value.name === scenarioName;
  });
};

/**
 * Give destination [{x: 1}, {x: 2}], key = 'y', and source=[3, 4]... you get [{x: 1, y: 3}, {x: 2, y:4}]
 * Destination and Source must be of the same size
 *  */

const mergeInWithKey = (destination, key, source) => {
  return destination.map((item, index) => {
    return { ...item, [key]: source[index] };
  });
};

const getCategoricalOutcomes = (data) => {
  const firstScenario = data.scenarios[0];
  const { y } = firstScenario.x_y_values;
  if (!Array.isArray(y) && typeof y === 'object' && y !== null) {
    return Object.keys(y);
  }

  return undefined;
};

const processData = (data, selectedOutcome) => {
  const x = get(data, 'scenarios[0].x_y_values.x', []);
  const xAxisColumn = getXAxisColumn(data);
  if (x.length === 0) return x;
  let processedData = x.map((xValue) => ({ [xAxisColumn]: xValue }));

  for (let i = 0; i < data.scenarios.length; i++) {
    const {
      x_y_values: { y },
      name,
    } = data.scenarios[i];
    if (selectedOutcome === undefined) {
      // If y is an array then just merge the values into the data piece array {x: xValue, y: yValue}
      processedData = mergeInWithKey(processedData, name, y);
    } else {
      // if y is an {} object, then merge each of the rows in separately
      const selectedResult = y[selectedOutcome];
      processedData = mergeInWithKey(processedData, name, selectedResult);
    }
  }

  return processedData;
};

export default ({
  data,
  columnsSortedByPredictiveness,
  simulationName,
  deleteScenario,
}) => {
  const categoricalOutcomes = getCategoricalOutcomes(data);
  const [selectedOutcome, setSelectedOutcome] = useState(
    categoricalOutcomes && categoricalOutcomes[0]
  );
  const processedData = processData(data, selectedOutcome);
  const [graphState, setGraphState] = useState({
    left: 'dataMin',
    right: 'dataMax',
    refAreaLeft: '',
    refAreaRight: '',
    top: 'dataMax+1',
    bottom: 'dataMin-1',
    top2: 'dataMax+20',
    bottom2: 'dataMin-20',
  });

  const zoom = () => {
    let { refAreaLeft, refAreaRight } = graphState;

    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      setGraphState((state) => ({
        ...state,
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight)
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    setGraphState({
      left: refAreaLeft,
      right: refAreaRight,
      refAreaLeft: '',
      refAreaRight: '',
    });
  };

  const zoomOut = () => {
    setGraphState({
      left: 'dataMin',
      right: 'dataMax',
      refAreaLeft: '',
      refAreaRight: '',
      top: 'dataMax+1',
      bottom: 'dataMin',
      top2: 'dataMax+50',
      bottom2: 'dataMin+50',
    });
  };

  const deleteScenarioViaName = (scenarioName) => {
    deleteScenario(getScenarioFromData(data, scenarioName).id);
  };

  const xAxisColumn = getXAxisColumn(data);

  let yAxisLabelValue = 'Probability of';
  let outcomeMenu = undefined;
  if (categoricalOutcomes === undefined) {
    yAxisLabelValue = 'Outcome'; //TODO replace
  } else {
    outcomeMenu = (
      <Menu
        selectable
        defaultSelectedKeys={[selectedOutcome]}
        onSelect={({ key }) => setSelectedOutcome(key)}
      >
        {categoricalOutcomes.map((outcome) => (
          <Menu.Item key={outcome}>{outcome}</Menu.Item>
        ))}
      </Menu>
    );
  }

  const yAxisLabel = (
    <YAxisLabelContainer>
      <YAxisLabel>{yAxisLabelValue}</YAxisLabel>
      {outcomeMenu && (
        <Dropdown overlay={outcomeMenu}>
          <Button>
            {selectedOutcome} <DownOutlined />
          </Button>
        </Dropdown>
      )}
    </YAxisLabelContainer>
  );

  const keys = data.scenarios.map((scenario) => scenario.name);
  let Chart, chartElements;
  const xAxisIsCategorical =
    data.column_metadata[xAxisColumn].type === 'categorical';
  if (xAxisIsCategorical) {
    Chart = BarChart;
    chartElements = keys.map((key, index) => (
      <Bar key={key} dataKey={key} fill={Colors[index % Colors.length]} />
    ));
  } else {
    Chart = LineChart;
    chartElements = keys.map((key, index) => (
      <Line
        key={key}
        type="monotone"
        dataKey={key}
        stroke={Colors[index % Colors.length]}
        strokeWidth={2}
        dot={false}
        activeDot={{ r: 6 }}
      />
    ));
    if (graphState.refAreaLeft && graphState.refAreaRight) {
      chartElements.push(
        <ReferenceArea
          x1={graphState.refAreaLeft}
          x2={graphState.refAreaRight}
          strokeOpacity={0.3}
          key="refArea"
        />
      );
    }
  }

  const onGraphMouseDown = (e) => {
    if (!xAxisIsCategorical && e !== null) {
      setGraphState((state) => ({ ...state, refAreaLeft: e.activeLabel }));
    }
  };

  const onGraphMouseMove = (e) => {
    if (!xAxisIsCategorical && e !== null && graphState.refAreaLeft) {
      setGraphState((state) => ({
        ...state,
        refAreaRight: e.activeLabel,
      }));
    }
  };

  const { left, right } = graphState;

  const formatValue = (value) =>
    typeof value === 'number' ? value.toFixed(2) : value;
  const yAxisDomain = categoricalOutcomes !== undefined ? [0, 1] : undefined;
  const tickFormatter = (val) => {
    if (!isNaN(val) && !Number.isInteger(val)) {
      return val.toFixed(2);
    }

    return val;
  };

  return (
    <Container>
      <Title>{simulationName}</Title>
      {!xAxisIsCategorical && (
        <ZoomControls>
          <span>Zoom:</span>
          <InputNumber
            value={graphState.left}
            onChange={(val) =>
              setGraphState((graphState) => ({ ...graphState, left: val }))
            }
          />
          <InputNumber
            value={graphState.right}
            onChange={(val) =>
              setGraphState((graphState) => ({ ...graphState, right: val }))
            }
          />
          <Button onClick={zoomOut} icon={<ZoomOutOutlined />}>
            Zoom Out
          </Button>
        </ZoomControls>
      )}
      {yAxisLabel}
      <ResponsiveContainer width="100%" height={500}>
        <Chart
          data={processedData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          onMouseDown={onGraphMouseDown}
          onMouseMove={onGraphMouseMove}
          onMouseUp={zoom}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            allowDataOverflow
            domain={[left, right]}
            interval={1}
            type={xAxisIsCategorical ? 'category' : 'number'}
            dataKey={xAxisColumn}
            label={{ value: xAxisColumn }}
            tickFormatter={tickFormatter}
            animationDuration={300}
          />
          <YAxis
            allowDataOverflow
            domain={yAxisDomain}
            animationDuration={300}
          />
          <Tooltip formatter={formatValue} labelFormatter={formatValue} />
          {chartElements}
        </Chart>
      </ResponsiveContainer>
      <ScenarioTable
        scenarios={data.scenarios}
        columnMetadata={data.column_metadata}
        columnsSortedByPredictiveness={columnsSortedByPredictiveness}
        deleteScenarioViaName={deleteScenarioViaName}
        colors={Colors}
      />
    </Container>
  );
};
