import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './redux/reducers';
import { LoadingOutlined } from '@ant-design/icons';
import Login from './pages/LoginPage';
import PasswordResetForm from './pages/PasswordResetForm';
import SignUp from './pages/SignUp';
import Home from './pages/HomePage';
import AuthTasks from './pages/AuthTasks';

import './App.css';
import UnauthedPaymentUpdate from './pages/UnauthedPaymentUpdate';
import DemoCallPage from './pages/DemoCallPage';
import UnauthedTee91UploadPage from './pages/UnauthedTee91UploadPage';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'data'], // only these will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  {},
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);
let persistor = persistStore(store);

// FYI Uncommenting this line will erase persisted redux store
// persistor.purge();

class App extends Component {
  // The component's Local state.
  state = {
    isSignedIn: undefined, // Local signed-in state.
  };

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    store.subscribe(() => {
      this.setState({
        isSignedIn: store.getState().user.accessToken !== undefined,
        intent: store.getState().user.intent,
        emailVerified: store.getState().user.email_verified,
        user: store.getState().user,
      });
    });
  }

  whatToShow() {
    if (window.location.pathname === '/_auth') {
      return <AuthTasks />;
    } else if (this.state.isSignedIn) {
      return <Home />;
    } else {
      if (this.state.intent === 'signup') {
        return <SignUp />;
      } else if (this.state.intent === 'password_reset') {
        return <PasswordResetForm />;
      } else {
        return <Login />;
      }
    }
  }

  UnauthedRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          !this.state.isSignedIn ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  };

  AuthedRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          this.state.isSignedIn ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  };

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={<LoadingOutlined />} persistor={persistor}>
          <Router>
            <div className="App">
              {window.location.pathname === '/_auth' ? (
                <AuthTasks />
              ) : (
                <Switch>
                  <this.UnauthedRoute
                    exact
                    path="/credit-card-form/:encryptedUserId"
                  >
                    <UnauthedPaymentUpdate />
                  </this.UnauthedRoute>
                  <this.UnauthedRoute path="/tee91">
                    <UnauthedTee91UploadPage />
                  </this.UnauthedRoute>
                  <this.UnauthedRoute path="/login">
                    <Login />
                  </this.UnauthedRoute>
                  <this.UnauthedRoute exact path="/join-us/:signupLink">
                    <SignUp />
                  </this.UnauthedRoute>
                  <this.UnauthedRoute path="/join-us/">
                    <SignUp />
                  </this.UnauthedRoute>
                  <this.UnauthedRoute path="/reset-password">
                    <PasswordResetForm />
                  </this.UnauthedRoute>
                  <Route path="/demo-call/:encryptedString">
                    <DemoCallPage />
                  </Route>
                  <this.AuthedRoute path="/">
                    <Home />
                  </this.AuthedRoute>
                </Switch>
              )}
            </div>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
