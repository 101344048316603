import firebase from 'firebase/app';
import 'firebase/auth';
import {
  API_PREFIX,
  getAuthHeadersWithRedux,
  checkResponseSuccess,
} from './actions';
import { notification } from 'antd';

export const TYPES = {
  RECEIVE_TOKEN: 'RECIEVE_TOKEN',
  LOGOUT: 'LOGOUT',
  LOGIN_START: 'LOGIN_START',
  LOGIN_END: 'LOGIN_END',
  LOGIN_INITIALIZED: 'LOGIN_INITIALIZED',
  LOGIN_INTENDED: 'LOGIN_INTENDED',
  SIGNUP_INTENDED: 'SIGNUP_INTENDED',
  EMAIL_VERIFICATION_NEEDED: 'EMAIL_VERIFICATION_NEEDED',
  EMAIL_VERIFIED: 'EMAIL_VERIFIED',
  PASSWORD_RESET: 'PASSWORD_RESET',
  RECEIVE_BASIC_INFO: 'RECEIVE_BASIC_INFO',
  RECEIVE_SUBSCRIPTION_INFO: 'RECEIVE_SUBSCRIPTION_INFO',
};

export const receiveToken = (accessToken, refreshToken) => {
  return {
    type: TYPES.RECEIVE_TOKEN,
    accessToken,
    refreshToken,
  };
};

export const letsResetPassword = () => {
  return {
    type: TYPES.PASSWORD_RESET,
  };
};

export const letsLogIn = () => {
  return {
    type: TYPES.LOGIN_INTENDED,
  };
};

export const letsSignUp = () => {
  return {
    type: TYPES.SIGNUP_INTENDED,
  };
};

export const needToVerifyEmail = () => {
  return {
    type: TYPES.EMAIL_VERIFICATION_NEEDED,
  };
};

export const emailVerified = () => {
  return {
    type: TYPES.EMAIL_VERIFIED,
  };
};

export const loginInitialize = () => {
  return {
    type: TYPES.LOGIN_INITIALIZED,
  };
};

export const loginStart = () => {
  return {
    type: TYPES.LOGIN_START,
  };
};

export const loginEnd = () => {
  return {
    type: TYPES.LOGIN_END,
  };
};

export const receiveBasicInfo = (userInfo) => {
  return {
    type: TYPES.RECEIVE_BASIC_INFO,
    name: userInfo.name,
    email: userInfo.email,
    id: userInfo.id,
    created_time: userInfo.created_time,
    subscription: userInfo.subscription,
    product: userInfo.product,
  };
};

export const markEmailVerified = (email) => async (dispatch) => {
  fetch(`${API_PREFIX}/user/verifyEmail`, {
    method: 'POST',
    body: JSON.stringify({
      email: email,
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
    .then((response) => {
      // do something
    })
    .catch((error) => {
      // do something
    });
};

export const fetchToken = (name, utm, product, forceRefresh = false) => async (
  dispatch
) => {
  dispatch(loginStart());
  let user = firebase.auth().currentUser;
  user
    .getIdToken(forceRefresh)
    .then(function (idToken) {
      fetch(`${API_PREFIX}/user/tokens`, {
        method: 'POST',
        body: JSON.stringify({
          firebase_token: idToken,
          name: name,
          email: user.email,
          user_id: user.uid,
          organization_id: user.organization_id,

          product: product,
          utm: utm,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      })
        .then(checkResponseSuccess)
        .then((data) => {
          dispatch(receiveBasicInfo(data.user));
          dispatch(
            receiveToken(data.tokens.access_token, data.tokens.refresh_token)
          );
          if (!user.emailVerified) {
            dispatch(needToVerifyEmail());
          } else if (user.emailVerified) {
            dispatch(emailVerified());
          }
        })
        .catch((error) => {
          dispatch(loginEnd());
          notification.error({
            message: "Couldn't log you in (bk001)",
            description:
              'Please check your internet connection or contact support at hello@intersectlabs.io.',
          });
        });
    })
    .catch(function (error) {
      // Handle error
      dispatch(loginInitialize());
      notification.error({
        message: "Couldn't log you in (fb001)",
        description:
          'Please check your internet connection or contact support at hello@intersectlabs.io.',
      });
    });
};

export const decryptSignupLink = (signupLink) => async (dispatch) => {
  fetch(`${API_PREFIX}/user/decrypt-signup-link/${signupLink}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      dispatch(receiveBasicInfo(data));
    });
};

export const fetchSubscriptionInfo = () => async (dispatch, getState) => {
  const authHeaders = getAuthHeadersWithRedux(dispatch, getState);
  if (authHeaders === undefined) return;

  fetch(`${API_PREFIX}/billing/subscription`, {
    method: 'GET',
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((subscription) => {
      dispatch({
        type: TYPES.RECEIVE_SUBSCRIPTION_INFO,
        subscription,
      });
    });
};

export const fetchDemoToken = (forceRefresh = false) => async (dispatch) => {
  dispatch(
    receiveToken(
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1NTI3ODA5NzMsIm5iZiI6MTU1Mjc4MDk3MywianRpIjoiMWI4NDJhNzUtZmI0ZS00Y2EzLTg3NWQtMGJjMWMwMjgxZDQ4IiwiZXhwIjoxNTg0MzE2OTczLCJpZGVudGl0eSI6Ijg5ODhkMDkxLWJmZmQtNGRmYi1iNTYzLWM1Y2VjNTVkOWMwOF93ZWIiLCJmcmVzaCI6ZmFsc2UsInR5cGUiOiJhY2Nlc3MiLCJ1c2VyX2NsYWltcyI6eyJhY2Nlc3MiOiJ3ZWIifX0.FbUZaaxsnYwDjO_C6y4W9jU6j7jZkNPTMHzWHrNzJBY',
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1NTI3NzY1OTYsIm5iZiI6MTU1Mjc3NjU5NiwianRpIjoiOTk1MWFlY2EtZWY4Zi00MjJhLWE1MWYtMDRlMWRmODEyM2Q1IiwiZXhwIjoxNTU1MzY4NTk2LCJpZGVudGl0eSI6Ijg5ODhkMDkxLWJmZmQtNGRmYi1iNTYzLWM1Y2VjNTVkOWMwOCIsInR5cGUiOiJyZWZyZXNoIn0.D0GFZx5m1SHMjffDIfrvrikw2jk8-e7DMBeUf4Z83jg'
    )
  );
};

export const logout = () => {
  firebase.auth().signOut();
  window.Intercom('shutdown');
  localStorage.clear();

  return {
    type: TYPES.LOGOUT,
  };
};
