import styled from 'styled-components';
import { Button } from 'antd';
import { themeColors } from '../colors/themeColors';

const AddTransformButton = styled(Button)`
  background-color: ${themeColors.secondary.dark};
  border-radius: 5px;
  cursor: pointer;
  transition: ${(p) => (p.selected ? 'none' : '0.2s background-color')};
  color: #ffffff;
  border: none;
  box-shadow: rgba(15, 15, 15, 0.2) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.2) 0px 2px 4px;

  :hover {
    background-color: ${themeColors.secondary.hover};
    color: #ffffff;
    border: none;
  }

  :active {
    background-color: ${themeColors.secondary.selected.base};
    color: #ffffff;
  }

  :focus {
    background-color: ${themeColors.secondary.hover};
    color: #ffffff;
  }

  :focus:hover {
    background-color: ${themeColors.secondary.hover};
    color: #ffffff;
  }
`;

export default AddTransformButton;
