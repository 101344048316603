import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input, Card, Button } from 'antd';
import IntersectLogo from '../images/Logo.svg';
import firebase from 'firebase/app';
import { fetchToken, loginStart, loginInitialize } from '../redux/authActions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from '../project-components/Loading';
import { themeColors } from '../colors/themeColors';
import { systemColors } from '../colors/systemColors';

var firebaseConfig = {
  apiKey: 'AIzaSyCcG3VHVrZhW_x1R1aZ-VV-UzP-eAn5pZw',
  authDomain: 'intersect-labs.firebaseapp.com',
  databaseURL: 'https://intersect-labs.firebaseio.com',
  projectId: 'intersect-labs',
  storageBucket: 'intersect-labs.appspot.com',
  messagingSenderId: '815566872801',
};
firebase.initializeApp(firebaseConfig);

const SubmitButton = styled(Button)`
  margin: 15px;
`;

const Logo = styled.img`
  margin-top: 50px;
`;

const LoginForm = styled(Card)`
  max-width: 100%;
  width: 400px;
  margin: auto;
  padding: 10px 30px;
  background: ${systemColors.background.fill};
  margin-top: 3%;
  border-radius: 4px;
  border: 1px solid ${systemColors.background.border};
`;

const StyledInput = styled(Input)`
  margin-bottom: 20px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 25px;
`;

const ForgotPassword = styled(Link)`
  margin: 10px;
  font-size: 14px;
  color: ${themeColors.primary.base};
  cursor: pointer;
  display: block;

  &:hover {
    text-decoration: underline;
  }
`;

const TCPP = styled.p`
  margin: 20px;
  font-size: 12px;
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: red;
  margin: 10px;
`;

const mapStateToProps = (state) => {
  return {
    loadingState: state.loading.login,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomToken: () => {
      dispatch(fetchToken());
    },
    setLoadingLoginStart: () => dispatch(loginStart()),
    setLoadingLoginInitialize: () => dispatch(loginInitialize()),
  };
};

const LoginPage = ({
  loadingState,
  fetchCustomToken,
  setLoadingLoginStart,
  setLoadingLoginInitialize,
}) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  useEffect(() => {
    setLoadingLoginInitialize();
  }, [setLoadingLoginInitialize]);

  useEffect(() => {
    setLoginError('');
  }, [emailAddress, password]);

  const onSubmit = () => {
    setLoadingLoginStart();
    firebase
      .auth()
      .signInWithEmailAndPassword(emailAddress, password)
      .then((firebaseUser) => {
        fetchCustomToken();
      })
      .catch((error) => {
        // Handle Errors here.
        setLoadingLoginInitialize();
        var errorCode = error.code;
        if (
          ['auth/wrong-password', 'auth/user-not-found'].includes(errorCode)
        ) {
          setLoginError('Either your password or email was incorrect');
        }
      });
  };

  return (
    <StyledDiv>
      <Logo src={IntersectLogo} title="Intersect Labs" />
      <LoginForm>
        <Title>Sign in</Title>
        <StyledInput
          placeholder="Email address"
          onChange={(e) => {
            setEmailAddress(e.target.value);
          }}
        />
        <StyledInput.Password
          placeholder="Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <ErrorText>{loginError}</ErrorText>
        <TCPP>
          By signing in, you confirm that you agree with the{' '}
          <a
            href="https://www.intersectlabs.io/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            href="https://www.intersectlabs.io/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </TCPP>
        {loadingState === 'started' ? (
          <Loading />
        ) : (
          <SubmitButton type="primary" onClick={onSubmit}>
            Log In
          </SubmitButton>
        )}
        <ForgotPassword to={`/reset-password`}>
          Forgot your password?
        </ForgotPassword>
      </LoginForm>
      <LoginForm>
        <Title>Haven't signed up yet?</Title>
        <Button
          href="https://calendly.com/intersect-labs/hello?utm_source=signup_button"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book Onboarding Call
        </Button>
      </LoginForm>
    </StyledDiv>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
