import React, { useState, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import ILDataTable from '../data-set-components/ILDataTable';

const DataTableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
  border: 1px solid #f0f0f0;
`;

const PredictionDataWrapper = styled.div`
  flex: 1 1 auto;
`;

const PredictionResultsWrapper = styled.div`
  width: ${(p) => p.width + 'px'};
`;

const defaultResultsColumnWidth = 200;

export default ({ predictionData, predictionResults, selectedRowState }) => {
  const firstGrid = useRef();
  const secondGrid = useRef();
  const [gridHoveredIndex, setGridHoveredIndex] = useState(-1);
  const [columnWidths, setColumnWidth] = useState(
    Array(predictionResults.length).fill(150)
  );
  const [resultsTableWidth, setResultsTableWidth] = useState(
    defaultResultsColumnWidth
  );

  useEffect(() => {
    setResultsTableWidth(columnWidths.reduce((a, b) => a + b, 0));
  }, [columnWidths]);

  const onPredictionDataScroll = useCallback(
    ({ scrollTop, scrollUpdateWasRequested }) => {
      if (!scrollUpdateWasRequested && secondGrid.current) {
        secondGrid.current.scrollTo({ scrollLeft: 0, scrollTop });
      }
    },
    [secondGrid]
  );

  const onPredictionResultsScroll = useCallback(
    ({ scrollTop, scrollUpdateWasRequested }) => {
      if (!scrollUpdateWasRequested && firstGrid.current) {
        firstGrid.current.scrollTo({ scrollTop });
      }
    },
    [firstGrid]
  );

  return (
    <DataTableWrapper>
      <PredictionResultsWrapper
        className="shadow-after"
        width={resultsTableWidth}
      >
        <ILDataTable
          className="selectable-data-table"
          useYellowTheme
          columns={predictionResults.columns}
          dataSource={predictionResults.values}
          onScroll={onPredictionResultsScroll}
          disableScroll
          defaultColumnWidth={defaultResultsColumnWidth}
          gridRef={secondGrid}
          columnWidthState={[columnWidths, setColumnWidth]}
          // onCellHoverChange={setGridHoveredIndex}
          hoveredRowState={[gridHoveredIndex, setGridHoveredIndex]}
          selectedRowState={selectedRowState}
        />
      </PredictionResultsWrapper>
      <PredictionDataWrapper>
        <ILDataTable
          className="selectable-data-table"
          columns={predictionData.columns}
          dataSource={predictionData.values}
          onScroll={onPredictionDataScroll}
          hoveredRowState={[gridHoveredIndex, setGridHoveredIndex]}
          gridRef={firstGrid}
          selectedRowState={selectedRowState}
        />
      </PredictionDataWrapper>
    </DataTableWrapper>
  );
};
