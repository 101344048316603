import React, { useEffect, useState } from 'react';
import IntersectLogo from '../images/Logo.svg';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Breadcrumb, Spin } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import StartSimulationQuestion from '../model-report-components/StartSimulationQuestion';
import AddScenarioToSimulation from '../model-report-components/Upstart_AddScenarioToSimulation';
import SimulationResults from '../model-report-components/SimulationResults';
import * as Actions from '../redux/actions';
import { get } from 'lodash';

const PageWrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Logo = styled.img``;

const BreadcrumbWrapper = styled.div`
  margin-left: 10px;
  margin-top: 20px;
`;

const SimulationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const AddLineColumn = styled.div`
  margin: 54px 10px;
  flex-grow: 1;
`;

const GraphColumn = styled.div`
  flex-grow: 1;
`;

const LoadingIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match.params.projectId;
  return {
    model: state.training,
    user: state.user,
    projectId: projectId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const SimulationPage = ({
  match: {
    params: { projectId, modelId, simulationId },
  },
  location,
  history,
  user,
}) => {
  const predictiveColumns = get(location, 'state.predictiveColumns', []).map(
    (column) => column.name
  );
  const projectName = get(location, 'state.projectName', 'Model Report');
  const simulationsList = get(location, 'state.simulations', []);
  const [simulationData, setSimulationData] = useState();
  const [loading, setLoading] = useState(
    simulationId !== 'new' ? 'Loading simulation...' : undefined
  );
  const [error, setError] = useState();

  useEffect(() => {
    if (simulationId === 'new') return;
    const authHeaders = Actions.getAuthHeaders(user);
    fetch(`${Actions.API_PREFIX}/simulator/${simulationId}`, {
      method: 'GET',
      headers: {
        ...authHeaders,
      },
    })
      .then(Actions.checkResponseSuccess)
      .then((data) => {
        // setIsLoading(false);
        setSimulationData(data);
        setLoading(undefined);
      })
      .catch((error) => {
        setLoading(undefined);
        setSimulationData('error');
        console.error(`Error fetching simulation id ${simulationId}`, error);
      });
  }, [projectId, modelId, simulationId, user]);

  const startSimulation = (newSimulationName, xAxisColumn) => {
    setLoading('Starting Simulation...');
    fetch(`${Actions.API_PREFIX}/simulator/create`, {
      method: 'POST',
      body: JSON.stringify({
        project_id: projectId,
        model_id: modelId,
        x_axis_column: xAxisColumn,
        simulation_name: newSimulationName,
      }),
      headers: {
        ...Actions.getAuthHeaders(user),
        'Content-Type': 'application/json',
      },
    })
      .then(Actions.checkResponseSuccess)
      .then((data) => {
        console.log('response of start simulation', data);
        setSimulationData(data);
        setLoading(undefined);
        history.replace(
          `/simulation/${projectId}/${modelId}/${data.metadata.id}`
        );
      })
      .catch((error) => {
        console.log('Start Simulation Error:', error.message);
        setError({ message: error.message, newSimulationName, xAxisColumn });
        // TODO handle error
        setLoading(undefined);
      });
  };

  const deleteScenario = (scenarioId) => {
    const updatedScenarios = simulationData.scenarios.filter(
      (scenario) => scenario.id !== scenarioId
    );
    setSimulationData({ ...simulationData, scenarios: updatedScenarios });
    fetch(`${Actions.API_PREFIX}/simulator/scenario/delete/${scenarioId}`, {
      method: 'POST',
      headers: {
        ...Actions.getAuthHeaders(user),
        'Content-Type': 'application/json',
      },
    })
      .then(Actions.checkResponseSuccess)
      .then((data) => {
        console.log('Deleted scenario:', scenarioId);
      })
      .catch((error) => {
        console.log('Start Simulation Error:', error);
        // TODO handle error
      });
  };

  const updateDataWithNewScenario = (scenario) => {
    setSimulationData({
      ...simulationData,
      scenarios: [...simulationData.scenarios, scenario],
    });
  };

  let content, simulationName;
  if (loading !== undefined) {
    content = (
      <LoadingIconWrapper>
        <Spin tip={loading} size={'large'} />
      </LoadingIconWrapper>
    );
    simulationName = loading;
  } else if (simulationData === 'error') {
    content = (
      <LoadingIconWrapper>
        There was an error. Please contact hello@intersectlabs.io
      </LoadingIconWrapper>
    );
  } else if (simulationId === 'new') {
    const dd = new Date();
    simulationName =
      'New Simulation' +
      ' ' +
      dd.getFullYear() +
      (dd.getMonth() + 1) +
      dd.getDate() +
      dd.getHours() +
      dd.getMinutes();
    content = (
      <StartSimulationQuestion
        savedSimulationName={simulationName}
        columns={predictiveColumns}
        simulationsList={simulationsList}
        startSimulation={startSimulation}
        error={error}
      />
    );
  } else if (simulationData !== undefined) {
    simulationName = get(simulationData, 'metadata.name');
    content = (
      <SimulationWrapper>
        <AddLineColumn>
          <AddScenarioToSimulation
            data={simulationData}
            user={user}
            projectId={projectId}
            modelId={modelId}
            simulationId={simulationId}
            updateDataWithNewScenario={updateDataWithNewScenario}
          />
        </AddLineColumn>
        <GraphColumn>
          <SimulationResults
            data={simulationData}
            simulationName={simulationName}
            deleteScenario={deleteScenario}
          />
        </GraphColumn>
      </SimulationWrapper>
    );
  }

  return (
    <PageWrapper>
      <Logo src={IntersectLogo} alt="Intersect Labs Logo" />
      <BreadcrumbWrapper>
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/projects/${projectId}/ml/report`}>
            <span>{projectName}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>UpstartWorks</Breadcrumb.Item>
          <Breadcrumb.Item>
            {simulationName || 'New Simulation'}
          </Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbWrapper>
      {content}
    </PageWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SimulationPage);
