import { get } from 'lodash';
import { useSelector } from 'react-redux';

const useCurrentSubscription = () => {
  const getSubscription = (state) => get(state, 'user.subscription');
  const currentSubscription = useSelector(getSubscription);

  return currentSubscription;
};

export default useCurrentSubscription;
