import React, { useState, useRef, useCallback, useEffect } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled from 'styled-components';
import { Resizable } from 'react-resizable';
import classNames from 'classnames';
import './DataTable.css';

const BASE_COL_WIDTH = 100;
const ROW_HEIGHT = 50;
const SCROLLBAR_SIZE = 15;
const MIN_COL_WIDTH = BASE_COL_WIDTH - SCROLLBAR_SIZE - 5;
const BASE_BG_COLOR = '#fafafa';

const DataTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const HeaderRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: ${ROW_HEIGHT};
  width: ${(p) => `${p.width}px`};
  background-color: ${(p) => p.bgColor};
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;

  margin-right: ${(p) => p.scrollbarSize + 'px'};
`;

const HeaderCell = (
  { name },
  columnIndex,
  columnWidths,
  getColumnWidth,
  handleResize
) => {
  const realWidth = columnWidths[columnIndex];
  const widthAdjusted = getColumnWidth(columnIndex);

  return (
    <Resizable
      width={realWidth}
      height={0}
      onResize={(e, data) => handleResize(columnIndex, e, data)}
      draggableOpts={{ enableUserSelectHack: false }}
      key={name}
    >
      <div
        className="virtual-table-cell virtual-table-cell-header"
        style={{
          minWidth: widthAdjusted,
          maxWidth: widthAdjusted,
          cursor: 'default',
          marginRight: columnIndex === columnWidths.length - 1 ? 20 : 0,
        }}
      >
        {name}
      </div>
    </Resizable>
  );
};

export default ({
  className,
  columns,
  dataSource,
  onScroll = () => {},
  disableScroll = false,
  hoveredRowState,
  selectedRowState,
  columnWidthState,
  useYellowTheme = false,
  gridRef,
  style = { backgroundColor: BASE_BG_COLOR },
  defaultColumnWidth = BASE_COL_WIDTH,
}) => {
  const [columnWidths, setColumnWidths] =
    columnWidthState ||
    useState(Array(columns.length).fill(defaultColumnWidth));
  const headerGridRef = useRef();
  const bodyGridRef = gridRef || useRef();
  const [hoveredRowIndex, setHoveredRowIndex] =
    hoveredRowState || useState(null);
  const [selectedRowIndex, setSelectedRowIndex] =
    selectedRowState || useState(null);

  const adjustedScrollbarSize = disableScroll ? 0 : SCROLLBAR_SIZE;

  const resetVirtualGrid = (shouldForceUpdate = false) => {
    if (bodyGridRef.current) {
      bodyGridRef.current.resetAfterIndices({
        columnIndex: 0,
        shouldForceUpdate,
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetVirtualGrid, []);
  useEffect(() => {
    const diff = columnWidths.length - columns.length;
    let newColWidths = [...columnWidths];
    if (diff < 0) {
      newColWidths = [
        ...newColWidths,
        ...Array(Math.abs(diff)).fill(defaultColumnWidth),
      ];
    } else if (diff > 0) {
      newColWidths.length = columns.length;
    }
    setColumnWidths(newColWidths);
    resetVirtualGrid(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const _onTableScroll = useCallback(
    ({ scrollLeft, scrollUpdateWasRequested }) => {
      if (!scrollUpdateWasRequested && headerGridRef.current) {
        headerGridRef.current.scrollLeft = scrollLeft;
      }
    },
    [headerGridRef]
  );

  const handleResize = useCallback(
    (index, e, data) => {
      const newWidth = Math.max(MIN_COL_WIDTH, data.size.width);
      setColumnWidths((columnWidths) => {
        const newColumnWidths = [...columnWidths];
        newColumnWidths[index] = newWidth;
        return newColumnWidths;
      });
      bodyGridRef.current.resetAfterColumnIndex(index, false);
    },
    [setColumnWidths, bodyGridRef]
  );

  const getColumnWidth = (index) => {
    const width = columnWidths[index];
    return index === columnWidths.length - 1
      ? width - adjustedScrollbarSize - 1
      : width;
  };

  const BodyCell = ({ columnIndex, rowIndex, style }) => {
    const value = dataSource[rowIndex][columnIndex];
    return (
      <div
        className={classNames('virtual-table-cell', {
          'hovered-cell': hoveredRowIndex === rowIndex,
          'selected-cell': selectedRowIndex === rowIndex,
        })}
        onMouseEnter={() => setHoveredRowIndex(rowIndex)}
        onClick={() => setSelectedRowIndex(rowIndex)}
        style={style}
        title={value}
      >
        {value}
      </div>
    );
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <DataTableWrapper
          key="data-table-wrapper"
          className="il-data-table virtual-table-wrapper"
          style={style}
          onMouseLeave={() => setHoveredRowIndex(-1)}
        >
          <HeaderRowWrapper
            width={width}
            bgColor={style.backgroundColor}
            ref={headerGridRef}
          >
            <HeaderRow
              className={classNames('grid-header-row', {
                'grid-header-row-yellow': useYellowTheme,
              })}
              scrollbarSize={adjustedScrollbarSize}
            >
              {columns.map((col, index) =>
                HeaderCell(
                  col,
                  index,
                  columnWidths,
                  getColumnWidth,
                  handleResize
                )
              )}
            </HeaderRow>
          </HeaderRowWrapper>
          <Grid
            ref={bodyGridRef}
            className={classNames(
              'virtual-grid',
              {
                'overflow-y-hidden': disableScroll,
                'virtual-grid-yellow': useYellowTheme,
              },
              className
            )}
            columnCount={columns.length}
            columnWidth={getColumnWidth}
            estimatedColumnWidth={defaultColumnWidth}
            rowCount={dataSource.length}
            rowHeight={() => ROW_HEIGHT}
            estimatedRowHeight={ROW_HEIGHT}
            height={height - ROW_HEIGHT}
            width={width}
            onScroll={(scrollAttrs) => {
              _onTableScroll(scrollAttrs);
              onScroll(scrollAttrs);
            }}
          >
            {BodyCell}
          </Grid>
        </DataTableWrapper>
      )}
    </AutoSizer>
  );
};
