import { TYPES } from '../actions';

const data = (state = {}, action) => {
  switch (action.type) {
    case TYPES.RECEIVE_DATA:
      return { ...state, [action.projectId]: action.data };
    case TYPES.TRAINING_STARTED:
      return {
        ...state,
        [action.projectId]: {
          ...state[action.projectId],
          training_started: true,
        },
      };
    case TYPES.TRAINING_DONE:
      return {
        ...state,
        [action.projectId]: {
          ...state[action.projectId],
          training_done: true,
        },
      };
    default:
      return state;
  }
};

export default data;
