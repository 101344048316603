import { API_PREFIX, checkResponseSuccess } from '../redux/actions';
import { exportCSVToBrowserDownloads } from '../pipeline-components/utilsCSV';
import { useState } from 'react';
import useAuthHeaders from '../redux/authHeadersHook';

const downloadDataSet = (dataSetId, setDataSetIsLoading, authHeaders) => {
  setDataSetIsLoading(true);
  fetch(`${API_PREFIX}/data/download-as-csv/${dataSetId}`, {
    method: 'GET',
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
  })
    .then(checkResponseSuccess)
    .then(({ csv, filename }) => {
      exportCSVToBrowserDownloads(csv, filename);
    })
    .finally(() => setDataSetIsLoading(false));
};

const downloadTransformedDataSet = (
  projectId,
  setDataSetIsLoading,
  authHeaders
) => {
  setDataSetIsLoading(true);
  fetch(
    `${API_PREFIX}/pasteur/full-transformed-dataset?project_id=${projectId}`,
    {
      method: 'GET',
      headers: {
        ...authHeaders,
      },
    }
  )
    .then(checkResponseSuccess)
    .then(({ data }) => {
      exportCSVToBrowserDownloads(data);
    })
    .finally(() => setDataSetIsLoading(false));
};

export const useDataSetDownloadCallback = () => {
  const [dataSetIsLoading, setDataSetIsLoading] = useState(false);
  const authHeaders = useAuthHeaders();

  return {
    downloadDataSet: (dataSetId) =>
      downloadDataSet(dataSetId, setDataSetIsLoading, authHeaders),
    downloadTransformedDataSet: (projectId) =>
      downloadTransformedDataSet(projectId, setDataSetIsLoading, authHeaders),
    dataSetIsLoading,
  };
};
