import { get } from 'lodash';
import { notification } from 'antd';

export default (getPipelineContext, options, position) => {
  const { network, state, setters } = getPipelineContext();

  setters.setIsLoading(true);
  fetch(`${network.API_PREFIX}/pasteur/pipeline/transform/modify`, {
    method: 'POST',
    body: JSON.stringify({
      project_id: state.projectId,
      position,
      options: JSON.stringify(options),
      // subset_start: data_set_subset_start?,
      // subset_end: data_set_subset_end?,
    }),
    headers: {
      ...network.authHeaders,
      'Content-Type': 'application/json',
    },
  })
    .then(network.checkResponseSuccess)
    .then(
      ({ before, after, pipeline, final, error, error_transform, message }) => {
        setters.setPipelineData((currentPipelineData) => {
          const oldStepData = get(currentPipelineData, position, {});
          const newStepData = get(pipeline, position, {});
          pipeline[position] = {
            ...newStepData,
            form: oldStepData.form,
            beforeData: before,
            afterData: after,
          };
          return pipeline;
        });
        if (error) {
          setters.setPipelineError({ error, error_transform, message });
        } else {
          setters.setPipelineError(undefined);
        }
        setters.setAfterData(final);
        setters.setIsLoading(false);
        console.log('data', before, after, pipeline, final);
      }
    )
    .catch((error) => {
      setters.setIsLoading(false);
      console.error(`Error fetching data list`, error);
      notification.error({
        message: "Couldn't modify transform",
        description:
          'Please check your internet connection or contact support at hello@intersectlabs.io',
      });
    });
};
