import { API_PREFIX, checkResponseSuccess } from '../redux/actions';
import { TYPES } from '../redux/authActions';
import { notification } from 'antd';

export const getProducts = (authHeaders, onComplete) => {
  fetch(`${API_PREFIX}/billing/products`, {
    method: 'GET',
    headers: {
      ...authHeaders,
    },
  })
    .then(checkResponseSuccess)
    .then((data) => onComplete(data))
    .catch((error) => {
      console.error(`Error fetching products in Pricing View`, error);
      notification.error({
        message: "Couldn't get subscription options",
        description:
          'Please check your internet connection or contact support at hello@intersectlabs.io',
      });
    });
};

export const beginSubscription = (
  price_id,
  payment_method,
  setIsLoading,
  authHeaders,
  dispatch,
  onComplete
) => {
  setIsLoading(true);
  fetch(`${API_PREFIX}/billing/subscription/begin`, {
    method: 'POST',
    body: JSON.stringify({ price_id, payment_method }),
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
  })
    .then(checkResponseSuccess)
    .then((subscription) => {
      console.log('return value of begin subscription', subscription);
      dispatch({
        type: TYPES.RECEIVE_SUBSCRIPTION_INFO,
        subscription: { ...subscription, id: subscription.subscription_id },
      });
      setIsLoading(false);
      onComplete();
    })
    .catch((error) => {
      console.error('Begin subscription error:', error);
      notification.error({
        message: "Couldn't Start Subscription",
        description:
          'Please check your internet connection or contact support at hello@intersectlabs.io',
      });
      setIsLoading(false);
    });
};

export const changeSubscription = (
  price_id,
  setIsLoading,
  authHeaders,
  dispatch,
  onComplete
) => {
  setIsLoading(true);
  fetch(`${API_PREFIX}/billing/subscription/change`, {
    method: 'POST',
    body: JSON.stringify({ price_id }),
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
  })
    .then(checkResponseSuccess)
    .then((subscription) => {
      dispatch({
        type: TYPES.RECEIVE_SUBSCRIPTION_INFO,
        subscription,
      });
      setIsLoading(false);
      onComplete();
    })
    .catch((error) => {
      console.error('Begin subscription error:', error);
      notification.error({
        message: "Couldn't Update Subscription",
        description:
          'Please check your internet connection or contact support at hello@intersectlabs.io',
      });
      setIsLoading(false);
    });
};

export const endSubscription = (setIsLoading, authHeaders, dispatch) => {
  setIsLoading(true);
  fetch(`${API_PREFIX}/billing/subscription/end`, {
    method: 'POST',
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
  })
    .then(checkResponseSuccess)
    .then((subscription) => {
      dispatch({
        type: TYPES.RECEIVE_SUBSCRIPTION_INFO,
        subscription: { amount_due: 0 },
      });
      setIsLoading(false);
    })
    .catch((error) => {
      console.error('Begin subscription error:', error);
      notification.error({
        message: "Couldn't Update Subscription",
        description:
          'Please check your internet connection or contact support at hello@intersectlabs.io',
      });
      setIsLoading(false);
    });
};

export const updateCard = (
  payment_method,
  setIsLoading,
  authHeaders,
  dispatch,
  onComplete
) => {
  setIsLoading(true);
  fetch(`${API_PREFIX}/billing/card/update`, {
    method: 'POST',
    body: JSON.stringify({ payment_method }),
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
  })
    .then(checkResponseSuccess)
    .then((updatedCustomer) => {
      dispatch({
        type: TYPES.RECEIVE_SUBSCRIPTION_INFO,
        subscription: { has_cc_on_file: true },
      });
      setIsLoading(false);
      onComplete();
    })
    .catch((error) => {
      setIsLoading(false);
      console.error('Begin subscription error:', error);
      if (error.message) {
        onComplete(error);
        return;
      }

      notification.error({
        message: "Couldn't Update Subscription",
        description:
          'Please check your internet connection or contact support at hello@intersectlabs.io',
      });
    });
};
