import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import IntersectLogo from './images/Logo.svg';
import LogoCircles from './images/LogoCircles.svg';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import * as AuthActions from './redux/authActions';
import { Drawer } from 'antd';
import FreeTrialBanner from './checkout-components/FreeTrialBanner';
import { themeColors } from './colors/themeColors';
import { systemColors } from './colors/systemColors';

const SidebarWrapper = styled.div`
  text-align: left;
  background-color: ${systemColors.background.fill};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Logo = styled.img`
  margin: 30px 45px 40px 18px;
`;

const Items = styled.div`
  padding-left: 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Item = styled.a.attrs((props) => ({
  activeStyle: {
    background: '#e9f4ff',
  },
}))`
  font-size: 14px;
  color: ${themeColors.primary.base};
  cursor: pointer;
  padding: 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const LinkItem = Item.withComponent(NavLink);

const FooterItems = styled.div`
  padding-left: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

const DrawerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 5px 5px 0px;
  overflow: hidden;
  height: 42px;
  width: 42px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 0px;
  z-index: 1000;
  background: #fbfbfb;
  border: 1px solid #eaeff2;
  transition: 0.3s all;

  :hover {
    border-bottom-color: ${themeColors.secondary.selected.base};
  }
`;

const FreeTrialBannerWrapper = styled.div`
  border: 1px solid #e9f4ff;
  background: #ffffff;
  margin-left: -30px;
  margin-bottom: 15px;
  border-left-width: 15px;
  align-self: flex-start;
  border-radius: 0px 4px 4px 0px;
  overflow: hidden;
`;

/**
 * Redux Helpers
 */
const mapStateToProps = (state, ownProps) => {
  return {
    projects: state.projects,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () => dispatch(AuthActions.logout()),
  };
};

const Sidebar = ({ logout, asDrawer }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    setDrawerVisible(false);
  }, [asDrawer]);

  let content = (
    <SidebarWrapper>
      <Logo src={IntersectLogo} alt="Intersect Labs" title="Intersect Labs" />
      <Items>
        <LinkItem exact to="/">
          Projects
        </LinkItem>
        <LinkItem to="/datasets">Data Sets</LinkItem>
      </Items>
      <FooterItems>
        <FreeTrialBannerWrapper>
          <FreeTrialBanner />
        </FreeTrialBannerWrapper>
        <LinkItem to="/settings">Settings</LinkItem>
        <Item onClick={logout}>Logout</Item>
      </FooterItems>
    </SidebarWrapper>
  );

  if (asDrawer)
    content = (
      <>
        <DrawerButton onClick={() => setDrawerVisible(true)}>
          <img src={LogoCircles} alt="Intersect Labs Home" />
        </DrawerButton>
        <Drawer
          bodyStyle={{
            padding: 0,
          }}
          placement="left"
          closable={true}
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
        >
          {content}
        </Drawer>
      </>
    );

  return content;
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
