import React from 'react';
import styled from 'styled-components';
import { Alert, Button, Spin } from 'antd';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import Form from 'react-jsonschema-form';
import {
  ArrayFieldTemplate,
  CustomFieldTemplate,
  fields,
  widgets,
} from './transform-components/TransformConfigFormComponents';
import PurpleButton from './PurpleButton';
import { themeColors } from '../colors/themeColors';

const ViewWrapper = styled.div`
  background-color: #fafaff;
  height: 100%;
  width: 100%;
  padding: 30px 45px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  color: ${themeColors.secondary.dark};
  font-size: 16px;
  font-weight: 600;
`;

const ErrorAlert = styled(Alert)`
  margin-top: 30px !important;
  margin-bottom: 30px !important;
`;

const PipelineInUseAlert = styled(Alert)`
  margin-top: 20px !important;
  max-width: 30vw;
`;

const ConfigForm = styled(Form)`
  text-align: left;
  padding-bottom: 30px !important;
`;

const SpinContainer = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ActionButton = styled(Button)`
  margin-left: 15px;
`;

const TransformAppliedActionAlert = styled.div`
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: 5px 10px;
  color: #444;
  display: inline-block;
  cursor: default;

  > span {
    color: ${themeColors.secondary.selected.base};
    margin-right: 5px;
  }
`;

const TransformErrorActionAlert = styled(TransformAppliedActionAlert)`
  border: 1px solid #444;
  color: #444;
  background-color: #fff;

  > span {
    color: #f5222d;
  }
`;

export default ({
  renderTitle = true,
  isPreview,
  defaultSchema = {},
  stepData = {},
  stepIndex,
  transform = {},
  formData,
  onApplyNewTransform,
  onModifyTransform,
  onDeleteTransform,
  setDirtyOptionsForStep = () => {},
  clearDirtyOptionsForStep,
  isLoading,
  pipelineError,
  pipelineInUse = false,
}) => {
  const titleRow = (
    <Row>
      <span>{transform.public_name || defaultSchema.title}</span>
    </Row>
  );

  let errorView;
  let upstreamError = false;
  let currentStepHasError = false;

  if (pipelineError) {
    currentStepHasError = pipelineError.error_transform === stepIndex;
    if (currentStepHasError) {
      errorView = (
        <ErrorAlert
          message="Can't Apply Transform"
          description={`There's an error with this transform: ${pipelineError.error} ${pipelineError.message}`}
          type="error"
          showIcon
        />
      );
    } else if (pipelineError.error_transform < stepIndex) {
      upstreamError = true;
      errorView = (
        <ErrorAlert
          message="A previous transform is failing"
          description="This transform can't be configured or applied until the previous transform is fixed. Please fix the previous transform."
          type="warning"
          showIcon
        />
      );
    }
  }

  const hasBeenApplied = stepData.afterData !== null;
  const hasDirtyOptions = stepData.dirtyOptions !== undefined;

  let onFormSubmit;
  let actionButtons;
  if (stepData.isDraft || isPreview) {
    onFormSubmit = onApplyNewTransform;
    actionButtons = (
      <PurpleButton htmlType="submit" type="primary">
        Apply Transform
      </PurpleButton>
    );
  } else if (currentStepHasError && !hasDirtyOptions) {
    actionButtons = (
      <TransformErrorActionAlert>
        <CloseCircleFilled />
        Can't Apply Transform
      </TransformErrorActionAlert>
    );
  } else if (pipelineInUse || (hasBeenApplied && !hasDirtyOptions)) {
    actionButtons = (
      <TransformAppliedActionAlert>
        <CheckCircleFilled /> Transform Applied
      </TransformAppliedActionAlert>
    );
  } else if ((hasBeenApplied || currentStepHasError) && hasDirtyOptions) {
    onFormSubmit = onModifyTransform;
    actionButtons = [
      <PurpleButton key="modify-btn" htmlType="submit" type="primary">
        Apply Transform
      </PurpleButton>,
      <ActionButton
        key="cancel-btn"
        onClick={() => clearDirtyOptionsForStep(stepIndex)}
      >
        Cancel Modifications
      </ActionButton>,
    ];
  }

  const formSchema = stepData.form || defaultSchema;

  return (
    <ViewWrapper>
      {renderTitle && titleRow}
      {errorView}
      {isLoading ? (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      ) : (
        !upstreamError && (
          <ConfigForm
            className="ant-form ant-form-vertical"
            schema={formSchema}
            widgets={widgets}
            fields={fields}
            FieldTemplate={CustomFieldTemplate}
            ArrayFieldTemplate={ArrayFieldTemplate}
            showErrorList={false}
            formData={stepData.dirtyOptions || formData}
            onSubmit={({ formData }) =>
              onFormSubmit(formData, stepIndex, transform.id)
            }
            onChange={({ formData }) =>
              setDirtyOptionsForStep(stepIndex, formData)
            }
          >
            {actionButtons}
            {!isPreview && !pipelineInUse && (
              <ActionButton
                key="delete-btn"
                onClick={() => onDeleteTransform(stepIndex)}
              >
                Delete Transform
              </ActionButton>
            )}
            {pipelineInUse && (
              <PipelineInUseAlert
                message="Pipeline In Use"
                description="This pipeline can't be changed because this project's machine learning model is using it. Please create a new project and clone this pipeline to change it."
                type="info"
                showIcon
              />
            )}
          </ConfigForm>
        )
      )}
    </ViewWrapper>
  );
};
