import React from 'react';
import ApplyPipelineView from './ApplyPipelineView';
import AddPipelineView from './AddPipelineView';
import styled from 'styled-components';
import { NON_TRANSFORM_STEPS } from './PipelineView';

const MainContentGridWrapper = styled.div`
  grid-column-start: middle-separator;
  grid-column-end: span end;
  grid-row-start: 1;
  grid-row-end: span end;
`;

export default ({
  pipelineInUse,
  transformEditor,
  selectedStep,
  pipelineData,
  beforeData,
  projectId,
  aPipelineExists,
  createNewPipeline,
  createClonedPipeline,
}) => {
  let mainContent = transformEditor;

  if (selectedStep === NON_TRANSFORM_STEPS.applyPipeline) {
    if (aPipelineExists) {
      const requiredColumns = beforeData.columns.map((c) => c.name);
      mainContent = (
        <MainContentGridWrapper>
          <ApplyPipelineView
            projectId={projectId}
            requiredColumns={requiredColumns}
          />
        </MainContentGridWrapper>
      );
    } else {
      mainContent = (
        <MainContentGridWrapper>
          <AddPipelineView
            pipelineInUse={pipelineInUse}
            createNewPipeline={createNewPipeline}
            createClonedPipeline={createClonedPipeline}
            projectId={projectId}
          />
        </MainContentGridWrapper>
      );
    }
  }

  return mainContent;
};
