import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import ILDataTable from '../data-set-components/ILDataTable';
import DownloadTransformedDataButton from './DownloadTransformedDataButton';
import { NON_TRANSFORM_STEPS } from './PipelineView';
import { themeColors } from '../colors/themeColors';

const ViewContainer = styled.div`
  border-top: 1px solid #eaeff2;
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 315px);
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eaeff2;
  flex-basis: 50px;
  background-color: #ffffff;
`;

const HeaderTitle = styled.div`
  font-weight: bold;
  color: #a4a3b2;
  margin-right: 20px;
`;

const RadioSelectedBorderColor = '#B285DA';

const RadioButton = styled.div`
  border: 1px solid
    ${(p) => (p.selected ? RadioSelectedBorderColor : '#eaeff2')};
  background-color: ${(p) => (p.selected ? '#F4E7FF' : '#FFFFFF')};
  padding: 3px 15px;
  color: ${themeColors.secondary.dark};
  cursor: pointer;
`;

const BeforeButton = styled(RadioButton)`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right-color: ${(p) =>
    p.selected ? RadioSelectedBorderColor : '#FFF'};
`;

const AfterButton = styled(RadioButton)`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left-color: ${(p) => (p.selected ? RadioSelectedBorderColor : '#FFF')};
`;

const DataTableHolder = styled.div`
  height: 100%;
`;

const EmptyDataGrid = styled.div`
  background: url(${require('../images/EmptyDataSetGrid.png')}) repeat-x;
  background-size: auto 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyDataMessage = styled.div`
  background: #fbfbfb;
  border: 1px solid #eaeff2;
  border-radius: 4px;
  padding: 20px 50px;
  font-weight: 600;
  font-size: 14px;
  color: ${themeColors.secondary.dark};
  cursor: default;
`;

const TransformDataPreview = ({
  onlyData,
  isLoading,
  allowDataDownload = false,
  selectedStep = null,
  beforeData = null,
  afterData = null,
  fetchDataExportFromServer = false,
  projectId = null,
  transformIsDraft = false,
}) => {
  const [beforeSelected, setBeforeSelected] = useState(true);

  useEffect(() => {
    if (afterData) {
      setBeforeSelected(false);
    }
  }, [afterData]);

  let selectedData;
  if (onlyData) {
    selectedData = onlyData;
  } else if (beforeSelected) {
    selectedData = beforeData;
  } else if (!transformIsDraft) {
    selectedData = afterData;
  }
  let emptyText = 'Apply the transform to preview results.';
  const beforeSelectedButNoData = beforeSelected || !beforeData;
  const noDataAndNotLoading =
    !beforeData && !afterData && !onlyData && !isLoading;
  if (beforeSelectedButNoData || noDataAndNotLoading) {
    emptyText = (
      <span>
        An earlier transform has an error. <br />
        Please fix it to see the data at this transform.
      </span>
    );
  }

  const showDownloadButton =
    selectedStep === NON_TRANSFORM_STEPS.after || allowDataDownload;

  return (
    <ViewContainer>
      <Header>
        <HeaderTitle>Data Preview</HeaderTitle>
        {!onlyData && !noDataAndNotLoading && (
          <>
            <BeforeButton
              selected={beforeSelected}
              onClick={() => setBeforeSelected(true)}
            >
              Before
            </BeforeButton>
            <AfterButton
              selected={!beforeSelected}
              onClick={() => setBeforeSelected(false)}
            >
              After
            </AfterButton>
          </>
        )}
        {showDownloadButton && (
          <DownloadTransformedDataButton
            fetchDataExportFromServer={fetchDataExportFromServer}
            projectId={projectId}
            data={onlyData}
          />
        )}
      </Header>
      {selectedData ? (
        <DataTableHolder>
          <ILDataTable
            className="project-data-table"
            columns={selectedData.columns}
            dataSource={selectedData.values}
          />
        </DataTableHolder>
      ) : (
        <EmptyDataGrid>
          <EmptyDataMessage>
            {isLoading ? <Spin /> : emptyText}
          </EmptyDataMessage>
        </EmptyDataGrid>
      )}
    </ViewContainer>
  );
};

export default React.memo(TransformDataPreview);
