import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Input, Spin } from 'antd';
import { CheckOutlined, RightOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import { API_PREFIX, checkResponseSuccess } from '../redux/actions';
import useAuthHeaders from '../redux/authHeadersHook';
import { themeColors } from '../colors/themeColors';
import { systemColors } from '../colors/systemColors';

const Container = styled.div`
  height: 60vh;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  overflow: auto;
  padding: 20px;
  flex-grow: 1;
  text-align: center;
`;

const Footer = styled.div`
  padding: 15px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div``;

const ColumnTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
  flex-grow: 0;
`;

const ColumnRow = styled.div`
  border-bottom: 1px solid #e8e8e8;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  :hover {
    background: ${systemColors.background.fill};
  }

  :active {
    background: #edf5fb;
  }
`;

const FilterInput = styled(Input)`
  margin-top: 20px;
  margin-bottom: 5px;
`;

const FilterAddon = styled.div`
  color: #d9d9d9;

  a {
    margin: 0px 5px;
  }
`;

const SelectedIcon = styled(CheckOutlined)`
  color: ${themeColors.primary.base};
`;

const ConfirmColumns = ({ columnConfirmationComplete, dataSets, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [columnFilter, setColumnFilter] = useState('');
  const dataSet = get(dataSets, '[0]', {});
  const [columns, setColumns] = useState(dataSet.columns || []);
  const [selectedColumns, setSelectedColumns] = useState(columns || []);
  const authHeaders = useAuthHeaders();

  useEffect(() => {
    if (dataSet.columns === undefined && dataSet.id !== undefined) {
      setIsLoading(true);
      fetch(`${API_PREFIX}/data/${dataSet.id}/columns`, {
        method: 'GET',
        headers: {
          ...authHeaders,
        },
      })
        .then(checkResponseSuccess)
        .then(({ columns = [] }) => {
          setColumns(columns);
          setSelectedColumns(columns);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(`Error fetching columns in confirm columns`, error);
        });
    } else {
      setColumns(dataSet.columns);
      setSelectedColumns(dataSet.columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSet]);

  const toggleColumnSelection = (name) => {
    const columns = [...selectedColumns];
    var index = columns.indexOf(name);
    if (index > -1) {
      if (selectedColumns.length !== 0) {
        columns.splice(index, 1);
      }
    } else {
      columns.push(name);
    }
    setSelectedColumns(columns);
  };

  const reportColumnConfirmation = () => {
    if (selectedColumns.length === columns.length) {
      //All columns are selected.
      columnConfirmationComplete([]);
    } else {
      columnConfirmationComplete(selectedColumns);
    }
  };

  return (
    <Container>
      <Body>
        <Spin spinning={isLoading}>
          <Title>
            Select which columns of the data set we should use in this project.
          </Title>
          <FilterInput
            addonAfter={
              <FilterAddon>
                <Button type="link" onClick={() => setSelectedColumns(columns)}>
                  Select All
                </Button>{' '}
                |{' '}
                <Button type="link" onClick={() => setSelectedColumns([])}>
                  None
                </Button>
              </FilterAddon>
            }
            placeholder="Filter..."
            onChange={(e) => {
              setColumnFilter(e.target.value);
            }}
          />
          <ColumnTable>
            {columns
              .filter((columnName) =>
                columnName.toLowerCase().includes(columnFilter.toLowerCase())
              )
              .map((name, index) => (
                <ColumnRow
                  key={name + index}
                  onClick={() => toggleColumnSelection(name)}
                >
                  <div>{name}</div>
                  {selectedColumns.includes(name) ? (
                    <SelectedIcon type="check" />
                  ) : (
                    <div />
                  )}
                </ColumnRow>
              ))}
          </ColumnTable>
        </Spin>
      </Body>
      <Footer>
        <div>
          {selectedColumns.length}/{columns.length} Selected
        </div>
        <Button
          disabled={selectedColumns.length === 0}
          onClick={reportColumnConfirmation}
          type="primary"
        >
          Confirm <RightOutlined />
        </Button>
      </Footer>
    </Container>
  );
};

export default ConfirmColumns;
