import React from 'react';
import { Form, Input, Alert, Button } from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Container = styled.div``;

const Body = styled.div`
  min-height: 25vh;
  padding: 40px;
`;

const Footer = styled.div`
  padding: 15px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const SelectedDataSet = styled(Alert)`
  margin-bottom: 5px !important;
`;

const ActionButton = styled(Button)`
  margin-left: 10px;
`;

const NewProjectInitialStep = ({
  projectName,
  setProjectName,
  nameError,
  dataSets,
  dataSetError,
  getDataSetName,
  showDataSetSelector,
  createButtonClicked,
  cancelButtonClicked,
}) => {
  const dataSetSelected = dataSets.length !== 0;

  return (
    <Container>
      <Body>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          size="middle"
        >
          <Form.Item
            label="Project Name:"
            hasFeedback={nameError !== undefined}
            validateStatus={nameError ? 'error' : ''}
            help={nameError || ''}
          >
            <Input
              type="text"
              value={projectName}
              placeholder="Enter Project Name..."
              onChange={(e) => {
                setProjectName(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Training Data:"
            hasFeedback={dataSetError !== undefined}
            help={dataSetError || ''}
          >
            {dataSets.map((dataSet, index) => (
              <SelectedDataSet
                key={index}
                message={getDataSetName(dataSet)}
                type="info"
              />
            ))}
            <Button
              type="primary"
              icon={<DatabaseOutlined />}
              onClick={showDataSetSelector}
            >
              {dataSetSelected ? 'Change' : 'Select'} Data Set
            </Button>
          </Form.Item>
        </Form>
      </Body>
      <Footer>
        <ActionButton onClick={cancelButtonClicked}>Cancel</ActionButton>
        <ActionButton
          disabled={!dataSetSelected}
          onClick={createButtonClicked}
          type="primary"
        >
          Create
        </ActionButton>
      </Footer>
    </Container>
  );
};

export default NewProjectInitialStep;
