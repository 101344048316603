import React from 'react';
import { Drawer, Button } from 'antd';
import { RightCircleFilled, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { themeColors } from '../colors/themeColors';
import { systemColors } from '../colors/systemColors';

const DrawerContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${themeColors.secondary.gridBackground};
`;

const Header = styled.div`
  background: ${themeColors.secondary.dark};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${themeColors.secondary.dimText};
`;

const HeaderButton = styled.div`
  width: 40px;
  height: 40px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: ${(p) => p.cursor || 'pointer'};
`;

const DataView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 15px 10px 50px;
  overflow: auto;

  > :nth-child(2n) {
    background-color: ${themeColors.secondary.dark};
  }
`;

const DataItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px 10px;
  color: #ffffff;
  margin-bottom: 5px;
  border-radius: 3px;

  > :first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
    margin-right: 10px;
  }

  > :last-child {
    flex-shrink: 0;
  }
`;

const OutcomeView = styled.div`
  background-color: #fff9d7;
  border-radius: 3px;
  color: ${systemColors.gold.tiredSand};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  margin: 10px 10px 0px;
`;

const OutcomeValueText = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 0px 8px 5px;
`;

const ExploreButtonLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ExploreButton = styled(Button)`
  margin: 10px;
  padding: 10px;
  height: 50px;
`;

const SpaceFiller = styled.div`
  height: 50px;
  width: 100%;
  display: block;
  padding-bottom: 100px;
`;

export default ({
  columns = [],
  values = [],
  outcomeColumns = [],
  outcomeValue = '',
  rowIndex = 'not selected',
  isVisible,
  onClose,
  projectId,
  modelId,
}) => {
  const rowColumnToValues = {};
  const dataItems = columns.map(({ name }, index) => {
    rowColumnToValues[name] = values[index];

    return (
      <DataItem key={name}>
        <span title={name}>{name}</span>
        <span>{values[index]}</span>
      </DataItem>
    );
  });

  const outcomes = outcomeColumns.map((col, index) => (
    <React.Fragment key={index}>
      <div>{col.name}</div>
      <OutcomeValueText>{outcomeValue[index]}</OutcomeValueText>
    </React.Fragment>
  ));

  return (
    <Drawer
      title={null}
      placement="right"
      width="300px"
      bodyStyle={{ padding: 0, height: '100%' }}
      closable={false}
      mask={false}
      onClose={onClose}
      visible={isVisible}
    >
      <DrawerContent>
        <Header>
          <HeaderButton cursor="default" />
          <span>Row {rowIndex}</span>{' '}
          <HeaderButton onClick={onClose}>
            <CloseOutlined />
          </HeaderButton>
        </Header>

        <OutcomeView>{outcomes}</OutcomeView>
        <ExploreButtonLink
          to={{
            pathname: `/simulation/${projectId}/${modelId}/new`,
            state: {
              seedWithValues: rowColumnToValues,
            },
          }}
        >
          <ExploreButton type="primary" className="btn-purple">
            Explore Row In Simulator
            <RightCircleFilled />
          </ExploreButton>
        </ExploreButtonLink>

        <DataView>
          {dataItems}
          <SpaceFiller />
        </DataView>
      </DrawerContent>
    </Drawer>
  );
};
