import React from 'react';
import styled from 'styled-components';
import ProjectBreadcrumbMenu from '../project-components/ProjectBreadcrumbMenu';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 15px 15px 65px;
  border-bottom: 1px solid #eaeff2;

  > * {
    width: 100%;
  }
`;

export default ({ onCurrentProjectStepClick, projectId, projectName }) => {
  return (
    <Header>
      <ProjectBreadcrumbMenu
        projectId={projectId}
        projectName={projectName}
        onCurrentProjectStepClick={onCurrentProjectStepClick}
      />
    </Header>
  );
};
