export default (getPipelineContext) => {
  const { network, setters, state } = getPipelineContext();

  setters.setIsLoading(true);
  fetch(`${network.API_PREFIX}/pasteur/pipeline/project/${state.projectId}`, {
    method: 'GET',
    headers: {
      ...network.authHeaders,
    },
  })
    .then(network.checkResponseSuccess)
    .then(({ pipeline, start, final, error, error_transform, message }) => {
      setters.setPipelineData(pipeline);
      setters.setBeforeData(start);
      setters.setAfterData(final);

      if (error) {
        setters.setPipelineError({ error, error_transform, message });
      }

      setters.setIsLoading(false);
    })
    .catch((error) => {
      setters.setIsLoading(false);
      console.error(`Error fetching data list`, error);
      // notification.error({
      //   message: "Couldn't fetch data",
      //   description:
      //     "Please check your internet connection or contact support."
      // });
    });
};
