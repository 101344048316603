import React from 'react';
import styled from 'styled-components';
import { Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { get, reduce, isEqual } from 'lodash';
import { themeColors } from '../colors/themeColors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: 30px;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 30px;
`;

const ScenariosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
  width: 100%;
`;

const ScenarioResults = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;

const OutcomeView = styled.div`
  background: ${themeColors.secondary.selected.base};
  padding: 10px;
  color: #ffffff;
  border-radius: 5px;
  min-width: 150px;
`;

const OutcomeViewTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
`;

const OutcomeValue = styled.div`
  background: ${themeColors.secondary.selected.darker};
  width: 100%;
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
`;

const ClassifierOutcomeValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > :first-child {
    margin-right: 10px;
  }
`;

const ColumnValueView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: auto;
  background-color: #f9f9f9;
  margin: 10px 0px;
  max-width: 50vw;

  > :nth-child(2n) {
    background-color: #eeeeee;
  }
`;

const ColumnValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 15px;

  > :first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
    margin-right: 10px;
    font-weight: 600;
  }

  > :last-child {
    flex-shrink: 0;
  }
`;

const findChangedValues = (a, b) =>
  reduce(
    a,
    function (result, value, key) {
      return isEqual(value, b[key]) ? result : result.concat(key);
    },
    []
  );

export default ({ data, simulationName, deleteScenario }) => {
  const scenarios = [...get(data, 'scenarios', [])].reverse();
  const renderedScenarios = scenarios.map((scenario, index) => {
    let outcomeValue = get(scenario, 'x_y_values.y', 'Something went wrong');

    if (Array.isArray(outcomeValue)) {
      outcomeValue = outcomeValue.map((val) => {
        return typeof val === 'number' ? val.toFixed(2) : val;
      });
    } else {
      outcomeValue = Object.keys(outcomeValue).map((key, index) => (
        <ClassifierOutcomeValue key={index}>
          <span>{key}:</span>{' '}
          <span>{(outcomeValue[key] * 100).toFixed(2)}</span>
        </ClassifierOutcomeValue>
      ));
    }

    let columnValues = get(scenario, 'column_values', {});
    let columnsToRender = Object.keys(columnValues);
    if (index > 0) {
      const prevColValues = get(scenarios, [index - 1, 'column_values'], {});
      columnsToRender = findChangedValues(columnValues, prevColValues);
    }
    console.log(columnsToRender, scenario.name);

    return (
      <ScenarioResults key={index}>
        <OutcomeView>
          <OutcomeViewTitle>
            <span>{scenario.name}</span>
            {data.scenarios.length > 1 && (
              <Popconfirm
                title="Are you sure delete this scenario?"
                onConfirm={() => deleteScenario(scenario.id)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            )}
          </OutcomeViewTitle>
          <OutcomeValue>{outcomeValue}</OutcomeValue>
        </OutcomeView>
        <ColumnValueView>
          {columnsToRender.map((col) => (
            <ColumnValue key={col}>
              <span>{col}</span>
              <span>{columnValues[col]}</span>
            </ColumnValue>
          ))}
        </ColumnValueView>
      </ScenarioResults>
    );
  });

  return (
    <Container>
      <Title>{simulationName}</Title>
      <ScenariosWrapper>{renderedScenarios.reverse()}</ScenariosWrapper>
    </Container>
  );
};
