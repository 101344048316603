export const sortColumnsByMostPredictive = (predictiveFeatures) => {
  var sortable = [];
  for (var column in predictiveFeatures) {
    sortable.push({
      name: column,
      importance: predictiveFeatures[column],
    });
  }
  return sortable.sort(function (a, b) {
    return b['importance'] - a['importance'];
  });
};

export const getColumnNamesSortedByMostPredictive = (predictiveFeatures) => {
  const sortedColumns = sortColumnsByMostPredictive(predictiveFeatures) || [];
  return sortedColumns.map((column) => column.name);
};

export const smartRound = (num, numberOfSigFigsAfterDecimal = 2) => {
  if (isNaN(num)) return num;
  const adjust = 10 ** numberOfSigFigsAfterDecimal;
  if (num >= 1) return Math.round(adjust * num) / adjust;

  return Number.parseFloat(num).toPrecision(numberOfSigFigsAfterDecimal);
};
