import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { PROJECT_STEPS } from '../project-components/NewProjectModal';
import MLAccessWrapper from '../project-components/MLAccessWrapper';

export default ({ projectId }) => {
  const history = useHistory();
  const MLAccessButton = MLAccessWrapper(Button);
  return (
    <MLAccessButton
      icon={<LoginOutlined />}
      type="primary"
      onClick={() =>
        history.push(`/projects/${projectId}/${PROJECT_STEPS.ml.url}`)
      }
    >
      Go to Machine Learning
    </MLAccessButton>
  );
};
