import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Upload, Spin, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as Actions from '../redux/actions';
import useAuthHeaders from '../redux/authHeadersHook';
import TransformDataPreview from './TransformDataPreview';

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60vh;
  width: 60vw;
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const displayErrorNotification = ({ error, message }) => {
  notification.error({
    duration: null, // User must manually close
    message: error || 'Whoops!',
    description:
      message ||
      'Please check your internet connection or contact support at hello@intersectlabs.io',
  });
};

export default ({ projectId, requiredColumns }) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [beforeData, setBeforeData] = useState();
  const [afterData, setAfterData] = useState();
  const [missingColumns, setMissingColumns] = useState();
  const authHeaders = useAuthHeaders();

  const applyPipeline = (dataFile) => {
    const data = new FormData();
    data.append('file', dataFile);
    data.append('project_id', projectId);

    fetch(`${Actions.API_PREFIX}/pasteur/apply`, {
      method: 'POST',
      body: data,
      headers: {
        ...authHeaders,
      },
    })
      .then(Actions.checkResponseSuccess403ok)
      .then((res) => {
        if (res.message) {
          displayErrorNotification(res.message);
          return;
        }

        setAfterData(res);
      })
      .catch((error) => {
        console.error(`Error applying pipeline via web`, error);
        notification.error({
          message: "Couldn't Apply Pipline",
          description:
            'Make sure you have transforms in your pipeline. Please check your internet connection or contact support at hello@intersectlabs.io',
        });
      })
      .finally(() => setIsLoading(false));
  };

  const uploadProps = {
    accept: '.csv',
    name: 'file',
    action: '/data/upload',
    disabled: false,
    showUploadList: false,
    beforeUpload: () => false,
    onChange: (info) => {
      let reader = new FileReader();
      setIsLoading(true);
      reader.onload = (e) => {
        let columns = e.target.result
          .replace(/\r\n/g, '\n')
          .split('\n', 1)[0]
          .split(',');
        var index = columns.indexOf('');

        if (index !== -1) {
          columns[index] = 'Unnamed: 0';
        }
        let missingColumns = requiredColumns.filter(function (x) {
          return !columns.includes(x);
        });
        if (missingColumns.length > 0) {
          setIsLoading(false);
          setMissingColumns(missingColumns.toString());
        } else {
          setMissingColumns(null);
          applyPipeline(info.fileList[0].originFileObj);
        }
      };
      reader.readAsText(info.fileList[0].originFileObj);
    },
  };

  if (isLoading)
    return (
      <Centered>
        <Spin />
      </Centered>
    );

  return (
    <ViewWrapper>
      <div>
        <ActionRow>
          <Upload {...uploadProps}>
            <Button type="primary" icon={<UploadOutlined />}>
              Apply Pipeline on CSV
            </Button>
          </Upload>
        </ActionRow>
        {missingColumns && (
          <div style={{ color: 'red' }}>
            You are missing columns: {missingColumns}
          </div>
        )}
      </div>
      {afterData && (
        <TransformDataPreview
          allowDataDownload
          onlyData={afterData}
          isLoading={isLoading}
        />
      )}
    </ViewWrapper>
  );
};
