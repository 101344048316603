import React, { useEffect, useState } from 'react';
import IntersectLogo from '../images/Logo.svg';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Breadcrumb, Spin } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import StartSimulationQuestion from '../model-report-components/StartSimulationQuestion';
import AddScenarioToSimulation from '../model-report-components/AddScenarioToSimulation';
import SimulationResults from '../model-report-components/SimulationResults';
import * as Actions from '../redux/actions';
import { get } from 'lodash';
import SinglePointSimulationResults from '../model-report-components/SinglePointSimulationResults';
import { getColumnNamesSortedByMostPredictive } from '../model-report-components/Helpers';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  padding: 30px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 90px;
`;

const Logo = styled.img`
  min-height: 40px;
`;

const BreadcrumbWrapper = styled.div`
  margin-left: 10px;
  margin-top: 20px;
`;

const SimulationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 120px);
  align-items: stretch;
  padding-top: 30px;
`;

const AddLineColumn = styled.div`
  width: 400px;
  overflow: auto;
  margin-right: 20px;
`;

const GraphColumn = styled.div`
  width: calc(100vw - 460px);
  padding: 0px 20px;
  overflow: auto;
`;

const LoadingIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match.params.projectId;
  return {
    model: state.training,
    user: state.user,
    projectId: projectId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const SimulationPage = ({
  match: {
    params: { projectId, modelId, simulationId },
  },
  location,
  history,
  user,
}) => {
  const predictiveColumns = get(location, 'state.predictiveColumns', []).map(
    (column) => column.name
  );
  const projectName = get(location, 'state.projectName', 'Model Report');
  const simulationsList = get(location, 'state.simulations');
  const seedWithValues = get(location, 'state.seedWithValues');
  const [simulationData, setSimulationData] = useState();
  const [loading, setLoading] = useState(
    simulationId !== 'new' ? 'Loading simulation...' : undefined
  );
  const [error, setError] = useState();

  useEffect(() => {
    if (simulationId === 'new') return;
    const authHeaders = Actions.getAuthHeaders(user);
    fetch(`${Actions.API_PREFIX}/simulator/${simulationId}`, {
      method: 'GET',
      headers: {
        ...authHeaders,
      },
    })
      .then(Actions.checkResponseSuccess)
      .then((data) => {
        // setIsLoading(false);
        setSimulationData(data);
        setLoading(undefined);
      })
      .catch((error) => {
        setLoading(undefined);
        setSimulationData('error');
        console.error(`Error fetching simulation id ${simulationId}`, error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, modelId, simulationId]);

  const startSimulation = (newSimulationName, xAxisColumn) => {
    const params = {
      project_id: projectId,
      model_id: modelId,
      simulation_name: newSimulationName,
    };

    if (seedWithValues) {
      params.column_values = JSON.stringify(seedWithValues);
    } else {
      params.x_axis_column = xAxisColumn;
    }

    setLoading('Starting Simulation...');
    fetch(`${Actions.API_PREFIX}/simulator/create`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        ...Actions.getAuthHeaders(user),
        'Content-Type': 'application/json',
      },
    })
      .then(Actions.checkResponseSuccess)
      .then((data) => {
        console.log('response of start simulation', data);
        setSimulationData(data);
        setLoading(undefined);
        history.replace(
          `/simulation/${projectId}/${modelId}/${data.metadata.id}`
        );
      })
      .catch((error) => {
        console.log('Start Simulation Error:', error.message);
        setError({ message: error.message, newSimulationName, xAxisColumn });
        // TODO handle error
        setLoading(undefined);
      });
  };

  const deleteScenario = (scenarioId) => {
    const updatedScenarios = simulationData.scenarios.filter(
      (scenario) => scenario.id !== scenarioId
    );
    setSimulationData({ ...simulationData, scenarios: updatedScenarios });
    fetch(`${Actions.API_PREFIX}/simulator/scenario/delete/${scenarioId}`, {
      method: 'POST',
      headers: {
        ...Actions.getAuthHeaders(user),
        'Content-Type': 'application/json',
      },
    })
      .then(Actions.checkResponseSuccess)
      .then((data) => {
        console.log('Deleted scenario:', scenarioId);
      })
      .catch((error) => {
        console.log('Start Simulation Error:', error);
        // TODO handle error
      });
  };

  const updateDataWithNewScenario = (scenario) => {
    setSimulationData({
      ...simulationData,
      scenarios: [scenario, ...simulationData.scenarios],
    });
  };

  let content, simulationName;
  if (loading !== undefined) {
    content = (
      <LoadingIconWrapper>
        <Spin tip={loading} size={'large'} />
      </LoadingIconWrapper>
    );
    simulationName = loading;
  } else if (simulationData === 'error') {
    content = (
      <LoadingIconWrapper>
        There was an error. Please contact hello@intersectlabs.io
      </LoadingIconWrapper>
    );
  } else if (simulationId === 'new') {
    const dd = new Date();
    simulationName =
      'New Simulation' +
      ' ' +
      dd.getFullYear() +
      (dd.getMonth() + 1) +
      dd.getDate() +
      dd.getHours() +
      dd.getMinutes();
    content = (
      <StartSimulationQuestion
        savedSimulationName={simulationName}
        columns={predictiveColumns}
        simulationsList={simulationsList}
        startSimulation={startSimulation}
        isSeeded={seedWithValues !== undefined}
        projectId={projectId}
        error={error}
      />
    );
  } else if (simulationData !== undefined) {
    simulationName = get(simulationData, 'metadata.name');
    const isSeeded =
      get(simulationData, 'metadata.simulation_type') === 'seeded';
    const columnsSortedByPredictiveness = getColumnNamesSortedByMostPredictive(
      simulationData.feature_importance
    );
    content = (
      <SimulationWrapper>
        <AddLineColumn>
          <AddScenarioToSimulation
            data={simulationData}
            columnsSortedByPredictiveness={columnsSortedByPredictiveness}
            user={user}
            projectId={projectId}
            modelId={modelId}
            simulationId={simulationId}
            updateDataWithNewScenario={updateDataWithNewScenario}
          />
        </AddLineColumn>
        <GraphColumn>
          {isSeeded ? (
            <SinglePointSimulationResults
              data={simulationData}
              columnsSortedByPredictiveness={columnsSortedByPredictiveness}
              simulationName={simulationName}
              deleteScenario={deleteScenario}
            />
          ) : (
            <SimulationResults
              data={simulationData}
              columnsSortedByPredictiveness={columnsSortedByPredictiveness}
              simulationName={simulationName}
              deleteScenario={deleteScenario}
            />
          )}
        </GraphColumn>
      </SimulationWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header>
        <Logo src={IntersectLogo} alt="Intersect Labs Logo" />
        <BreadcrumbWrapper>
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/projects/${projectId}/ml/report`}>
              <span>{projectName}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {simulationName || 'New Simulation'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbWrapper>
      </Header>
      {content}
    </PageWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SimulationPage);
