import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Table, notification } from 'antd';
import { API_PREFIX, checkResponseSuccess } from '../redux/actions';
import useAuthHeaders from '../redux/authHeadersHook';
import {
  condensedColumns,
  expandedColumns,
} from './DataListViewColumnDefinition';
import { useDataSetDownloadCallback } from './useDataSetDownloadCallback';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  overflow: auto;
`;

const CurrentDataSetListView = ({
  singleSelection = true,
  setSelectedExistingDataSets,
  selectedExistingDataSets = [],
  expanded = false,
  searchFilter = null,
}) => {
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const authHeaders = useAuthHeaders();
  const { downloadDataSet, dataSetIsLoading } = useDataSetDownloadCallback();

  useEffect(() => {
    setIsLoading(true);
    fetch(`${API_PREFIX}/user/data`, {
      method: 'GET',
      headers: {
        ...authHeaders,
      },
    })
      .then(checkResponseSuccess)
      .then((data) => {
        setDataList(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(`Error fetching data list`, error);
        notification.error({
          message: "Couldn't fetch data",
          description:
            'Please check your internet connection or contact support.',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteDataSet = (dataSetId) => {
    setIsLoading(true);
    fetch(`${API_PREFIX}/data/${dataSetId}/delete`, {
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
    })
      .then(checkResponseSuccess)
      .then((data) => {
        const updatedDataList = dataList.filter(
          (dataSet) => dataSet.id !== dataSetId
        );
        setDataList(updatedDataList);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('deleteDataSet Error:', error);
        setIsLoading(false);
        notification.error({
          duration: null,
          message: "Couldn't Delete Data Set",
          description:
            'Please ensure that no projects are using this data set.',
        });
      });
  };

  const selectRow = (dataset) => {
    if (singleSelection) {
      setSelectedExistingDataSets([dataset]);
    } else {
      const selectedDatasets = [...selectedExistingDataSets];
      if (selectedDatasets.indexOf(dataset) >= 0) {
        selectedDatasets.splice(selectedDatasets.indexOf(dataset), 1);
      } else {
        selectedDatasets.push(dataset);
      }
      setSelectedExistingDataSets(selectedDatasets);
    }
  };

  const deselectRow = (dataset) => {
    if (singleSelection && selectedExistingDataSets.includes(dataset)) {
      setSelectedExistingDataSets([]);
    } else {
      const selectedDatasets = [...selectedExistingDataSets];
      if (selectedDatasets.indexOf(dataset) >= 0) {
        selectedDatasets.splice(selectedDatasets.indexOf(dataset), 1);
      }
      setSelectedExistingDataSets(selectedDatasets);
    }
  };

  let columnsDefinition = expandedColumns(deleteDataSet, downloadDataSet);
  if (!expanded) {
    columnsDefinition = condensedColumns(
      selectedExistingDataSets,
      selectRow,
      deselectRow
    );
  }

  let filteredDataList = dataList;
  if (searchFilter !== null) {
    filteredDataList = dataList.filter((dataSet) => {
      const name = dataSet.filename.toLowerCase();
      return name.includes(searchFilter.toLowerCase());
    });
  }

  return (
    <Container>
      <Table
        dataSource={filteredDataList}
        rowKey={(record) => record.id}
        columns={columnsDefinition}
        bordered={false}
        loading={isLoading || dataSetIsLoading}
      />
    </Container>
  );
};

export default CurrentDataSetListView;
