export const convertToCSV = (objArray) => {
  let array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index = 0; index < array[i].length; index++) {
      if (index >= 1) {
        line += ',';
      }
      let cell = array[i][index];
      line += typeof cell === 'string' ? `"${cell}"` : cell;
    }

    str += line + '\r\n';
  }
  return str;
};

const getFileDownloadName = (fileTitle) => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  const dateString = `${yyyy}/${mm}/${dd}`;
  return `${fileTitle || 'export'}_${dateString}.csv`;
};

export const exportCSVToBrowserDownloads = (csv, fileTitle) => {
  const blob = new Blob([csv], { type: 'text/csv' });
  const fileDownloadName = getFileDownloadName(fileTitle);
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileDownloadName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      link.href = window.URL.createObjectURL(blob);
      link.download = fileDownloadName;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    }
  }
};

export const exportAsCSVFile = (headers, items, fileTitle) => {
  const itemsCopy = items.slice();
  if (headers) {
    itemsCopy.unshift(headers);
  }

  const jsonObject = JSON.stringify(itemsCopy);

  const csv = convertToCSV(jsonObject);
  exportCSVToBrowserDownloads(csv, fileTitle);
};
