import { get } from 'lodash';

export default (position, transformId, getPipelineContext) => {
  const { state, setters, network } = getPipelineContext();

  if (state.pipelineError && position > state.pipelineError.error_transform)
    return;

  setters.setIsFormLoading(true);
  fetch(
    `${network.API_PREFIX}/pasteur/form?project_id=${state.projectId}&position=${position}&transform_id=${transformId}`,
    {
      method: 'GET',
      headers: {
        ...network.authHeaders,
      },
    }
  )
    .then(network.checkResponseSuccess)
    .then(({ form, before, after, error, error_transform, message }) => {
      // Update step with form and data
      setters.setPipelineData((p) => {
        const dataCopy = [...p];
        const stepData = get(dataCopy, position, {});
        dataCopy[position] = {
          ...stepData,
          form,
          beforeData: before,
          afterData: after,
        };
        return dataCopy;
      });

      if (error) {
        setters.setPipelineError({ error, error_transform, message });
        // Not clearing the error there is no error here because GET form doesn't return errors if they
        // are downstream.
      }
      setters.setIsFormLoading(false);
    })
    .catch((error) => {
      setters.setIsFormLoading(false);
      console.error(`Error fetching form`, error);
      //TODO: Update pipelineError with error

      // notification.error({
      //   message: "Couldn't fetch data",
      //   description:
      //     "Please check your internet connection or contact support."
      // });
    });
};
