import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions';
import { themeColors } from '../colors/themeColors';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PredictionPageWrapper = styled.div`
  flex-grow: 1;
  display: block;
  padding-left: 50px;
  padding-top: 50px;
`;

const Warning = styled.div`
  color: ${themeColors.primary.darkText};
  margin-bottom: 30px;
  text-align: left;
  margin-top: 20px;
`;

const ModalText = styled.div`
  color: ${themeColors.primary.darkText};
`;

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    predictionToken: state.training,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchPredictionAPIToken: () => dispatch(Actions.fetchPredictionAPIToken()),
  };
};

class ApiKeys extends Component {
  constructor(props) {
    super(props);
    this.state = { modalVisible: false };
  }

  fetchTokenAndShowModal = () => {
    this.props.fetchPredictionAPIToken();
    this.setState({ modalVisible: true });
    if (this.props.predictionToken.predictionToken !== undefined) {
      document.querySelector('#ModalText').select();
      document.execCommand('copy');
    }
  };

  handleCancel = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    let modalText =
      this.props.predictionToken === undefined
        ? 'processing'
        : this.props.predictionToken.predictionToken;
    return (
      <PredictionPageWrapper>
        <Warning>
          Note that issuing a new token will invalidate your existing tokens!
        </Warning>
        <ButtonWrapper>
          <Button type="primary" onClick={this.fetchTokenAndShowModal}>
            Issue new token
          </Button>
        </ButtonWrapper>
        <Modal
          title="Your API access token"
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          onOk={this.handleCancel}
          footer=""
        >
          <ModalText>{modalText}</ModalText>
        </Modal>
      </PredictionPageWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeys);
