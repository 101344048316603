import { TYPES as AUTH_TYPES } from '../authActions';
import { TYPES } from '../actions';

const loading = (state = {}, action) => {
  switch (action.type) {
    case AUTH_TYPES.LOGIN_START:
      return { ...state, login: 'started' };

    case AUTH_TYPES.LOGIN_END:
      return { ...state, login: 'done' };

    case AUTH_TYPES.LOGIN_INITIALIZED:
      return { ...state, login: 'initialized' };

    case TYPES.PROJECTS_FETCHING_START:
      return { ...state, projectsFetching: 'started' };

    case TYPES.PROJECTS_FETCHING_END:
      return { ...state, projectsFetching: 'done' };

    case TYPES.PRICING_FETCHING_START:
      return { ...state, pricingFetching: 'started' };

    case TYPES.PRICING_FETCHING_END:
      return { ...state, pricingFetching: 'done' };

    case TYPES.SUBSCRIPTION_ACTIVATION_START:
      return { ...state, subscriptionActivation: 'started' };

    case TYPES.SUBSCRIPTION_ACTIVATION_END:
      return { ...state, subscriptionActivation: 'done' };

    case TYPES.PROJECT_ANSWER_SUBMISSION_START:
      return { ...state, project_answer_submission: 'started' };

    case TYPES.PROJECT_ANSWER_SUBMISSION_END:
      return { ...state, project_answer_submission: 'done' };

    default:
      return state;
  }
};

export default loading;
