import React from 'react';
import styled from 'styled-components';
import { systemColors } from '../colors/systemColors';
import { Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 10px -15px -15px;
  padding-top: 10px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

const Subtitle = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const QuestionIcon = styled(QuestionCircleFilled)`
  color: #a3a1b7;
  margin-left: 4px;
`;

const ColumnsWrapper = styled.div`
  background: #fff;
  padding: 30px;
`;

const ColumnRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 15px 15px;

  :last-of-type {
    padding-bottom: 30px;
  }
`;

const ColumnName = styled.div`
  width: 20%;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 15px;
  font-weight: 300;
`;

const ImportanceBarBG = styled.div`
  width: 66%;
  height: 20px;
  background-color: #fbfbfb;
  border: 1px solid #e4e2ef;
`;

const ImportanceBar = styled.div`
  width: ${(p) => `${p.importanceFraction * 100}%`};
  height: 20px;
  background-color: #e4e2ef;
  border-radius: 3px;

  ${ColumnRow}:hover & {
    background: ${systemColors.gold.base};
  }
`;

export default ({ predictiveColumns }) => {
  if (!predictiveColumns || predictiveColumns.length === 0)
    return <div>No Columns</div>;

  const maxImportance = predictiveColumns[0].importance;

  return (
    <ViewWrapper>
      <Title>Predictive Columns</Title>
      <Subtitle>
        Each column's bar shows its relative influence on the outcome column.
      </Subtitle>
      <ColumnsWrapper>
        {predictiveColumns.map((col) => (
          <ColumnRow key={col.name}>
            <ColumnName title={col.name}>{col.name}</ColumnName>
            <ImportanceBarBG>
              <ImportanceBar
                importanceFraction={col.importance / maxImportance}
              />
            </ImportanceBarBG>
          </ColumnRow>
        ))}
      </ColumnsWrapper>
    </ViewWrapper>
  );
};
