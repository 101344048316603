import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Radio, Select, Button } from 'antd';
import { CopyFilled, PlusSquareFilled } from '@ant-design/icons';
import useAuthHeaders from '../redux/authHeadersHook';
import { API_PREFIX, checkResponseSuccess } from '../redux/actions';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import MLAccessButton from './MLAccessButton';
import { themeColors } from '../colors/themeColors';

const PageWrapper = styled.div`
  background: #fbfbfb;
  border: 1px solid #eaeff2;
  color: ${themeColors.secondary.dark};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 50px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  max-width: 500px;
`;

const Subtitle = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  max-width: 500px;
`;

const RowPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #eaeff2;
  border-radius: 5px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const CreatePipelineButton = styled(Button)`
  margin-top: 20px;
`;

const ExistingPipelineSelect = styled(Select)`
  margin-top: 20px !important;
`;

const creationOptions = {
  new: 'new',
  clone: 'clone',
};

const PipelineAlreadyInUseMessage = ({ children }) => (
  <PageWrapper>
    <Title style={{ marginBottom: 24 }}>
      Nice work: work you've already got a machine learning model up and running
      without needing to use a pipeline to transform your data.
    </Title>
    {children}
    <Subtitle>
      Hoping to add a pipeline to this model? Try this: create a new project,
      setup your desired pipeline, and then launch a new machine learning model
      there.
    </Subtitle>
  </PageWrapper>
);

export default ({
  pipelineInUse,
  createNewPipeline,
  createClonedPipeline,
  projectId,
}) => {
  const [selectedOption, setSelectedOption] = useState(creationOptions.new);
  const [pipelineOptions, setPipelineOptions] = useState();
  const [selectedPipelineToClone, setSelectedPipelineToClone] = useState();
  const authHeaders = useAuthHeaders();

  const getProjectName = useCallback(
    (state) => {
      const projects = get(state, ['projects'], []);
      const currentProject =
        projects.find((project) => project.id === projectId) || {};
      return currentProject.name || 'Project';
    },
    [projectId]
  );
  const name = useSelector(getProjectName);

  useEffect(() => {
    fetch(`${API_PREFIX}/pasteur/pipelines`, {
      method: 'GET',
      headers: {
        ...authHeaders,
      },
    })
      .then(checkResponseSuccess)
      .then((data) => {
        setPipelineOptions(data);
      })
      .catch((error) => {
        console.error(`Error fetching pipelines`, error);
        //TODO: Update pipelineError with error

        // notification.error({
        //   message: "Couldn't fetch data",
        //   description:
        //     "Please check your internet connection or contact support."
        // });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let icon = <PlusSquareFilled />;
  if (selectedOption === creationOptions.clone) icon = <CopyFilled />;

  const createButtonClicked = () => {
    if (selectedOption === creationOptions.new) {
      createNewPipeline(name);
    } else {
      createClonedPipeline(selectedPipelineToClone, name);
    }
  };

  let createButtonEnabled = name.length > 0;
  if (selectedOption === creationOptions.clone && createButtonEnabled) {
    createButtonEnabled = selectedPipelineToClone !== undefined;
  }

  let sortedPipelineOptions = pipelineOptions;
  if (pipelineOptions) {
    sortedPipelineOptions = pipelineOptions.sort(function (a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    });
  }

  if (pipelineInUse) {
    return (
      <PipelineAlreadyInUseMessage>
        <MLAccessButton projectId={projectId} />
      </PipelineAlreadyInUseMessage>
    );
  }

  return (
    <PageWrapper>
      <Title>How do you want to transform this data?</Title>
      <Subtitle>
        You can transform your data really easily with pipelines. Create a new
        one or use an existing pipeline to get started.
      </Subtitle>
      <RowPanel>
        <Column>
          <Radio.Group
            defaultValue={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            <Radio.Button value={creationOptions.new}>
              Create New Pipeline
            </Radio.Button>
            <Radio.Button value={creationOptions.clone}>
              Clone Existing Pipeline
            </Radio.Button>
          </Radio.Group>
          {selectedOption === creationOptions.clone && (
            <ExistingPipelineSelect
              onSelect={(value) => setSelectedPipelineToClone(value)}
              placeholder="Clone pipeline ..."
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {sortedPipelineOptions.map((pipeline) => (
                <Select.Option key={pipeline.id} value={pipeline.id}>
                  {pipeline.name}
                </Select.Option>
              ))}
            </ExistingPipelineSelect>
          )}
          <CreatePipelineButton
            onClick={createButtonClicked}
            disabled={!createButtonEnabled}
            icon={icon}
            type="primary"
            data-btn-id="create-pipeline"
          >
            Create Pipeline
          </CreatePipelineButton>
        </Column>
      </RowPanel>
      <Title>Don’t need to transform data?</Title>
      <Subtitle>
        If you’re data is already good to go, you can jump right into the
        machine learning step.
      </Subtitle>
      <MLAccessButton projectId={projectId} />
    </PageWrapper>
  );
};
