import React, { useState } from 'react';
import { Modal, Breadcrumb, Spin, Button } from 'antd';
import NewProjectInitialStep from './new-project-steps/NewProjectInitialStep';
import UploadModal from '../data-set-components/UploadModal';
import ConfirmColumns from '../data-set-components/ConfirmColumns';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';

const STEPS = {
  createProject: 'createProject',
  selectDataSet: 'selectDataSet',
  confirmColumns: 'confirmColumns',
};

const STEP_NAMES = {
  createProject: 'Create New Project',
  selectDataSet: 'Select Data Set',
  confirmColumns: 'Confirm Columns',
};

const PATHS = {
  [STEPS.createProject]: [STEPS.createProject],
  [STEPS.selectDataSet]: [STEPS.createProject, STEPS.selectDataSet],
  [STEPS.confirmColumns]: [
    STEPS.createProject,
    STEPS.selectDataSet,
    STEPS.confirmColumns,
  ],
};

export const PROJECT_STEPS = {
  pipeline: {
    url: 'pipeline',
    display: 'Pasteur',
  },
  ml: {
    url: 'ml',
    display: 'ML',
  },
};

const getDataSetName = (dataSet = {}) => {
  return dataSet.filename || get(dataSet, 'file.name');
};

const NewProjectModal = ({
  showModal,
  closeModal,
  doesProjectNameAlreadyExist,
  createProject,
}) => {
  const [currentStep, setCurrentStep] = useState(STEPS.createProject);
  const [dataSets, setDataSets] = useState([]);
  const [columns, setColumns] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [nameError, setNameError] = useState();
  const [dataSetError, setDataSetError] = useState();
  const [creationInProgress, setCreationInProgress] = useState();
  const history = useHistory();

  const cleanUpAndCloseModal = () => {
    setCurrentStep(STEPS.createProject);
    setDataSets([]);
    setColumns([]);
    setProjectName('');
    setNameError(undefined);
    setDataSetError(undefined);
    setCreationInProgress(undefined);

    closeModal();
  };

  const updateProjectCreationStatus = (
    creationInProgress,
    projectId = undefined
  ) => {
    setCreationInProgress(creationInProgress);
    if (creationInProgress === false && projectId) {
      cleanUpAndCloseModal();
      history.push(`/projects/${projectId}/${PROJECT_STEPS.pipeline.url}`);
    }
  };

  const CurrentPath = (
    <Breadcrumb separator=">">
      {PATHS[currentStep].map((step) => (
        <Breadcrumb.Item key={step} onClick={() => setCurrentStep(step)}>
          <Button type="link">{STEP_NAMES[step]}</Button>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );

  const dataSetsSelected = (selectedDataSets) => {
    setDataSets(selectedDataSets);
    setCurrentStep(STEPS.confirmColumns);
  };

  const columnConfirmationComplete = (confirmedColumns) => {
    setColumns(confirmedColumns);
    setCurrentStep(STEPS.createProject);
  };

  const nameChanged = (name) => {
    setProjectName(name);
    const nameAlreadyExists = doesProjectNameAlreadyExist(name);
    if (nameAlreadyExists) {
      setNameError('A project with this name already exists!');
    } else {
      setNameError(undefined);
    }
  };

  const createButtonClicked = () => {
    if (!dataSets || dataSets.length === 0) {
      setDataSetError('Please select a data set to create this project');
    } else {
      setDataSetError(undefined);
    }

    let nameError = undefined;
    const nameAlreadyExists = doesProjectNameAlreadyExist(projectName);
    if (nameAlreadyExists)
      nameError = 'A project with this name already exists!';
    if (
      projectName === undefined ||
      projectName.length === 0 ||
      projectName.trim().length === 0
    )
      nameError = 'Please enter a project name';
    setNameError(nameError);

    if (!nameError && !dataSetError) {
      const dataSet = dataSets[0];
      const dataSetId = get(dataSets, '[0].id');
      if (dataSetId) {
        createProject(
          projectName,
          undefined,
          dataSetId,
          columns,
          updateProjectCreationStatus
        );
      } else if (dataSet !== undefined) {
        createProject(
          projectName,
          dataSets[0].file,
          undefined,
          columns,
          updateProjectCreationStatus
        );
      }
    }
  };

  return (
    <Modal
      title={CurrentPath}
      visible={showModal}
      onCancel={cleanUpAndCloseModal}
      footer={null}
      okText="Create"
      width={700}
      bodyStyle={{ padding: 0 }}
    >
      <Spin spinning={creationInProgress === true} tip="Creating Project...">
        {currentStep === STEPS.createProject && (
          <NewProjectInitialStep
            {...{
              projectName,
              dataSets,
              createProject,
              showDataSetSelector: () => setCurrentStep(STEPS.selectDataSet),
              setProjectName: nameChanged,
              nameError,
              dataSetError,
              getDataSetName,
              createButtonClicked,
              cancelButtonClicked: closeModal,
            }}
          />
        )}

        {currentStep === STEPS.selectDataSet && (
          <UploadModal
            dataSetsSelected={dataSetsSelected}
            getDataSetName={getDataSetName}
          />
        )}

        {currentStep === STEPS.confirmColumns && (
          <ConfirmColumns
            dataSets={dataSets}
            columnConfirmationComplete={columnConfirmationComplete}
          />
        )}
      </Spin>
    </Modal>
  );
};

export default NewProjectModal;
