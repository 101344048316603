import React, { Component } from 'react';
import { Button, Card, Input } from 'antd';
import styled from 'styled-components';
import IntersectLogo from '../images/Logo.svg';
import firebase from 'firebase/app';
import { letsLogIn } from '../redux/authActions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { systemColors } from '../colors/systemColors';
const Logo = styled.img`
  margin: 50px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
`;

const FormContainer = styled(Card)`
  max-width: 100%;
  width: 400px;
  margin: auto;
  padding: 10px 30px;
  background: ${systemColors.background.fill};
  margin-top: 3%;
  border-radius: 4px;
  border: 1px solid ${systemColors.background.border};
`;

const StyledInput = styled(Input)`
  margin-bottom: 20px;
`;

const SubmitButton = styled(Button)`
  margin: 15px;
`;

const Title = styled.p`
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 300;
  margin-bottom: 25px;
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: red;
  margin: 10px;
`;

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    letsLogin: () => dispatch(letsLogIn()),
  };
};

class PasswordResetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      error: '',
      emailSent: false,
    };
  }

  sendPasswordResetEmail = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(this.state.emailAddress)
      .then(
        function () {
          this.setState({
            emailSent: true,
          });
        }.bind(this)
      )
      .catch(
        function (error) {
          var errorCode = error.code;
          // var errorMessage = error.message;
          if (errorCode === 'auth/user-not-found') {
            this.setState({
              error: 'User does not exist',
              emailSent: false,
            });
          } else if (errorCode === 'auth/invalid-email') {
            this.setState({
              error: 'Invalid email address',
              emailSent: false,
            });
          }
        }.bind(this)
      );
  };

  handleChangeEmail = (emailAddress) => {
    this.setState({
      emailAddress: emailAddress,
      error: '',
      emailSent: false,
    });
  };

  render() {
    let buttonText = this.state.emailSent
      ? 'Re-send password reset link'
      : 'Send password reset link';
    return (
      <StyledDiv>
        <Logo src={IntersectLogo} title="Intersect Labs" />
        <FormContainer>
          <Title>Reset Password</Title>
          <StyledInput
            placeholder="Email address"
            onChange={(e) => {
              this.handleChangeEmail(e.target.value);
            }}
          />
          <ErrorText>{this.state.error}</ErrorText>
          <SubmitButton
            onClick={() => {
              this.sendPasswordResetEmail();
            }}
          >
            {buttonText}
          </SubmitButton>
        </FormContainer>
        <FormContainer>
          <Link to={`/login`}>
            <SubmitButton>Log In</SubmitButton>
          </Link>
        </FormContainer>
      </StyledDiv>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetForm);
